import {Component, OnInit} from '@angular/core';
import {Escala} from '../interfaces/escala';
import {Router} from '@angular/router';
import {EscalaService} from '../../../modules/scf/services/escala.service';
import {JornadaTrabalhoService} from './../services/jornada-trabalho.service';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {Jornada} from 'src/app/pages/escala/interfaces/jornada';
import {OrgaoService} from '../services/orgao.service';
import {IntervaloJornadaService} from '../services/intervalo-jornada.service';
import {IntervaloJornada} from '../interfaces/intervaloJornada';

@Component({
    selector: 'app-escala-consultar',
    templateUrl: './escala-consultar.component.html',
    styleUrls: ['./escala-consultar.component.scss']
})
export class EscalaConsultarComponent implements OnInit {

    escala: Escala;
    listaOrgaos: Orgao[];
    descricaaoBancoHoras: string;
    listaJornadas: Jornada[];
    jornada: Jornada;
    listaIntervalosEscala: IntervaloJornada[];
    intervaloJornada: IntervaloJornada;


    constructor(
        private serviceEscala: EscalaService,
        private serviceOrgao: OrgaoService,
        private router: Router,
        private serviceJornadaTrabalho: JornadaTrabalhoService,
        private serviceIntervaloJornada: IntervaloJornadaService,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.escala = this.serviceEscala.sharedData;
        this.breadcrumbService.setItems([
            {label: 'Listar Escalas', routerLink: ['/escalas']},
            {label: 'Consultar Escalas'},
        ]);
    }

    ngOnInit(): void {
        this.getOrgaosAll();
        this.getJornadasPorEscala();
        this.getIntervalosPorEscala();
    }

    getOrgaosAll() {
        this.serviceOrgao.listar().subscribe((listaOrgaos) => {
            this.listaOrgaos = listaOrgaos.data;
        });
    }

    onRedirect(url: String) {
        this.router.navigate([url]);
    }

    getJornadasPorEscala() {
        this.serviceJornadaTrabalho.listarJornadasPorEscala().subscribe((listaJornadas) => {
            this.listaJornadas = listaJornadas.filter(jornada => jornada.escala.id === this.escala.id);
        });
    }

    getIntervalosPorEscala() {
        this.serviceIntervaloJornada.listarIntervalosPorEscala().subscribe((listaIntervalosEscala) => {
            this.listaIntervalosEscala = listaIntervalosEscala.filter(intervalo => intervalo.escala.id === this.escala.id);
        });
    }

}
