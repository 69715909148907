<p-toast></p-toast>

<div class="card">
    <h4>Editar Escala de Trabalho</h4>
    <form class="formgrid grid">
        <div class="field col-6">
            <label for="orgao">Orgão </label>
            <p-dropdown
                styleClass="w-full"
                placeholder="Selecione o orgão"
                [options]="listaUnidades"
                [(ngModel)]="escala.unidade.tipoAdministracao"
                optionLabel="organizacaoAdministrativa?.nome"
                optionValue="id"
                inputId="id"
                [(ngModel)]="escala.unidade.tipoAdministracao"
                optionLabel="nome"
                optionValue="id"
                inputId="id"
                (onChange)="getUnidadesPorOrgao($event.value)"
            ></p-dropdown>
        </div>

        <div class="field col-6">
            <label for="orgaoInferior">Unidade Administrativa </label>
            <p-dropdown
                styleClass="w-full"
                [options]="listaUnidades"
                placeholder="Selecione a unidade"
                optionLabel="nome"
                optionValue="id"
                [(ngModel)]="escala.unidade"
                inputId="idUnidade"
            ></p-dropdown>
        </div>

        <div class="field col-4">
            <label for="tipoescala">Tipo Escala </label>
            <p-dropdown
                styleClass="w-full"
                [options]="tiposEscala"
                optionLabel="descricao"
                placeholder="Selecione o Tipo"
                [(ngModel)]="escala.tipoEscala"
                inputId="idTipo"
                (onChange)="getExibeBotoesInserirJornada($event.value)"
            >
            </p-dropdown>
        </div>

        <div class="field col-4">
            <label>Inicio Vigencia </label>
            <p-calendar
                styleClass="w-full"
                [(ngModel)]="escala.dataInicioVigencia"
            ></p-calendar>
        </div>

        <div class="field col-4">
            <label for="descricao">Descrição:</label>
            <input
                pInputText
                [(ngModel)]="escala.descEscalaTrabalho"
                placeholder="Descrição"
                name="descricao"
                class="w-full"
                id="telefone"
            />
        </div>

        <!--div class="field col-4">
            <label>Tipo Escala </label>
            <p-dropdown styleClass="w-full"  [(ngModel)]="escala.tipo" [options]="" ></p-dropdown>
        </div-->

        <div class="field col-4">
            <label for="jornadaDiaria">Jornada Diária:</label>
            <input
                pInputText
                [(ngModel)]="escala.qtdeHorajornadaDiaria"
                placeholder="Jornada Diária"
                name="jornadaDiaria"
                class="w-full"
                id="jornadaDiaria"
            />
        </div>

        <div class="field col-4">
            <label for="jornadaSemanal">Jornada Semanal:</label>
            <input
                pInputText
                [(ngModel)]="escala.qtdeHorajornadaSemanal"
                placeholder="Jornada Semanal"
                name="jornadaSemanal"
                class="w-full"
                id="jornadaSemanal"
            />
        </div>

        <div class="field col-4">
            <label for="jornadaMensal">Jornada Mensal:</label>
            <input
                pInputText
                [(ngModel)]="escala.qtdeHorajornadaMensal"
                placeholder="Jornada Mensal"
                name="jornadaMensal"
                class="w-full"
                id="jornadaMensal"
            />
        </div>

        <div class="field col-4">
            <label>Banco de Horas</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="bancoHoras1"
                        name="bonco1"
                        value="S"
                        [(ngModel)]="escala.indiBancoDeHoras"
                    ></p-radioButton>
                    <label for="bancoHoras1">Sim</label>
                </div>
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="bancoHoras2"
                        name="banco2"
                        value="N"
                        [(ngModel)]="escala.indiBancoDeHoras"
                    ></p-radioButton>
                    <label for="bancoHoras2">Não</label>
                </div>
            </div>
        </div>

        <div class="field col-4">
            <label>Flexibilidade</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="flexibilidade1"
                        name="flexibilidade1"
                        value="S"
                        [(ngModel)]="escala.indiHorarioFlexivel"
                    ></p-radioButton>
                    <label for="flexibilidade1">Sim</label>
                </div>
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="flexibilidade2"
                        name="flexibilidade2"
                        value="N"
                        [(ngModel)]="escala.indiHorarioFlexivel"
                    ></p-radioButton>
                    <label for="flexibilidade2">Não</label>
                </div>
            </div>
        </div>

        <div class="field col-4">
            <label> Escala Continua</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="escalacontinua1"
                        name="escalacontinua1"
                        value="S"
                        [(ngModel)]="escala.indiEscalaContinua"
                    ></p-radioButton>
                    <label for="escalacontinua1">Sim</label>
                </div>
                <div class="field-radiobutton col-2">
                    <p-radioButton
                        id="escalacontinua2"
                        name="escalacontinua2"
                        value="N"
                        [(ngModel)]="escala.indiEscalaContinua"
                    ></p-radioButton>
                    <label for="escalacontinua2">Não</label>
                </div>
            </div>
        </div>

        <div *ngIf="mostraBotoesDeAdicaoDeJornada">
            <button
                pButton
                type="button"
                (click)="abrirDialogJornada()"
                class="p-button-raised p-button-rounded"
                icon="pi pi-plus"
                iconPos="left"
            ></button>
            <button
                pButton
                type="button"
                (click)="abrirDialogJornada()"
                label="adicionar Jornada de trabalho"
                class="p-button-link mr-3"
            ></button>
        </div>

        <div *ngIf="this.mostraBotoesDeIntervalos">
            <button
                pButton
                type="button"
                (click)="abrirDialogIntervalos()"
                class="p-button-raised p-button-rounded"
                icon="pi pi-plus"
                iconPos="left"
            ></button>
            <button
                pButton
                type="button"
                label="adicionar intervalo"
                class="p-button-link"
            ></button>
        </div>

        <!--button pButton type="button"  (click)=" abrirDialogJornada()" label="Inserir jornada Diária +" class="p-button-success mr-3"></button>
        <button pButton type="button"  (click)=" abrirDialogJornadaRevezamento()" label="Inserir Jornada Revezamento +" class="p-button-success mr-3" ></button>
        <button pButton type="button"  (click)=" abrirDialogJornadaTeleatendimento()" label="Inserir Jornada Teleatendimento +" class="p-button-success mr-3"></button-->
    </form>

    <!--p-toolbar styleClass="mb-4 gap-2">
        <div class="p-toolbar-group-left">
        </div>

        <div class="p-toolbar-group-right">

            <p-button label="Listar" icon="pi pi-search"></p-button>
            <p-button label="Nova Escala" icon="pi pi-plus" styleClass="p-button-success"></p-button>


        </div>
    </p-toolbar-->

    <div class="field col-6" *ngIf="listaJornadas.length > 0">
        <p-table [value]="listaJornadas" [lazy]="true" [paginator]="false">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <p>Jornadas</p>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Dia Semana</th>
                    <th>Carga Hor.</th>
                    <th>Entrada</th>
                    <th *ngIf="escala.tipoEscala.id == 1">Inicio Intervalo</th>
                    <th *ngIf="escala.tipoEscala.id == 1">Fim Intervalo</th>
                    <th
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        Primeiro Intervalo
                    </th>
                    <th
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        Segundo Intervalo
                    </th>
                    <th *ngIf="escala.tipoEscala.id == 3">
                        Terceiro Intervalo
                    </th>
                    <th>Saída</th>
                    <th>Editar</th>
                    <th>Escluir</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-jornada>
                <tr>
                    <td>{{ jornada.descricaoDiaSemana }}</td>
                    <td>{{ jornada.quantidadeHoras }}</td>
                    <td>{{ jornada.horaEntrada }}</td>
                    <td *ngIf="escala.tipoEscala.id == 1">
                        {{ jornada.horaIntervaloEntrada1 }}
                    </td>
                    <td *ngIf="escala.tipoEscala.id == 1">
                        {{ jornada.horaIntervaloSaida1 }}
                    </td>
                    <td
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        {{ jornada.horaIntervaloEntrada1 }} -
                        {{ jornada.horaIntervaloSaida1 }}
                    </td>
                    <td
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        {{ jornada.horaIntervaloEntrada2 }} -
                        {{ jornada.horaIntervaloSaida2 }}
                    </td>
                    <td *ngIf="escala.tipoEscala.id == 3">
                        {{ jornada.horaIntervaloEntrada3 }} -
                        {{ jornada.horaIntervaloSaida3 }}
                    </td>
                    <td>{{ jornada.horaSaida }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            icon="pi pi-pencil"
                            class="p-button-text p-button-rounded mr3"
                            pTooltip="Editar a Jornada"
                            tooltipPosition="top"
                        ></button>
                    </td>
                    <td>
                        <button
                            pButton
                            pRipple
                            icon="pi pi-trash"
                            class="p-button-text p-button-rounded mr3"
                            pTooltip="Excluir Jornada"
                            tooltipPosition="top"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="field col-6" *ngIf="listaIntervalosJornada.length > 0">
        <p-table
            [value]="listaIntervalosJornada"
            [lazy]="true"
            [paginator]="false"
        >
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <p>Intervalos Escala</p>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Tempo</th>
                    <th>Limite Inicio.</th>
                    <th>Limite Fim</th>
                    <th>Editar</th>
                    <th>Excluir</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-intervalo>
                <tr>
                    <td>{{ intervalo.numeroMinutosIntervalo }}</td>
                    <td>{{ intervalo.numeroInicioIntervalor }}</td>
                    <td>{{ intervalo.numeroFimIntervalo }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            icon="pi pi-pencil"
                            class="p-button-text p-button-rounded mr3"
                            pTooltip="Editar a Jornada"
                            tooltipPosition="top"
                        ></button>
                    </td>
                    <td>
                        <button
                            pButton
                            pRipple
                            icon="pi pi-trash"
                            class="p-button-text p-button-rounded mr3"
                            pTooltip="Excluir Jornada"
                            tooltipPosition="top"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            pTooltip="Cancelar nova Escala"
            tooltipPosition="top"
            label="Cancelar"
            routerLink="/escalas"
        ></button>
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            pTooltip="Salva uma nova Escala"
            tooltipPosition="top"
            label="Salvar"
            class="p-button-outlined"
            (click)="criarEscala()"
        ></button>
    </div>

    <!--p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">

        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="Cancelar"  class="p-button-success mr-3" routerLink="/escalas"></button>
            <button pButton pRipple label="Salvar" icon="pi pi-search"  class="p-button-success mr-3" (click)="openNew()"></button>

        </ng-template>
    </p-toolbar-->
</div>

<p-dialog
    [(visible)]="adicionaJornada"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="entrada">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaEntrada"
                placeholder="Entrada"
                name="jornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
            />
        </div>

        <div class="field col-4">
            <label for="saida">Saida:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaSaida"
                placeholder="Saida"
                name="jornada.horaSaida"
                class="w-full"
                id="horaSaida"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">Jornada Diaria </label>
            <input
                pInputText
                [(ngModel)]="jornada.quantidadeHoras"
                placeholder="Jornada Diária"
                name="jornada.quantidadeHoras"
                class="w-full"
                id="quantidadeHoras"
            />
        </div>

        <div *ngIf="mostraBotoesDeAdicaoDeJornada"></div>
        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="1"
                label="Seg"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="2"
                label="Ter"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-2">
            <p-checkbox
                name="diasSemana"
                value="3"
                label="Qua"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="4"
                label="Qui"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="5"
                label="Sex"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="6"
                label="Sab"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="7"
                label="Dom"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-12">
            <p><b> Intervalos</b></p>
        </div>

        <div class="field col-4">
            <label for="horaIntervaloEntrada1">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaIntervaloEntrada1"
                placeholder="Intervalo Entrada"
                name="horaIntervaloEntrada1"
                class="w-full"
                id="horaIntervaloEntrada1"
            />
        </div>

        <div class="field col-4">
            <label for="horaIntervaloSaida1">Saida:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaIntervaloSaida1"
                placeholder="Intervalo Saida"
                name="horaIntervaloSaida1"
                class="w-full"
                id="horaIntervaloSaida1"
            />
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog>

<p-dialog
    [(visible)]="adicionaJornadaRevezamento"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="entrada">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaEntrada"
                placeholder="Entrada"
                name="jornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
            />
        </div>

        <div class="field col-4">
            <label for="saida">Jornada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaSaida"
                placeholder="Saida"
                name="jornada.horaSaida"
                class="w-full"
                id="horaSaida"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">InterJornada </label>
            <input
                pInputText
                [(ngModel)]="jornada.quantidadeHoras"
                placeholder="Jornada Diária"
                name="jornada.quantidadeHoras"
                class="w-full"
                id="quantidadeHoras"
            />
        </div>

        <div class="field col-4">
            <label for="horaIntervaloEntrada1">Quantidade Intervalos:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaIntervaloEntrada1"
                placeholder="Intervalo Entrada"
                name="horaIntervaloEntrada1"
                class="w-full"
                id="horaIntervaloEntrada1"
            />
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>

    <!--div class="flex justify-content-end gap-2">


        <button iconPos="right" pButton pRipple type="button" pTooltip="Cancelar nova Escala" tooltipPosition="top"
            label="Cancelar" routerLink="/escalas"></button>
            <button iconPos="right" pButton pRipple type="button" pTooltip="Salva uma nova Escala" tooltipPosition="top"
            label="Salvar" class="p-button-outlined" (click)="criarEscala()"></button>


    </div-->
</p-dialog>

<p-dialog
    [(visible)]="adicionaJornadaTeleatendimento"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="entrada">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaEntrada"
                placeholder="Entrada"
                name="jornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
            />
        </div>

        <div class="field col-4">
            <label for="saida">Saida:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaSaida"
                placeholder="Saida"
                name="jornada.horaSaida"
                class="w-full"
                id="horaSaida"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">Jornada Diaria </label>
            <input
                pInputText
                [(ngModel)]="jornada.quantidadeHoras"
                placeholder="Jornada Diária"
                name="jornada.quantidadeHoras"
                class="w-full"
                id="quantidadeHoras"
            />
        </div>

        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="1"
                label="Seg"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="2"
                label="Ter"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-2">
            <p-checkbox
                name="diasSemana"
                value="3"
                label="Qua"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="4"
                label="Qui"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="5"
                label="Sex"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="6"
                label="Sab"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="7"
                label="Dom"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog>

<p-dialog
    [(visible)]="adicionaIntervalo"
    [style]="{ width: '850px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-4">
            <label for="idNrMinutos"><b> Duração do Intervalo:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaMinutos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="intervalo.numeroMinutosIntervalo"
                inputId="idNrMinutos"
            >
            </p-dropdown>
        </div>

        <div></div>

        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="inicioIntervalo"><b> Inicio:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaPrimeirosIntervalos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="intervalo.numeroInicioIntervalo"
                inputId="inicioIntervalo"
            >
            </p-dropdown>
        </div>

        <div class="field col-4">
            <label for="fimIntervalo"><b> Fim:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaSegundosIntervalos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="intervalo.numeroFimIntervalo"
                inputId="fimIntervalo"
            >
            </p-dropdown>
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog>
