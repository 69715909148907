<p-toast></p-toast>
<p-confirmDialog
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    rejectButtonStyleClass="p-button-text"
    rejectLabel="Cancelar"
    acceptLabel="Deletar"
></p-confirmDialog>
<!-- isToShowDialogConfirmDelete -->

<p-card>
    <ng-template pTemplate="header">
        <div class="grid p-2">
            <div class="col-10">
                <h3><b>Manter escalas</b></h3>
            </div>
            <div class="col-2 flex justify-content-end gap-1">
                <button
                    pButton
                    type="button"
                    class="p-button-raised p-button-rounded"
                    pTooltip="Incluir escala"
                    icon="pi pi-plus"
                    tooltipPosition="top"
                    routerLink="/escala/salvar"
                ></button>
                <button
                    pButton
                    type="button"
                    class="p-element p-ripple p-button-rounded p-button-text p-button p-component p-button-icon-only"
                    pTooltip="Fechar filtro"
                    [icon]="isShowFormFilter ? 'pi pi-times' : 'pi pi-filter'"
                    tooltipPosition="top"
                    (click)="handleShowFilter()"
                ></button>
            </div>
        </div>
    </ng-template>
    <div [hidden]="!isShowFormFilter">
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="orgao">Órgão</label>
                <p-autoComplete
                    autoClear="true"
                    field="name"
                    [style]="{ minWidth: '100%' }"
                    [inputStyle]="{ minWidth: '100%' }"
                    [suggestions]="autoCompleteOrgaos"
                    [(ngModel)]="formFilter.orgao"
                    (completeMethod)="onChangeOrgaoAutocomplete($event)"
                ></p-autoComplete>
            </div>
            <!-- <div class="field col-6">
                <label for="unidadeAdministrativa"
                    >Unidade Administrativa (opicional)</label
                >
                <p-dropdown
                    styleClass="w-full"
                    [options]="listaUnidades"
                    placeholder="Selecione..."
                    id="unidadeAdministrativa"
                    optionLabel="nome"
                    optionValue="id"
                    [(ngModel)]="formFilter.idOrganizacaoAdministrativa"
                    inputId="idUnidade"
                ></p-dropdown>
            </div> -->
            <!-- <div class="field col-4">
                <label for="jornadaDiaria">Jornada Diaria (opicional)</label>
                <p-dropdown
                    styleClass="w-full"
                    [options]=""
                    optionLabel="nome"
                    placeholder="Selecione..."
                    id="jornadaDiaria"
                    [(ngModel)]="formFilter.jornadaDiaria"
                    inputId="jornadaDiaria"
                >
                </p-dropdown>
            </div> -->
            <!-- <div class="field col-4">
                <label for="jornadaSemanal">Jornada Semanal (opicional)</label>
                <p-dropdown
                    styleClass="w-full"
                    [options]=""
                    optionLabel="nome"
                    placeholder="Selecione..."
                    id="jornadaSemanal"
                    [(ngModel)]="formFilter.jornadaSemanal"
                    inputId="jornadaSemanal"
                >
                </p-dropdown>
            </div>
            <div class="field col-4">
                <label for="jornadaMensal">Jornada Mensal (opicional)</label>
                <p-dropdown
                    styleClass="w-full"
                    [options]=""
                    optionLabel="nome"
                    placeholder="Selecione..."
                    id="jornadaMensal"
                    [(ngModel)]="formFilter.jornadaMensal"
                    inputId="jornadaMensal"
                >
                </p-dropdown>
            </div>
            <div class="field col-4">
                <label for="situacao">Situacao (opicional)</label>
                <p-dropdown
                    styleClass="w-full"
                    [options]="situacoes"
                    optionLabel="nome"
                    placeholder="Selecione..."
                    id="situacao"
                    [(ngModel)]="formFilter.situacao"
                    inputId="situacao"
                >
                </p-dropdown>
            </div> -->
            <div class="field col-6">
                <label for="tipo">Tipo Escala</label>
                <p-dropdown
                    styleClass="w-full"
                    optionLabel="descricao"
                    placeholder="Selecione..."
                    inputId="tipo"
                    [(ngModel)]="formFilter.tipoEscala"
                    [options]="listTiposEscalas"
                >
                </p-dropdown>
            </div>
            <!-- <div class="field col-4">
                <label>Banco de Horas</label>
                <div class="formgrid grid fontForm8">
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            id="bancoHoras1"
                            name="banco1"
                            value="S"
                            [(ngModel)]="formFilter.bancoHoras"
                        ></p-radioButton>
                        <label for="bancoHoras1">Sim </label>
                    </div>
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            id="bancoHoras2"
                            name="banco2"
                            value="N"
                            [(ngModel)]="formFilter.bancoHoras"
                        ></p-radioButton>
                        <label for="bancoHoras2"> Não</label>
                    </div>
                </div>
            </div>
            <div class="field col-4">
                <label for="inicioVigencia">Inicio Vigencia (opicional)</label>
                <p-calendar
                    dateFormat="dd/mm/yy"
                    styleClass="w-full"
                    [(ngModel)]="formFilter.inicioVigencia"
                    inputId="inicioVigencia"
                    id="inicioVigencia"
                ></p-calendar>
            </div> -->
            <div class="field col-6">
                <label for="descricao">Descrição</label>
                <input
                    pInputText
                    placeholder="Descrição"
                    name="descricao"
                    class="w-full"
                    [(ngModel)]="formFilter.descricao"
                />
            </div>
            <div class="col-12 flex justify-content-end gap-2">
                <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="button"
                    pTooltip="Limpa os dados da tela"
                    tooltipPosition="top"
                    label="Limpar"
                    class="p-button-text"
                    (click)="handleClear()"
                ></button>
                <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="button"
                    pTooltip="Consulta uma Escala"
                    tooltipPosition="top"
                    label="Consultar"
                    (click)="handleSearch()"
                ></button>
            </div>
        </div>
    </div>
    <p-table
        [lazy]="true"
        [paginator]="true"
        [rows]="10"
        (onPage)="onChangePage($event)"
        [totalRecords]="resultPaginated?.totalElements || 0"
        [value]="resultPaginated?.content"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>Órgão</th>
                <!-- <th>Unidade Administrativa</th> -->
                <th>Tipo da escala</th>
                <th>Descrição</th>
                <th class="text-center">Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.orgao.organizacaoAdministrativa.nome }}</td>
                <!-- <td>{{ item.orgao.organizacaoAdministrativa.nome }}</td> -->
                <td>{{ item.tipoEscala.descricao }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center gap-2">
                    <button
                        pButton
                        pRipple
                        icon="pi pi-search"
                        class="p-button-text p-button-rounded"
                        pTooltip="Visualizar escala"
                        tooltipPosition="top"
                        (click)="handleShowEscala(item)"
                    ></button>

                    <button
                        pButton
                        pRipple
                        icon="pi pi-pencil"
                        class="p-button-text p-button-rounded"
                        (click)="handleEditEscala(item)"
                        pTooltip="Editar escala"
                        tooltipPosition="top"
                    ></button>

                    <button
                        pButton
                        pRipple
                        icon="pi pi-calendar"
                        class="p-button-text p-button-rounded"
                        (click)="handleShowDialogJornada(item)"
                        pTooltip="Visualizar jornada"
                        tooltipPosition="top"
                    ></button>
                    <button
                        pButton
                        pRipple
                        icon="pi pi-users"
                        pTooltip="Vincular escala"
                        tooltipPosition="top"
                        class="p-button-text p-button-rounded"
                        (click)="showEscalaPorTipo(item)"
                    ></button>
                    <button
                        pButton
                        pRipple
                        pTooltip="Desativar escala"
                        tooltipPosition="top"
                        [class]="
                            item?.status === 'S'
                                ? 'p-button-text p-button-rounded'
                                : 'p-button-text p-button-rounded p-button-danger'
                        "
                        [icon]="
                            item?.status === 'S'
                                ? 'pi pi-check-circle'
                                : 'pi pi-times-circle'
                        "
                        (click)="handleChangeStatus(item)"
                    ></button>
                    <button
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        class="p-button-text p-button-rounded"
                        pTooltip="Excluir escala"
                        tooltipPosition="top"
                        (click)="handleConfirmDelete(item)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>

<p-dialog
    [(visible)]="isToShowJornada"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <!--div  *ngIf="listaJornadas.length > 0"-->
    <h2>Jornada de Trabalho - >{{ tituloDialog }}</h2>

    <p-table [value]="listaJornadas" [lazy]="true" [paginator]="false">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p>Jornadas</p>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Dia Semana</th>
                <th>Carga Hor.</th>
                <th>Entrada</th>
                <th>Inicio Intervalo</th>
                <th>Fim Intervalo</th>
                <th>Saída</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-jornada>
            <tr>
                <td>{{ jornada.descricaoDiaSemana }}</td>
                <td>{{ jornada.quantidadeHoras }}</td>
                <td>{{ jornada.horaEntrada }}</td>
                <td>{{ jornada.horaIntervaloEntrada1 }}</td>
                <td>{{ jornada.horaIntervaloSaida1 }}</td>
                <td>{{ jornada.horaSaida }}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td>
                    <p>Não há jornadas cadastradas</p>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <!--/div-->
    <!--ng-template #semJornadas>
        <p>Não há jornadas cadstradas para essa escala</p>
     </ng-template-->

    <p-table [value]="listaIntervalosEscala" [lazy]="true" [paginator]="false">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p>Intervalos Escala</p>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th>Tempo</th>
                <th>Limite Inicio.</th>
                <th>Limite Fim</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-intervalo>
            <tr>
                <td>{{ intervalo.numeroMinutosIntervalo }}</td>
                <td>{{ intervalo.numeroInicioIntervalor }}</td>
                <td>{{ intervalo.numeroFimIntervalo }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td>
                    <p>Não há intervalos cadastrados</p>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
