<p-toast></p-toast>

<div class="card">
    <h5>Visualizar Escalas</h5>
    <p>Detalhe da escala de trabalho de servidores</p>

    <form class="formgrid grid">
        <div class="field col-4">
            <label><b> Orgão </b></label>
            <p>{{ escala.organizacaoAdministrativa.nome }}</p>
        </div>

        <div class="field col-4">
            <label><b>Unidade Administrativa</b> </label>
            <p>{{ escala.organizacaoAdministrativa.nome }}</p>
        </div>

        <div class="field col-4">
            <label><b> Ativa? </b> </label>
            <p>{{ escala.status }}</p>
        </div>

        <div class="field col-4">
            <label> <b> Tipo Escala</b> </label>
            <p>{{ escala.tipoEscala.descricao }}</p>
        </div>

        <div class="field col-3">
            <label> <b> Inicio Vigencia</b> </label>
            <p>{{ escala.dataInicioVigencia }}</p>
        </div>

        <div class="field col-2">
            <label> <b> Possui Banco de Horas? </b> </label>
            <p>{{ escala.indiBancoDeHoras === "SIM" ? "Sim" : "Não" }}</p>
        </div>

        <div class="field col-4">
            <label> <b> Jornada Diária</b> </label>
            <p>{{ escala.qtdeHorajornadaDiaria }}</p>
        </div>

        <div class="field col-4">
            <label> <b>Jornada Semanal</b> </label>
            <p>{{ escala.qtdeHorajornadaSemanal }}</p>
        </div>

        <div class="field col-4">
            <label> <b> Jornada Mensal</b> </label>
            <p>{{ escala.qtdeHorajornadaMensal }}</p>
        </div>
    </form>

    <div class="field col-6" *ngIf="listaJornadas.length > 0">
        <p-table [value]="listaJornadas" [lazy]="true" [paginator]="false">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <p>Jornadas</p>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Dia Semana</th>
                    <th>Carga Hor.</th>
                    <th>Entrada</th>
                    <th *ngIf="escala.tipoEscala.id == 1">Inicio Intervalo</th>
                    <th *ngIf="escala.tipoEscala.id == 1">Fim Intervalo</th>
                    <th
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        Primeiro Intervalo
                    </th>
                    <th
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        Segundo Intervalo
                    </th>
                    <th *ngIf="escala.tipoEscala.id == 3">
                        Terceiro Intervalo
                    </th>
                    <th>Saída</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-jornada>
                <tr>
                    <td>{{ jornada.descricaoDiaSemana }}</td>
                    <td>{{ jornada.quantidadeHoras }}</td>
                    <td>{{ jornada.horaEntrada }}</td>
                    <td *ngIf="escala.tipoEscala.id == 1">
                        {{ jornada.horaIntervaloEntrada1 }}
                    </td>
                    <td *ngIf="escala.tipoEscala.id == 1">
                        {{ jornada.horaIntervaloSaida1 }}
                    </td>
                    <td
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        {{ jornada.horaIntervaloEntrada1 }} -
                        {{ jornada.horaIntervaloSaida1 }}
                    </td>
                    <td
                        *ngIf="
                            escala.tipoEscala.id == 2 ||
                            escala.tipoEscala.id == 3
                        "
                    >
                        {{ jornada.horaIntervaloEntrada2 }} -
                        {{ jornada.horaIntervaloSaida2 }}
                    </td>
                    <td *ngIf="escala.tipoEscala.id == 3">
                        {{ jornada.horaIntervaloEntrada3 }} -
                        {{ jornada.horaIntervaloSaida3 }}
                    </td>
                    <td>{{ jornada.horaSaida }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="field col-6" *ngIf="listaIntervalosEscala.length > 0">
        <p-table
            [value]="listaIntervalosEscala"
            [lazy]="true"
            [paginator]="false"
        >
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <p>Intervalos Escala</p>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Tempo</th>
                    <th>Limite Inicio.</th>
                    <th>Limite Fim</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-intervalo>
                <tr>
                    <td>{{ intervalo.numeroMinutosIntervalo }}</td>
                    <td>{{ intervalo.numeroInicioIntervalor }}</td>
                    <td>{{ intervalo.numeroFimIntervalo }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="Voltar"
                class="p-button-success mr-3"
                routerLink="/escalas"
            ></button>
        </ng-template>
    </p-toolbar>
</div>
