<p-toast></p-toast>
<p-dialog
    [(visible)]="isShowSave"
    [style]="{ height: '20vw', width: '800px' }"
    header="Adicionar Órgãos"
>
    <!-- <ng-template pTemplate="header"> Adicionar Órgão </ng-template> -->
    <div class="div-fields">
        <div class="formgrid grid">
            <div class="field col-12 div-item">
                <label><b>Órgão:</b></label>
                <p-autoComplete
                    [(ngModel)]="formSave.list"
                    [suggestions]="formSaveAutocomplete"
                    (completeMethod)="onChangeOrgaoAutocomplete($event)"
                    [multiple]="true"
                    field="name"
                    [style]="{ minWidth: '100%' }"
                    [inputStyle]="{ minWidth: '100%' }"
                    class="p-autocomplete"
                ></p-autoComplete>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Cancelar"
                class="p-button-text"
                (click)="handleFormSaveDialog()"
            ></button>
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Salvar"
                (click)="handleSubmit()"
            ></button>
        </div>
    </ng-template>
</p-dialog>
<p-card>
    <ng-template pTemplate="header">
        <div class="grid p-2">
            <div class="col-10">
                <h3>Manter órgãos</h3>
            </div>
            <div class="col-2 flex justify-content-end gap-1">
                <button
                    pButton
                    type="button"
                    class="p-button-raised p-button-rounded"
                    pTooltip="Incluir órgão"
                    icon="pi pi-plus"
                    tooltipPosition="top"
                    (click)="handleFormSaveDialog()"
                ></button>
                <button
                    pButton
                    type="button"
                    class="p-element p-ripple p-button-rounded p-button-text p-button p-component p-button-icon-only"
                    pTooltip="Fechar filtro"
                    [icon]="isShowFormFilter ? 'pi pi-times' : 'pi pi-filter'"
                    tooltipPosition="top"
                    (click)="handleShowFilter()"
                ></button>
            </div>
        </div>
    </ng-template>
    <div [hidden]="!isShowFormFilter">
        <!-- <p class="title"><b></b></p> -->
        <!-- <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Adicionar"
            (click)="handlerSave()"
        ></button>
    </div> -->
        <div class="div-fields">
            <div class="formgrid grid">
                <div class="field col-6 div-item">
                    <label><b>Órgão:</b></label>
                    <input
                        [(ngModel)]="formFilter.nome"
                        placeholder="Digite aqui a sigla ou o nome do órgão"
                        type="text"
                        pInputText
                        class="w-full"
                    />
                </div>
                <div class="field col-6 div-item">
                    <label><b>Situação</b></label>
                    <p-dropdown
                        styleClass="w-full"
                        [options]="situacoes"
                        optionLabel="nome"
                        placeholder="Selecione a situação"
                        [(ngModel)]="formFilter.status"
                        inputId="situacao"
                    >
                    </p-dropdown>
                </div>
            </div>
        </div>

        <div class="flex justify-content-end gap-2">
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Limpar"
                class="p-button-text"
                (click)="clear()"
            ></button>
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Consultar"
                (click)="fetchAll()"
            ></button>
        </div>
    </div>
    <p-divider></p-divider>

    <div class="div-fields">
        <p-table
            styleClass="p-datatable-striped p-datatable-sm"
            [value]="orgaos"
            [lazy]="true"
            [paginator]="true"
            [rowHover]="true"
            [rows]="10"
            [totalRecords]="totalElements"
            (onPage)="onPageChange($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Órgão</th>
                    <!-- <th>Data de Criação</th> -->
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orgao>
                <tr>
                    <td>{{ orgao?.organizacaoAdministrativa?.nome }}</td>
                    <!-- <td>{{ orgao?.dtCriacao }}</td> -->
                    <td>
                        <button
                            pButton
                            pRipple
                            type="button"
                            [class]="
                                orgao?.status === 'A'
                                    ? 'p-button-text p-button-rounded'
                                    : 'p-button-text p-button-rounded p-button-danger'
                            "
                            [icon]="
                                orgao?.status === 'A'
                                    ? 'pi pi-check-circle'
                                    : 'pi pi-times-circle'
                            "
                            pTooltip="Inativar"
                            tooltipPosition="top"
                            (click)="handleChangeStatus(orgao)"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            (icon)="
                                (orgao?.organizacaoAdministrativa?.status ===
                                    '')
                            "
                            pTooltip="Visualizar"
                            tooltipPosition="top"
                            (click)="show(orgao)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
