import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {InputComponent} from "./components/input/input.component";
import {AuthGuard} from "./modules/core/auth/auth.guard.service";
import {NonAuthGuard} from "./modules/core/auth/non-auth.guard";
import {AppMainComponent} from "./modules/main/app.main.component";
import {ClienteAddComponent} from "./pages/cliente/cliente-add/cliente-add.component";
import {ClienteEditComponent} from "./pages/cliente/cliente-edit/cliente-edit.component";
import {ClienteListComponent} from "./pages/cliente/cliente-list/cliente-list.component";
import {CrudProdutosComponent} from "./pages/crud-produtos/crud-produtos.component";
import {CrudUsuariosListComponent} from "./pages/crud-usuarios/crud-usuarios-list/crud-usuarios-list.component";
import {
    CrudUsuariosRegisterComponent
} from "./pages/crud-usuarios/crud-usuarios-register/crud-usuarios-register.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {EscalaConsultarComponent} from "./pages/escala/escala-consultar/escala-consultar.component";
import {EscalaCreateComponent} from "./pages/escala/escala-create/escala-create.component";
import {EscalaListComponent} from "./pages/escala/escala-list/escala-list.component";
import {PerfilComponent} from "./pages/perfil/perfil.component";
import {EstagiarioDetailComponent} from "./pages/servidores/estagiario-detail/estagiario-detail.component";
import {ServidorDetailComponent} from "./pages/servidores/servidor-detail/servidor-detail.component";
import {ServidoresListComponent} from "./pages/servidores/servidores-list/servidores-list.component";
import {EscalaDiariaComponent} from "./pages/vinculo-escala/escala-diaria/escala-diaria.component";
import {EscalaRevezamentoComponent} from "./pages/vinculo-escala/escala-revezamento/escala-revezamento.component";
import {
    EscalaTeleatendimentoComponent
} from "./pages/vinculo-escala/escala-teleatendimento/escala-teleatendimento.component";
import {AppAccessdeniedComponent} from "./utilities/app.accessdenied.component";
import {AppErrorComponent} from "./utilities/app.error.component";
import {AppLoginComponent} from "./utilities/app.login.component";
import {AppNotfoundComponent} from "./utilities/app.notfound.component";
import {EscalaEditarComponent} from "./pages/escala/escala-editar/escala-editar.component";
import {OrgaosListComponent} from "./pages/orgaos/orgaos-list/orgaos-list.component";
import {FechamentosListComponent} from "./pages/fechamentos/fechamentos-list/fechamentos-list.component";
import {FechamentoSaveComponent} from "./pages/fechamentos/fechamento-save/fechamento-save.component";
import { EspelhoDePontoComponent } from "./pages/espelho-de-ponto/espelho-de-ponto.component";
import { PontoSaveComponent } from "./pages/registrar-ponto/ponto-save/ponto-save.component";
import { EscalaSaveComponent } from "./pages/escala/escala-save/escala-save.component";
import { EscalaShowComponent } from "./pages/escala/escala-show/escala-show.component";

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: "",
                    component: AppMainComponent,
                    canActivate: [AuthGuard],
                    canActivateChild: [AuthGuard],
                    children: [
                        {
                            path: "dashboard",
                            component: DashboardComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "registrar-ponto",
                            component: PontoSaveComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "produtos",
                            component: CrudProdutosComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "clientes",
                            component: ClienteListComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "clientes-add",
                            component: ClienteAddComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "clientes-edit",
                            component: ClienteEditComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "usuarios-list",
                            component: CrudUsuariosListComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "usuarios-register",
                            component: CrudUsuariosRegisterComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "usuarios-register/:id",
                            component: CrudUsuariosRegisterComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "perfil",
                            component: PerfilComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "input",
                            component: InputComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "orgaos",
                            component: OrgaosListComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "escalas",
                            component: EscalaListComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "escala/salvar",
                            component: EscalaSaveComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "escala/salvar/:id",
                            component: EscalaSaveComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "escala/:id",
                            component: EscalaShowComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "escalas-editar",
                            component: EscalaEditarComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "vinculo-escala-diaria/:id",
                            component: EscalaDiariaComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "vinculo-escala-revezamento/:id",
                            component: EscalaRevezamentoComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "vinculo-escala-teleatendimento/:id",
                            component: EscalaTeleatendimentoComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "dashboard",
                            component: DashboardComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "produtos",
                            component: CrudProdutosComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "clientes",
                            component: ClienteListComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "clientes-add",
                            component: ClienteAddComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "clientes-edit",
                            component: ClienteEditComponent,
                            canActivate: [AuthGuard],
                        },
                        {
                            path: "usuarios-list",
                            component: CrudUsuariosListComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "usuarios-register",
                            component: CrudUsuariosRegisterComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "usuarios-register/:id",
                            component: CrudUsuariosRegisterComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "perfil",
                            component: PerfilComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "input",
                            component: InputComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "servidores",
                            component: ServidoresListComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "servidor",
                            component: ServidorDetailComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "servidor/:id",
                            component: ServidorDetailComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "estagiario",
                            component: EstagiarioDetailComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "fechamentos",
                            component: FechamentosListComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "fechamento/salvar",
                            component: FechamentoSaveComponent,
                            canActivate: [NonAuthGuard],
                        },
                        {
                            path: "fechamento/salvar/:id",
                            component: FechamentoSaveComponent,
                            canActivate: [NonAuthGuard],
                        }
                    ],
                },
                {
                    path: "error",
                    component: AppErrorComponent,
                    canActivate: [NonAuthGuard],
                },
                {
                    path: "access",
                    component: AppAccessdeniedComponent,
                    canActivate: [NonAuthGuard],
                },
                {
                    path: "notfound",
                    component: AppNotfoundComponent,
                    canActivate: [NonAuthGuard],
                },
                {
                    path: "login",
                    component: AppLoginComponent,
                    canActivate: [NonAuthGuard],
                },
            ],
            {initialNavigation: "disabled"}
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
