import {Component, OnInit} from '@angular/core';
import {Orgao} from "../../../modules/corporativo/models/orgao";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FechamentoFormSave } from 'src/app/modules/scf/models/types/fechamento-form-save.type';
import { Fechamento } from 'src/app/modules/scf/models/fechamento.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrgaosService } from 'src/app/modules/scf/services/orgaos.service';
import { MessageService } from 'primeng/api';
import { FechamentoService } from 'src/app/modules/scf/services/fechamento.service';
import { first } from 'rxjs';
import { DateUtil } from 'src/app/modules/scf/utils/date.util';
import { TipoEscala } from '../interfaces/tipoEscala';
import { TiposEscalasService } from 'src/app/modules/scf/services/tipos-escalas.service';
import { Messages } from 'src/app/modules/scf-core/constants/messages';
import { EscalaService } from 'src/app/modules/scf/services/escala.service';
import { EscalaType } from 'src/app/modules/scf/models/types/escala.type';

@Component({
    selector: 'app-escala-show',
    templateUrl: './escala-show.component.html'
})
export class EscalaShowComponent implements OnInit {
    listTiposEscalas: TipoEscala[];
    autoCompleteOrgaos: object[];
    formSave: FormGroup;
    data: EscalaType;

    constructor(
        private service: EscalaService,
        private serviceTiposEscalas: TiposEscalasService,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
    ) {
    }

    ngOnInit(): void {
        if (this.service.sharedData) {
            this.data = this.service.sharedData
        } else {
            this.route.paramMap.subscribe(params => {
                const id = params.get('id');
                if (!id) {
                    this.handleCancel();
                    return;
                }
                this.fetchData(params.get('id'))
            });
        }
    }

    fetchData(id: any) {
        this.service.findById(id).subscribe((response) => {
            this.data = response.data;
        },
        _ => {
            this.messageService.add({severity:'error', summary: 'Ops!', detail: Messages.error.service, life: 3000});
        });
    }

    handleCancel(): void{
        this.router.navigate(['escalas']);
    }

    fetchTiposEscalas() {
        this.serviceTiposEscalas.findAll().subscribe((response) => {
            this.listTiposEscalas = response.data;
        });
    }
}
