import {Component, OnInit} from '@angular/core';
import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OrgaoService} from 'src/app/pages/escala/services/orgao.service';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';

@Component({
    selector: 'app-escala-diaria',
    templateUrl: './escala-diaria.component.html',
    styleUrls: ['./escala-diaria.component.scss']
})
export class EscalaDiariaComponent implements OnInit {


    listaOrgaos: Orgao[];
    orgaoSelecionado: Orgao;
    servidoresSelecionados: any[];
    value: boolean;


    servidor = {
        id: '',
        unidade: '',
        nome: '',
        cpf: '',
        codVinculo: '',
        cargo: ''
    };


    servidores: any[] = [
        {
            id: 1,
            unidade: 'Penitenciária Coronel Odenir Guimarães',
            nome: 'João Luis da Silva',
            cpf: '225.343.124-15',
            codVinculo: '0001526',
            cargo: 'Agente Penitenciário',

        },
        {
            id: 2,
            unidade: 'Penitenciária Coronel Odenir Guimarães',
            nome: 'João Luis Oliveira',
            cpf: '145.346.789-13',
            codVinculo: '0008040',
            cargo: 'Agente de Segurança Prisional',
        },
        {
            id: 3,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Santos',
            cpf: '351.424.715-26',
            codVinculo: '0009030',
            cargo: 'Médico Cardiologista',
        },
        {
            id: 4,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Pereira',
            cpf: '141.346.720-18',
            codVinculo: '0004359',
            cargo: 'Enfermeiro',
        },
        {
            id: 5,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Sousa',
            cpf: '804.202.395-81',
            codVinculo: '0002517',
            cargo: 'Fisioterapeuta',
        },
    ];


    constructor(
        private serviceOrgao: OrgaoService,
        private messageService: MessageService, private confirmationService: ConfirmationService,
        private breadcrumbService: AppBreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Gestor', routerLink: ['#']}, {label: 'Escalas', routerLink: ['/escalas']}, {
                label: 'Escala-diaria',
                routerLink: ['/escala-diaria']
            }
        ]);

    }

    ngOnInit(): void {
        this.getOrgaosAll();
    }

    getOrgaosAll() {
        this.serviceOrgao.listar().subscribe((listaOrgaos) => {
            console.log(listaOrgaos);
            this.listaOrgaos = listaOrgaos.data;
            console.log(this.listaOrgaos);
        });

    }

    pesquisa() {

    }

}
