import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractCrudService } from "../../shared/services/abstract-crud.service";
import { Cliente } from './cliente.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends AbstractCrudService<Cliente> {
  constructor(protected override http: HttpClient) {
    super(http, `${environment.apiUrl}`);
  }
}
