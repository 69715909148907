import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import { CrudUsuariosService } from '../crud-usuarios.service';

@Component({
  selector: 'app-crud-usuarios-list',
  templateUrl: './crud-usuarios-list.component.html',
  styleUrls: ['./crud-usuarios-list.component.scss']
})
export class CrudUsuariosListComponent implements OnInit {
  users: any[] = [];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private crudUsuariosService: CrudUsuariosService,
    private breadcrumbService: AppBreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'Usuários', routerLink: ['/usuarios-list'] }
        ]);
    }

  ngOnInit() {
    this.crudUsuariosService.getUsers().then((users: any[]) =>{
      this.users = users;
      console.log(users)
    })
  }

  goToRegister(){
    this.router.navigate([`usuarios-register`]);
  }

  deleteUser(user: any) {
    this.confirmationService.confirm({
        message: 'você tem certeza que deseja deletar ' + user.usuario + '?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.crudUsuariosService.deleteUserById(user.id).then((users: any[]) =>{
            this.users = users;
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Usuario deletado!', life: 3000});
          })
        }
    });
  }

  abrirPdf(file: any) {
    window.open(URL.createObjectURL(file));
  }

  editar(id: string){
    this.router.navigate([`usuarios-register/${id}`]);
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }
}
