import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, MessageService, SortEvent } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import { IOrder } from 'src/app/shared/interfaces/order.interface';
import { IPaginate } from 'src/app/shared/interfaces/paginate.interface';
import { PageRequest } from 'src/app/shared/models/page-sort/page-request.model';
import { Page } from 'src/app/shared/models/page-sort/page.model';
import { SortDirection } from 'src/app/shared/models/page-sort/sort-direction.enum';
import { Sort } from 'src/app/shared/models/page-sort/sort.model';
import { Cliente } from '../cliente.model';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.scss']
})
export class ClienteListComponent implements OnInit {
  public pageRequest: PageRequest;
  public sort: Sort;
  public data: Page<Cliente>;
  public page: number = 0;
  public totalElements: number = 0;
  public loadingCliente: boolean = false;

  public searchResults: Cliente[];

  constructor(private router: Router,
              private messageService: MessageService,
              private clienteService: ClienteService,
              private breadcrumbService: AppBreadcrumbService) {

                this.breadcrumbService.setItems([
                    { label: 'Cleinte', routerLink: ['/clientes']},
                ]);

  }

  ngOnInit(): void {
    this.limparEPesquisar();
  }

  getContent(): Cliente[] {
    if (this.page && this.data.content) {
      return this.data.content;
    } else {
      return [];
    }
  }

  limparEPesquisar() {
    this.sort = new Sort('id', SortDirection.ASCENDING);
    this.pageRequest = new PageRequest(0, 10, this.sort);
    this.pesquisar();
  }

  pesquisar() {
    this.loadingCliente = true;
    this.clienteService.page(this.pageRequest).subscribe(
      (response: any) => {
        this.data = response;
        this.totalElements = response.totalElements;
      },
      (error: any) => {
        this.messageService.add({severity:'error', summary: 'Erro', detail: 'Não foi possível listar os Clientes.', life: 3000});
      }
    ).add(() => {
      this.loadingCliente = false;
    });
  }

  addCliente(): void {
    this.router.navigate(['clientes-add']);
  }

  editCliente(cliente: Cliente): void {
    window.localStorage.removeItem('editClienteId');
    window.localStorage.setItem('editClienteId', cliente.id.toString());
    this.router.navigate(['clientes-edit']);
  }

  deleteCliente(cliente: Cliente): void {
    this.clienteService.delete(cliente.id)
      .subscribe((data: any) => {
          if (data != null) {
            alert('Cliente deleted.');
            this.searchResults = this.searchResults.filter(c => c !== cliente);
          } else {
            alert(data);
          }
        },
        (error: any) => {
          this.messageService.add({severity:'error', summary: 'Erro', detail: error.message, life: 3000});
        });
  }

  changeOrder(event: SortEvent): void {
    console.log(event);
    /* this.pageRequest.sort = new Sort(column, direction);
    this.pesquisar(); */
  }

  loadClientes(event: LazyLoadEvent){
    this.page = Number(event.first) / Number(event.rows);
    this.pageRequest = new PageRequest(this.page, 10, this.sort);

    this.pesquisar();
  }
}
