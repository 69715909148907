import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {RegimeJuridico} from "src/app/shared/models/servidor/regime.juridico";
import {Servidor} from "src/app/shared/models/servidor/servidor.model";
import {PageableServidor} from "src/app/shared/models/servidor/servidor.page.model";
import {SituacaoFuncional} from "src/app/shared/models/servidor/situacao.funcional";
import {RegimeJuridicoService} from "src/app/shared/services/servidor/regime-juridico.service";
import {ServidorService} from "src/app/shared/services/servidor/servidor.service";
import {SituacaoFuncionalService} from "src/app/shared/services/servidor/situacao-funcional.service";
import {AppBreadcrumbService} from "../../../modules/main/breadcrumb/app.breadcrumb.service";
import {Orgao} from "../../../modules/scf/models/orgao.model";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import {CPFUtils} from "../../../utilities/cpf-utils";
import { Situacao } from "../../escala/interfaces/situacao";
import { SituacaoService } from "../../escala/services/situacao.service";
import { SituacoesService } from "../../escala/services/situacoes.service";
import { PageableOrgao } from "src/app/modules/scf/models/orgao.page.model";
import { OrgaosService } from "src/app/modules/scf/services/orgaos.service";
import { OrganizacoesAdministrativasService } from "src/app/modules/corporativo/services/organizacoes-administrativas.service";

@Component({
    selector: "app-ponto-list",
    templateUrl: "./ponto-list.component.html",
    styleUrls: ["./ponto-list.component.scss"],
})
export class PontoListComponent implements OnInit {
    orgaos: Orgao[];
    situacoes: Situacao[];
    regimes: RegimeJuridico[];
    lotacoes: Orgao[];
    orgaosOrigem: Orgao[];
    totalElements: number;
    isShowSave: boolean = false;
    filtro = {
        nome: "",
        situacao: "",
        orgaoOrigem: null,
        cpf: "",
        regimeJuridico: null,
        situacaoFuncional: null,
        codigoVinculo: null,
        orgaoId: null,
    };
    formSave = {
        text: "",
    };
    formSaveAutocomplete: string[];
    queryParam: string;

    constructor(
        private situacaoService: SituacoesService,
        private orgaosService: OrgaosService,
        private organizacoesAdministrativasService: OrganizacoesAdministrativasService,
        private router: Router,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            {label: "Orgãos", routerLink: ["/orgaos"]},
        ]);
    }

    ngOnInit(): void {
        this.getSituacoes();
        this.clear();
        this.formSaveAutocomplete = ["todos"];
        // this.initiateDataLoading(
        //     this.orgaoOrigemService,
        //     (response) => (this.orgaosOrigem = response)
        // );
    }

    findByName(event) {
        this.formSaveAutocomplete = ["todos"];
        this.organizacoesAdministrativasService.listAll(event.query).subscribe(data => {
            console.log(data)
            // this.results = data;
        });

        //tagOuNome
        // this.mylookupservice.getResults(event.query).then(data => {
        //     this.results = data;
        // });
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    handlerSave() {
        this.isShowSave = !this.isShowSave;
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    getSituacoes() {
        this.situacaoService.listar().subscribe((situacoes) => {
        // situacoes.unshift({id: "", nome: "Todos"})
        this.situacoes = situacoes
        });
    }

    // initiateDataLoading(service: any, callback: (response: any[]) => void) {
    //     service.listAll().subscribe((response: any[]) => {
    //         callback(response);
    //     });
    // }

    clear() {
        for (const chave in this.filtro) {
            delete this.filtro[chave];
        }
        this.queryParam = "";
        this.fetchOrgaos();
    }

    fetchOrgaos() {
        this.orgaosService
            .listByFilter(0, this.createQueryString(this.filtro))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            })
    }

    onPageChange(event: any) {
        const page = event.first / event.rows;
        // const page = event.page;
        this.createQueryString(this.filtro)
        this.orgaosService
            .listByFilter(page, this.createQueryString(this.filtro))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            });
    }

    changeStatus(orgao: Orgao) {
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    show(orgao: Orgao) {
        // this.servidorService.sharedData = servidor;
        this.router.navigate(["/servidor"]);
    }

    private createQueryString(filtro: { [key: string]: string }): string {
        const partesQueryParam = [];
        for (const chave in filtro) {
            if (filtro[chave]) {
                partesQueryParam.push(
                    `${chave}=${encodeURIComponent(filtro[chave])}`
                );
            }
        }
        return partesQueryParam.join("&");
    }
}
