import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-cliente-add',
  templateUrl: './cliente-add.component.html',
  styleUrls: ['./cliente-add.component.scss']
})
export class ClienteAddComponent implements OnInit {
  addForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private messageService: MessageService,
              private clienteService: ClienteService,
              private breadcrumbService: AppBreadcrumbService) {

                this.breadcrumbService.setItems([
                    { label: 'Cliente', routerLink: ['/clientes']},
                    { label: 'Cadastro Cliente', routerLink: ['/clientes-add']},
                ]);
  }

  ngOnInit(): void {
    this.addForm = this.formBuilder.group({
      id: [],
      nome: ['', Validators.required],
      cpf: ['', Validators.required],
      telefone: ['', Validators.required],
      endereco: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.addForm.dirty && this.addForm.valid) {
      this.clienteService.save(this.addForm.getRawValue())
        .pipe(first())
        .subscribe(data => {
            if (data != null) {
              this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Cliente cadastrado com sucesso!!!', life: 3000});
              this.router.navigate(['clientes']);
            } else {
              alert(data);
            }
          },
          error => {
            this.messageService.add({severity:'error', summary: 'Erro', detail: error.message, life: 3000});
          });
    }else {
      this.messageService.add({severity:'warning', summary: 'Informação', detail: 'Por favor, preencher os campos obrigatórios!', life: 3000});
    }
  }
}
