import {Component, OnInit} from '@angular/core';
import {Fechamento} from "../../../modules/scf/models/fechamento.model";
// import {Orgao} from "../../../modules/corporativo/models/orgao";
import {FechamentoService} from "../../../modules/scf/services/fechamento.service";
import {Router} from "@angular/router";
import { FechamentoFormFilterDto } from 'src/app/modules/scf/models/dtos/fechamento-form-filter.dto';
import { FechamentoFormFilter } from 'src/app/modules/scf/models/types/fechamento-form-filter.type';
import { PaginateType } from 'src/app/modules/scf-core/types/paginate.type';
import { DateUtil } from 'src/app/modules/scf/utils/date.util';
import { OrgaosService } from 'src/app/modules/scf/services/orgaos.service';
import { Orgao } from 'src/app/modules/scf/models/orgao.model';

@Component({
    selector: 'app-fechamentos-list',
    templateUrl: './fechamentos-list.component.html',
    styleUrls: ['./fechamentos-list.component.scss']
})
export class FechamentosListComponent implements OnInit {
    isShowFormFilter: boolean = false;
    formFilterOrgaoAutocomplete = null;
    autoCompleteOrgaos: object[]
    currentPage = 0;
    resultPaginated: PaginateType<Fechamento>;
    // orgao: Orgao;
    orgaos: Orgao[];
    totalElements: number;
    formFilter: FechamentoFormFilterDto = {
        anoMes: null,
        dataFechamento: null,
        status: null,
        orgaoId: null,
        orgaoStatus: 'A'
    };

    constructor(
        private service: FechamentoService,
        private orgaosService: OrgaosService,
        private router: Router,
        private dateUtil: DateUtil
    ) {
    }

    ngOnInit(): void {
        this.fetchSearch();
    }

    fetchOrgaos() {
        // this.orgaosService.findAll().subscribe(response => {
        //     this.orgaos = response.data;
        // });
    }

    handleShowFilter() {
        this.isShowFormFilter = !this.isShowFormFilter;
    }

    handleSearch() {
        this.fetchSearch();
    }

    handleClear(): void {
        this.autoCompleteOrgaos = [];
        this.formFilterOrgaoAutocomplete = null;
        for (const chave in this.formFilter) {
            this.formFilter[chave] = null;
        }
        this.fetchSearch();
    }

    onChangePage(event: any) {
        const page = event.first / event.rows;
        this.fetchSearch(page);
    }

    onChangeOrgaoAutocomplete(event) {
        if (event.query.length >= 3) {
            this.fetchOrgaosByTagOrName(event.query);
        }
    }

    fetchSearch(page = 0) {
        if (this.formFilterOrgaoAutocomplete) {
            this.formFilter.orgaoId = this.formFilterOrgaoAutocomplete.code;
        }
        this.service
        .findAllPaginated(this.formFilter, page)
        .subscribe((response) => {
            response.content = response.content.map(item => {
                if (item?.anoMes) {
                    item.anoMes = this.dateUtil.yearMonthToView(item.anoMes);
                }
                return item;
            });
            this.resultPaginated = response;
        })
    }

    fetchOrgaosByTagOrName(nome: string) {
        this.orgaosService.findAllByTagOrName({
            nome: nome,
            status: 'A'
        }).subscribe(response => {
            const results = response.content.map(item =>
                ({
                    name: item.organizacaoAdministrativa.nome,
                    code: item.id
                })
            )
            this.autoCompleteOrgaos = results;
        });
    }

    handleEdit(item) {
        this.service.sharedData = item;
        this.router.navigate([`/fechamento/salvar/${item.id}`]);
    }

    addFechamento(): void {
        this.service.sharedData = null;
        this.router.navigate(['/fechamento/salvar']);
    }
}
