<p-toast></p-toast>
<p-dialog [(visible)]="isShowSave" [style]="{ height: '20vw' }">
    <div class="formgrid grid">
        <div class="field col-12 div-item">
            <label><b>Órgãos:</b></label>
            <!-- <input
                [(ngModel)]="filtro.nome"
                placeholder="Digite aqui o nome do órgão"
                type="text"
                pInputText
                class="w-full"
                tagOuNome
            /> -->
            <p-autoComplete
                [(ngModel)]="formSave.text"
                [suggestions]="formSaveAutocomplete"
                (completeMethod)="findByName($event)"
                [multiple]="true"
            ></p-autoComplete>
        </div>
    </div>
</p-dialog>
<div class="card">
    <p class="title"><b>Orgãos</b></p>
    <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Adicionar"
            (click)="handlerSave()"
        ></button>
    </div>
    <div class="div-fields">
        <div class="formgrid grid">
            <div class="field col-6 div-item">
                <label><b>Órgão:</b></label>
                <input
                    [(ngModel)]="filtro.nome"
                    placeholder="Digite aqui a sigla ou o nome do órgão"
                    type="text"
                    pInputText
                    class="w-full"
                />
            </div>
            <div class="field col-6 div-item">
                <label><b>Situação</b></label>
                <p-dropdown
                    styleClass="w-full"
                    [options]="situacoes"
                    optionLabel="nome"
                    placeholder="Selecione a situacao"
                    [(ngModel)]="filtro.situacao"
                    inputId="situacao"
                >
                </p-dropdown>
            </div>
        </div>
    </div>

    <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Limpar"
            class="p-button-text"
            (click)="clear()"
        ></button>
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Consultar"
            (click)="fetchOrgaos()"
        ></button>
    </div>
    <p-divider></p-divider>

    <div class="div-fields">
        <p-table
            styleClass="p-datatable-striped p-datatable-sm"
            [value]="orgaos"
            [lazy]="true"
            [paginator]="true"
            [rowHover]="true"
            [rows]="10"
            [totalRecords]="totalElements"
            (onPage)="onPageChange($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Nome do Órgão</th>
                    <th>Data de Criação</th>
                    <th>Ações</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orgao>
                <tr>
                    <td>{{ orgao?.organizacaoAdministrativa?.nome }}</td>
                    <td>{{ orgao?.dtCriacao }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-check-circle"
                            pTooltip="Inativar"
                            tooltipPosition="top"
                            (click)="changeStatus(orgao)"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-search"
                            pTooltip="Visualizar"
                            tooltipPosition="top"
                            (click)="show(orgao)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
