import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import { Cliente } from '../cliente.model';
import { ClienteService } from '../cliente.service';

@Component({
  selector: 'app-cliente-edit',
  templateUrl: './cliente-edit.component.html',
  styleUrls: ['./cliente-edit.component.scss']
})
export class ClienteEditComponent implements OnInit {
  clienteId: any;
  cliente: Cliente;
  editForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private messageService: MessageService,
              private clienteService: ClienteService,
              private breadcrumbService: AppBreadcrumbService) {

                this.breadcrumbService.setItems([
                    { label: 'Cliente', routerLink: ['/clientes']},
                    { label: 'Cadastro Cliente', routerLink: ['/clientes-edit']},
                ]);
  }

  ngOnInit() {
    this.clienteId = window.localStorage.getItem('editClienteId');
    if (!this.clienteId) {
      this.messageService.add({severity:'warning', summary: 'Informação', detail: 'Ação inválida', life: 3000});
      this.router.navigate(['clientes']);
      return;
    }

    this.editForm = this.formBuilder.group({
      id: [],
      nome: ['', Validators.required],
      cpf: ['', Validators.required],
      telefone: ['', Validators.required],
      endereco: ['', Validators.required]
    });

    this.clienteService.findOne(+this.clienteId)
      .subscribe(data => {
        this.editForm.setValue(data);
      });
  }

  onSubmit() {
    this.clienteService.update(+this.clienteId, this.editForm.getRawValue())
      .pipe(first())
      .subscribe(
        data => {
          if (data != null) {
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Cliente atualizado com sucesso.', life: 3000});
            this.router.navigate(['clientes']);
          } else {
            alert(data);
          }
        },
        error => {
          this.messageService.add({severity:'error', summary: 'Erro', detail: error.message, life: 3000});
        });
  }
}
