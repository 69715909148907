<p-toast></p-toast>

<p-card>
    <!-- <ng-template pTemplate="header">
      <h3 class="card-title">Cadastro de Cliente</h3>
    </ng-template> -->
    <!-- <div class="card card-success">
      <div class="card-header">
        <h3 class="card-title">Dados do Cliente</h3>
      </div>
    </div> -->
    <form class="formgrid grid" [formGroup]="addForm" (ngSubmit)="onSubmit()">
      <div class="field col-12">
        <label for="nome">Nome:</label>
        <input pInputText formControlName="nome" placeholder="Nome Completo" name="nome" class="w-full" id="nome">
      </div>
      <div class="field col-12">
        <label for="cpf">CPF:</label>
        <p-inputMask styleClass="w-full" formControlName="cpf" [unmask]="true" mask="999.999.999-99" placeholder="CPF" name="cpf" id="cpf"></p-inputMask>
      </div>

      <div class="field col-12">
        <label for="telefone">Telefone:</label>
        <input pInputText formControlName="telefone" placeholder="Telefone" name="telefone" class="w-full" id="telefone">
      </div>

      <div class="field col-12">
        <label for="endereco">Endereço:</label>
        <input pInputText formControlName="endereco" placeholder="Endereço" name="endereco" class="w-full" id="endereco">
      </div>
      <p-toolbar class="field col">
        <ng-template pTemplate="right">
          <button type="submit" pButton pRipple class="p-button-success">Salvar</button>
        </ng-template>
      </p-toolbar>
    </form>
</p-card>
