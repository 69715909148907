import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {RegimeJuridico} from "src/app/shared/models/servidor/regime.juridico";
import {Servidor} from "src/app/shared/models/servidor/servidor.model";
import {PageableServidor} from "src/app/shared/models/servidor/servidor.page.model";
import {SituacaoFuncional} from "src/app/shared/models/servidor/situacao.funcional";
import {RegimeJuridicoService} from "src/app/shared/services/servidor/regime-juridico.service";
import {ServidorService} from "src/app/shared/services/servidor/servidor.service";
import {SituacaoFuncionalService} from "src/app/shared/services/servidor/situacao-funcional.service";
import {AppBreadcrumbService} from "../../../modules/main/breadcrumb/app.breadcrumb.service";
import {Orgao} from "../../../modules/scf/models/orgao.model";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import {CPFUtils} from "../../../utilities/cpf-utils";
import { Situacao } from "../../escala/interfaces/situacao";
import { SituacaoService } from "../../escala/services/situacao.service";
import { SituacoesService } from "../../escala/services/situacoes.service";
import { PageableOrgao } from "src/app/modules/scf/models/orgao.page.model";
import { OrgaosService } from "src/app/modules/scf/services/orgaos.service";
import { OrganizacoesAdministrativasService } from "src/app/modules/corporativo/services/organizacoes-administrativas.service";
import { formatDate } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { Messages } from "src/app/modules/scf-core/constants/messages";

@Component({
    selector: "app-ponto-save",
    templateUrl: "./ponto-save.component.html",
    styleUrls: ["./ponto-save.component.scss"],
})
export class PontoSaveComponent implements OnInit {
    addForm: FormGroup;
    isShowModal: boolean = false;
    dateNow: String;
    timeNow: String;
    dateWeak: String;
    orgaos: Orgao[];
    situacoes: Situacao[];
    regimes: RegimeJuridico[];
    lotacoes: Orgao[];
    orgaosOrigem: Orgao[];
    totalElements: number;
    filtro = {
        nome: "",
        situacao: "",
        orgaoOrigem: null,
        cpf: "",
        regimeJuridico: null,
        situacaoFuncional: null,
        codigoVinculo: null,
        orgaoId: null,
    };
    formSave: FormGroup;
    formSaveAutocomplete: string[];
    queryParam: string;

    constructor(
        private situacaoService: SituacoesService,
        private orgaosService: OrgaosService,
        private organizacoesAdministrativasService: OrganizacoesAdministrativasService,
        private router: Router,
        private breadcrumbService: AppBreadcrumbService,
        private formBuilder: FormBuilder,
        private messageService: MessageService,
    ) {
        this.breadcrumbService.setItems([
            {label: "Orgãos", routerLink: ["/orgaos"]},
        ]);
    }

    ngOnInit(): void {
        this.formSave = this.formBuilder.group({
            cpf: ['', Validators.required],
            senha: ['', Validators.required],
        });
        this.timeNow = formatDate(new Date(), 'HH:mm:ss', 'pt-BR');
        this.dateNow = formatDate(new Date(), 'longDate', 'pt-BR');
        this.dateWeak = formatDate(new Date(), 'EEEE', 'pt-BR');
        this.getSituacoes();
        this.clear();
        this.formSaveAutocomplete = ["todos"];
        // this.initiateDataLoading(
        //     this.orgaoOrigemService,
        //     (response) => (this.orgaosOrigem = response)
        // );


        setInterval(() => {
            this.timeNow = formatDate(new Date(), 'HH:mm:ss', 'pt-BR');
          }, 1);
    }

    findByName(event) {
        this.formSaveAutocomplete = ["todos"];
        this.organizacoesAdministrativasService.listAll(event.query).subscribe(data => {
            console.log(data)
            // this.results = data;
        });

        //tagOuNome
        // this.mylookupservice.getResults(event.query).then(data => {
        //     this.results = data;
        // });
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    handlerShowList() {
        this.isShowModal = !this.isShowModal;
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    getSituacoes() {
        this.situacaoService.listar().subscribe((situacoes) => {
        // situacoes.unshift({id: "", nome: "Todos"})
        this.situacoes = situacoes
        });
    }

    // initiateDataLoading(service: any, callback: (response: any[]) => void) {
    //     service.listAll().subscribe((response: any[]) => {
    //         callback(response);
    //     });
    // }

    clear() {
        for (const chave in this.filtro) {
            delete this.filtro[chave];
        }
        this.queryParam = "";
        this.fetchOrgaos();
    }

    fetchOrgaos() {
        this.orgaosService
            .listByFilter(0, this.createQueryString(this.filtro))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            })
    }

    onSubmit() {

        if (this.formSave.dirty && this.formSave.valid) {
            // this.clienteService.save(this.addForm.getRawValue())
            //   .pipe(first())
            //   .subscribe(data => {
            //       if (data != null) {
            //         this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Cliente cadastrado com sucesso!!!', life: 3000});
            //         this.router.navigate(['clientes']);
            //       } else {
            //         alert(data);
            //       }
            //     },
            //     error => {
            //       this.messageService.add({severity:'error', summary: 'Erro', detail: error.message, life: 3000});
            //     });
            this.messageService.add({severity:'error', summary: 'Ops!', detail: 'CPF ou Senha inválida!', life: 3000});

          }else {
            this.messageService.add({severity:'warn', summary: 'Ops!', detail: Messages.error.service, life: 3000});
          }
    }

    onPageChange(event: any) {
        const page = event.first / event.rows;
        // const page = event.page;
        this.createQueryString(this.filtro)
        this.orgaosService
            .listByFilter(page, this.createQueryString(this.filtro))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            });
    }

    changeStatus(orgao: Orgao) {
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    show(orgao: Orgao) {
        // this.servidorService.sharedData = servidor;
        this.router.navigate(["/servidor"]);
    }

    private createQueryString(filtro: { [key: string]: string }): string {
        const partesQueryParam = [];
        for (const chave in filtro) {
            if (filtro[chave]) {
                partesQueryParam.push(
                    `${chave}=${encodeURIComponent(filtro[chave])}`
                );
            }
        }
        return partesQueryParam.join("&");
    }
}
