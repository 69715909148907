<div class="layout-footer flex align-items-center p-1 shadow-1">
    <div
        style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        "
    >
        <p>
            Copyright © {{ currentYear }} -
            <strong
                ><a
                    style="text-decoration: none"
                    target="_blank"
                    href="https://goias.gov.br/administracao/"
                    >SEAD - Secretaria de Estado de Administração</a
                ></strong
            >
            - Todos os direitos reservados.
        </p>
        <p><strong>Versão</strong> {{ version }}</p>
    </div>
</div>
