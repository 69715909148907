<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/">
            <img id="app-logo" src="assets/layout/images/logo-governo-goias.png" alt="goias-logo" style="height: 3.25rem">
        </a>

        <a class="layout-menu-button shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>
    </div>

    <div style="display: flex; justify-content: flex-end;" class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <!-- li class="layout-topbar-item notifications">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'notifications')" pRipple>
                        <span class="p-overlay-badge">
                            <i class="pi pi-bell fs-large"></i>
                            <span class="p-badge p-badge-warning p-badge-dot"></span>
                        </span>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'notifications'">
                        <li class="mb-3">
                            <span class="px-3 fs-small">Você tem <b>4</b> novas notificações</span>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-1.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Jeremias Beltrão</span>
                                        <small>42 minutos atrás</small>
                                    </div>
                                    <span class="fs-small">Como estão as fotografias para esta semana?</span>
                                </div>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-2.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Carlos Willian</span>
                                        <small>48 mins atrás</small>
                                    </div>
                                    <span class="fs-small">Começou a escrever exelentes artigos no blog.</span>
                                </div>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-3.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Anna Milena</span>
                                        <small>1 dia atrás</small>
                                    </div>
                                    <span class="fs-small">Acaba de se tornar um novo assinante.</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li -->
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle" (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <p-avatar icon="pi pi-user" alt="avatar" shape="circle"></p-avatar>
                        <!-- <img src="assets/demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;"> -->
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'profile'">
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple (click)="goToProfile()">
                                <i class="pi pi-cog" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Perfil</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-compass" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Suporte</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" (click)="onSubmit($event)" pRipple>
                                <i class="pi pi-power-off" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Sair</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
