import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {Component, OnInit} from '@angular/core';
import {EscalaService} from '../../../modules/scf/services/escala.service';
import {SituacaoService} from './../services/situacao.service';
import {TipoService} from './../services/tipo.service';
import {UnidadeService} from '../services/unidade.service';
import {OrgaoService} from '../services/orgao.service';
import {Unidade} from 'src/app/pages/escala/interfaces/unidade';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import {Escala} from '../interfaces/escala';
import {TipoEscala} from '../interfaces/tipoEscala';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {IntervaloJornada} from '../interfaces/intervaloJornada';
import {Jornada} from 'src/app/pages/escala/interfaces/jornada';
import {JornadaTrabalhoService} from './../services/jornada-trabalho.service';
import {IntervaloJornadaService} from '../services/intervalo-jornada.service';

@Component({
    selector: 'app-escala-editar',
    templateUrl: './escala-editar.component.html',
    styleUrls: ['./escala-editar.component.scss']
})
export class EscalaEditarComponent implements OnInit {

    listaOrgaos: Orgao[];
    listaUnidades: Unidade[];
    unidade: Unidade;
    TipoAux: TipoEscala;
    tiposEscala: TipoEscala[];
    idOrgao: '';
    escala: Escala;
    listaJornadas: Jornada[];
    //jornada: Jornada;
    listaIntervalosJornada: IntervaloJornada[];
    intervaloJornada: IntervaloJornada;
    /*  escala : Escala ={
      id:null,
      descEscalaTrabalho:'',
      dataInicioVigencia:new Date(10/10/2023),
      indiHorarioFlexivel:'',
      indiBancoDeHoras:'',
      indiEscalaContinua:'',
      qtdeHorajornadaDiaria:0,
      qtdeHorajornadaSemanal:0,
      qtdeHorajornadaMensal:0,
      statAtivo:'',
      descStatJustificativa:'',
      tipoEscala:null,
      idRhJornadaTRabalhoMes:0,
      idRhJornadaTRabalhoSemana:0,
      dataCriacao:new Date(10/10/2023),
      dataAtualizacao:new Date(10/10/2023),
      unidade:null
      }*/

    jornada: Jornada = {
        id: null,
        horaEntrada: null,
        horaSaida: null,
        horaIntervaloEntrada1: null,
        horaIntervaloSaida1: null,
        horaIntervaloEntrada2: null,
        horaIntervaloSaida2: null,
        horaIntervaloEntrada3: null,
        horaIntervaloSaida3: null,
        numeroDiaSemana: 0,
        quantidadeHoras: 0,
        quantidadeJornada: 0,
        descricaoDiaSemana: '',
        quantidadeIntervalos: 0,
        status: '',
        descricaoJustificativa: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null
    };

    listaMinutos: any[] = [];
    listaPrimeirosIntervalos: any[] = [];
    listaSegundosIntervalos: any[] = [];


    intervalo: IntervaloJornada = {
        id: null,
        numeroMinutosIntervalo: '',
        numeroInicioIntervalo: '',
        numeroFimIntervalo: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null

    };

    dadosEscalaDiaria: boolean = false;

    diasSelecionadosJornada: number[] = [];
    mostraBotoesDeAdicaoDeJornada: boolean = false;
    mostraBotoesDeIntervalos: boolean = false;

    adicionaJornada: boolean = false;
    adicionaJornadaRevezamento: boolean = false;
    adicionaJornadaTeleatendimento: boolean = false;
    adicionaIntervalo: boolean = false;


    mostraCamposDiaria: boolean = false;
    mostraCamposReveza: boolean = false;

    flexibilidades: any[] = [];

    constructor(private serviceOrgao: OrgaoService, private serviceUnidade: UnidadeService,
                private serviceSituacao: SituacaoService, private serviceTipo: TipoService,
                private serviceJornadaTrabalho: JornadaTrabalhoService,
                private serviceIntervaloJornada: IntervaloJornadaService,
                private serviceEscala: EscalaService, private breadcrumbService: AppBreadcrumbService,
                private messageService: MessageService,
                private router: Router,) {
        this.escala = this.serviceEscala.sharedData;
        this.breadcrumbService.setItems([
            {label: 'Listar Escalas', routerLink: ['/escalas']},
            {label: 'Editar Escalas'},

        ]);
    }

    ngOnInit(): void {
        this.getFlexibilidades();
        this.getOrgaosAll();
        this.getiposEscala();
        this.getJornadasPorEscala();
        this.getIntervalosPorEscala();
        console.log(this.listaOrgaos);
        console.log(this.escala);
    }

    criarEscala() {
        // this.serviceEscala.save(this.escala).subscribe();
        // this.router.navigate([`/escalas`]);
    }

    getFlexibilidades() {
        return this.flexibilidades = ['Sim', 'Não'];
    }

    getOrgaosAll() {
        this.serviceOrgao.listar().subscribe((listaOrgaos) => {
            this.listaOrgaos = listaOrgaos.data;
        });
    }

    getiposEscala() {
        this.serviceTipo.listar().subscribe((tiposEscala) => {
            this.tiposEscala = tiposEscala;
        });
    }


    getUnidadesPorOrgao(id: any) {
        this.serviceUnidade.listar().subscribe((listaUnidades) => {
            this.listaUnidades = listaUnidades.filter(unidade => unidade.orgao.id === id);
        });
    }


    editarEscala(escala: Escala) {
        this.escala = escala;
        this.router.navigate([`/escalas`]);
    }


    abrirDialogJornada() {


        if (this.TipoAux.id === 1) {
            this.adicionaJornada = true;
        } else if (this.TipoAux.id === 2) {
            this.adicionaJornadaRevezamento = true;

        } else {
            this.adicionaJornadaTeleatendimento = true;
        }

    }

    abrirDialogIntervalos() {
        this.listaMinutos = [
            '10 minutos',
            '20 minutos'
        ];

        this.listaPrimeirosIntervalos = [
            '1ª Hora',
            '2ª Hora',
            '3ª Hora',
            '4ª Hora'
        ];

        this.listaSegundosIntervalos = [
            '1ª Hora',
            '2ª Hora',
            '3ª Hora',
            '4ª Hora'
        ];
        this.adicionaIntervalo = true;
    }


    getExibeBotoesInserirJornada(idTipo: TipoEscala) {

        this.TipoAux = idTipo;
        if ((idTipo.id === 1) || (idTipo.id === 3)) {
            this.mostraBotoesDeAdicaoDeJornada = true;
            this.mostraBotoesDeIntervalos = true;

        } else {
            this.mostraBotoesDeAdicaoDeJornada = true;
            this.mostraBotoesDeIntervalos = false;
        }
    }


    getJornadasPorEscala() {
        this.serviceJornadaTrabalho.listarJornadasPorEscala().subscribe((listaJornadas) => {
            this.listaJornadas = listaJornadas.filter(jornada => jornada.escala.id === this.escala.id);
        });
    }

    getIntervalosPorEscala() {
        this.serviceIntervaloJornada.listarIntervalosPorEscala().subscribe((listaIntervalosJornada) => {
            this.listaIntervalosJornada = listaIntervalosJornada.filter(jornada => jornada.escala.id === this.escala.id);
        });
    }


}
