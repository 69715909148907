import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {PageableServidor} from '../../models/servidor/servidor.page.model';

@Injectable({
    providedIn: 'root'
})
export class ServidorService {
    sharedData: any;

    constructor(private http: HttpClient) {
    }

    listAllInCorporative(page: number, params: string): Observable<PageableServidor> {
        const apiUrl = `${environment.apiUrl}/wso2/rhvinculos/servidores?page=${page}${params ? `&${params}` : ''}`;
        return this.http.get<PageableServidor>(apiUrl).pipe(
            catchError(this.handleError)
        );
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }
}
