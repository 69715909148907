<p-toast></p-toast>
<div>
    <div>
        <p class="title"><b>Estagiário</b></p>
    </div>

    <div>
        <div class="div-container div-container-header">
            <p>Unidade Administrativa:</p>
        </div>
        <div class="div-container">
            <p>{{estagiario.unidadeAdministrativa}}</p>
        </div>

        <div class="div-container div-container-header">
            <p>Nome:</p>
            <p>CPF:</p>
            <p>Jornada de Trabalho:</p>
        </div>
        <div class="div-container">
            <p>{{estagiario.nome}}</p>
            <p>{{estagiario.cpf}}</p>
            <p>{{estagiario.jornadaTrabalhoServidor}}</p>
        </div>

        <div class="div-container div-container-header">
            <p>Contrato de Prestador de Serviços:</p>
            <p>Data de Início de Contrato:</p>
            <p>Data de Término de Contrato:</p>
        </div>
        <div class="div-container">
            <p>{{estagiario.contratoPrestadorServico}}</p>
            <p>{{estagiario.dataInicioContrato}}</p>
            <p>{{estagiario.dataTerminoContrato}}</p>
        </div>
    </div>
</div>