import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrudUsuariosService {
    data: any[] = [];
    constructor(protected http: HttpClient) { }

    getUsers(): Promise<any> {
      return new Promise( (resolve, reject) => {
          resolve(this.data);
      })
    }

    getUserById(id: string): Promise<any> {
      return new Promise( (resolve, reject) => {
          resolve(this.data.find((res: any) => res.id === id));
      })
    }

    saveUser(request: any): Promise<any> {
      return new Promise( (resolve, reject) => {
        if(request){
          this.data.push(request);
          resolve(this.data);
        }else{
          reject('ERRO');
        }
      })
    }

    editUser(request: any): Promise<any> {
      return new Promise( (resolve, reject) => {
        if(request){
          this.data[this.data.findIndex((res: any) => res.id == request.id)] = request;
          resolve(this.data);
        }else{
          reject('ERRO');
        }
      })
    }

    deleteUserById(id: string): Promise<any> {
      return new Promise( (resolve, reject) => {
        this.data.splice(this.data.findIndex((res: any) => res.id == id), 1)
        resolve(this.data);
      })
    }
}