<p-toast></p-toast>

<p-card>
    <ng-template pTemplate="header">
        <div class="grid p-2">
            <div class="col-10">
                <h3>Manter servidores</h3>
            </div>
            <div class="col-2 flex justify-content-end gap-1">
                <!-- <button
                    pButton
                    type="button"
                    class="p-button-raised p-button-rounded"
                    pTooltip="Incluir servidor"
                    icon="pi pi-plus"
                    tooltipPosition="top"
                    (click)="handlerSave()"
                ></button> -->
                <button
                    pButton
                    type="button"
                    class="p-element p-ripple p-button-rounded p-button-text p-button p-component p-button-icon-only"
                    pTooltip="Fechar filtro"
                    [icon]="isShowFormFilter ? 'pi pi-times' : 'pi pi-filter'"
                    tooltipPosition="top"
                    (click)="handlerShowFilter()"
                ></button>
            </div>
        </div>
    </ng-template>
    <div [hidden]="!isShowFormFilter">
        <div class="div-fields">
            <div class="formgrid grid">
                <div class="field col div-item">
                    <label><b>Orgão de lotação:</b></label>
                    <select
                        [(ngModel)]="parametros.orgaoId"
                        pInputText
                        class="w-full"
                    >
                        <option
                            *ngFor="let lotacao of lotacoes"
                            [value]="lotacao.id"
                            [selected]="lotacao.id == parametros.orgaoId"
                        >
                            {{ lotacao.nome }}
                        </option>
                    </select>
                </div>
                <div class="field col div-item">
                    <label><b>Orgão de origem:</b></label>
                    <select
                        [(ngModel)]="parametros.orgaoOrigem"
                        pInputText
                        class="w-full"
                    >
                        <option
                            *ngFor="let orgao of orgaosOrigem"
                            [value]="orgao.id"
                        >
                            {{ orgao.nome }}
                        </option>
                    </select>
                </div>
                <div class="field col div-item">
                    <label><b>Regime jurídico:</b></label>
                    <select
                        [(ngModel)]="parametros.regimeJuridico"
                        pInputText
                        class="w-full"
                    >
                        <option
                            *ngFor="let regimeJuridico of regimes"
                            [value]="regimeJuridico.codigo"
                        >
                            {{ regimeJuridico.descricao }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-2 div-item">
                    <label><b>CPF:</b></label>
                    <input
                        [(ngModel)]="parametros.cpf"
                        type="text"
                        pInputText
                        class="w-full"
                        mask="000.000.000-00"
                    />
                </div>
                <div class="field col-2 div-item">
                    <label><b>Código do vínculo:</b></label>
                    <input
                        [(ngModel)]="parametros.codigoVinculo"
                        type="text"
                        pInputText
                        class="w-full"
                    />
                </div>
                <div class="field col-4 div-item">
                    <label><b>Situação Funcional:</b></label>
                    <select
                        [(ngModel)]="parametros.situacaoFuncional"
                        pInputText
                        class="w-full"
                    >
                        <option
                            *ngFor="let situacao of situacoes"
                            [value]="situacao.codigo"
                        >
                            {{ situacao.descricao }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="flex justify-content-end gap-2">
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Limpar"
                class="p-button-text"
                (click)="clear()"
            ></button>
            <button
                iconPos="right"
                pButton
                pRipple
                type="button"
                label="Consultar"
                (click)="fetchServidores()"
            ></button>
        </div>
    </div>
    <p-divider></p-divider>

    <div class="div-fields">
        <p-table
            styleClass="p-datatable-striped p-datatable-sm"
            [value]="servidores"
            [lazy]="true"
            [paginator]="true"
            [rowHover]="true"
            [rows]="10"
            [totalRecords]="totalElements"
            (onPage)="onPageChange($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Nome</th>
                    <th>CPF</th>
                    <th>Matrícula</th>
                    <th>Regime Jurídico</th>
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-servidor>
                <tr>
                    <td>{{ servidor.nome }}</td>
                    <td>{{ formatCPFNumber(servidor.cpf) }}</td>
                    <td>{{ servidor.matricula }}</td>
                    <td>{{ servidor.regimeJuridico }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-search"
                            pTooltip="Visualizar"
                            tooltipPosition="top"
                            (click)="showServidor(servidor)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
