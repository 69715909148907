import {Component} from '@angular/core';
import {AppComponent} from '../../../app.component';
import environment from 'package.json';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    version = environment.version
    currentYear: number = new Date().getFullYear();
    constructor(public app: AppComponent) {}
}
