import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { ResponseRequest } from '../../core/models/ResponseRequest';
import { RHVinculosServidor } from '../models/types/RHVinculosServidor';
import { RHVinculoServidorFormFilter } from '../models/types/rhvinculos-servidor-form-filter.type';
import { PaginateType } from '../../scf-core/types/paginate.type';

@Injectable({
    providedIn: 'root'
})
export class RHVinculosServidoresService {
    public apiUrl = `${environment.apiUrl}/wso2/rhvinculos/servidores`;

    constructor(
        private http: HttpClient) {
    }

    findAll() {
        return this.http.get<ResponseRequest<RHVinculosServidor[]>>(this.apiUrl);
    }

    findAllPaginated(filter: RHVinculoServidorFormFilter, page: number) {
        const url = `${this.apiUrl}`;
        let filterParam = {
            page: page,
            orgaoId: filter.orgaoId,
            cpf: filter.cpf,
            vincCodg: filter.vincCodg,
        }
        console.log(filterParam, filter);
        const options = {params: this.addFilterToParams(filterParam)}
        return this.http.get<PaginateType<RHVinculosServidor>>(url, options);
    }

    private addFilterToParams(filter: object) {
        let params = new HttpParams();
        for (const key in filter) {
            if (filter[key] !== null && typeof filter[key] === 'object') {
                params = params.set(key, JSON.stringify(filter[key]));
                continue;
            }
            if (filter[key]) params = params.set(key, filter[key]);
        }
        return params;
    }
}
