import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import { MessageService } from 'primeng/api';
import {forkJoin, Observable} from 'rxjs';
import {AuthService} from "./auth.service";
import {AcessosUsuarioService} from "../services/acessos-usuario/acessos-usuario.service";
import {AppService} from "../services/app.service";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private oauthService: OAuthService,
    private acessosUsuarioService: AcessosUsuarioService,
    private messageService: MessageService,
    private appService: AppService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    console.log(environment.isToUseAuthentication, ' ENV <<<<<----')
    if (!environment.production && !environment?.isToUseAuthentication) {
        return true;
    }

    if (this.authService.isValidToken()) {
      if (this.acessosUsuarioService.isNotEmptyRoles()) {
        if (this.appService.isAppDataOk()) {
          if (route.data.roles && !this.acessosUsuarioService.hasRole(route.data.roles)) {
            this.router.navigate(['/403']);
            return false;
          }

          return true;
        } else {
          this.router.navigate(['/500']);
          return false;
        }
      } else {
        this.messageService.add({severity:'error', summary: 'Erro', detail: 'Sem permissão para o acesso', life: 3000});
        this.router.navigate(['/403']);
        return false;
      }
    }
    this.oauthService.initImplicitFlow();
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
}
