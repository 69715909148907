import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrganizacaoAdministrativa } from '../models/OrganizacaoAdministrativa';

@Injectable({
  providedIn: 'root'
})
export class OrganizacoesAdministrativasService {

  constructor(private http: HttpClient) { }

  listAll(filter): Observable<OrganizacaoAdministrativa[]> {
    const apiUrl = `${environment.apiUrl}/corporativo/organizacoes-administrativas`;
    return this.http.get<OrganizacaoAdministrativa[]>(apiUrl, this.addFilterToParams(filter)).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error('Erro na requisição:', error);
    return throwError(error);
  }

  private addFilterToParams(filter: object) {
    let params = new HttpParams();
    for (const key in filter) {
        if (filter[key]) params = params.set(key, filter[key])
    }
    return {params};
}
}
