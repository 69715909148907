import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {environment} from '../../../../environments/environment';
import { ResponseRequest } from 'src/app/modules/core/models/ResponseRequest';

@Injectable({
    providedIn: 'root'
})

export class OrgaoService {

    sharedData: any;
    apiUrl = `${environment.apiUrl}/admin/orgaos`;

    constructor(private http: HttpClient) {
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }


    listar(): Observable<ResponseRequest<Orgao[]>> {
        console.log(`${this.apiUrl}`);
        return this.http.get<ResponseRequest<Orgao[]>>(`${this.apiUrl}`).pipe(
            catchError(this.handleError));
    }
}
