import { Component, OnInit } from '@angular/core';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AuthService } from './modules/core/auth/auth.service';
import { authConfig } from './modules/core/oauth2.config';
import { AppService } from './modules/core/services/app.service';
import { SessionStorageService } from './shared/services/session-storage/session-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    topbarTheme = 'blue';

    menuTheme = 'light';

    layoutMode = 'light';

    menuMode = 'static';

    inlineMenuPosition = 'bottom';

    inputStyle = 'filled';

    ripple = true;

    isRTL = false;

    constructor(private oauthService: OAuthService,
        private authService: AuthService,
        private sessionStorageService: SessionStorageService,
        private appService: AppService,
        private primengConfig: PrimeNGConfig) {
        this.oauthService.configure(authConfig);

        /** enable below validation only if jwks object is defined as part of oauthconfig obj */
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();
        this.oauthService.setStorage(sessionStorageService.getSessionStorage());

        this.oauthService.loadDiscoveryDocument()
            .then(res => {
                this.oauthService.sessionCheckIFrameUrl += `?client_id=${environment.sso.clientId}&redirect_uri=${window.location.origin}`;
                // @ts-ignore
                this.oauthService.config.sessionCheckIFrameUrl = this.oauthService.sessionCheckIFrameUrl;
                return;
            });

        /** commented below because below resource is protected by some identity server ex: wso2 */
        this.oauthService.loadDiscoveryDocumentAndTryLogin({})
            .then(
                () => this.appService.loadAccessData()
            );

        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                this.oauthService.logOut();
            }
        });
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
