import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Unidade} from 'src/app/pages/escala/interfaces/unidade';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UnidadeService {

    sharedData: any;
    apiUrl = `${environment.apiUrl}/corporativo/organizacoes-administrativas`;

    constructor(private http: HttpClient) {
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }

    listar(): Observable<Unidade[]> {
        return this.http.get<Unidade[]>(this.apiUrl).pipe(
            catchError(this.handleError));
    }

}
