<p-toast></p-toast>

<div class="card">
    <form [formGroup]="userForm" class="formgrid grid">
        <div class="field col-6">
            <label>Usuario</label>
            <input type="text" pInputText formControlName="usuario" class="w-full" [ngClass]="{ 'ng-invalid': userForm.get('usuario')?.errors?.['required'] && userForm.get('usuario')?.touched, 'ng-dirty': userForm.get('usuario')?.errors?.['required'] && userForm.get('usuario')?.touched }">
        </div>
        <div class="field col-6 align-self-end">
            <span class="p-float-label">
                <input id="float-input" type="text" pInputText class="w-full" formControlName="email" [ngClass]="{ 'ng-invalid': userForm.get('email')?.errors?.['required'] && userForm.get('email')?.touched, 'ng-dirty': userForm.get('email')?.errors?.['required'] && userForm.get('email')?.touched }"> 
                <label for="float-input">Email</label>
            </span>
        </div>
        <div class="field col-6">
            <label>Senha</label>
            <p-password styleClass="w-full" inputStyleClass="w-full" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte" formControlName="senha" [ngClass]="{ 'ng-invalid': userForm.get('senha')?.errors?.['required'] && userForm.get('senha')?.touched, 'ng-dirty': userForm.get('senha')?.errors?.['required'] && userForm.get('senha')?.touched }">
                <ng-template pTemplate="header">
                    <h6>Escolha uma senha</h6>
                </ng-template>
                <ng-template pTemplate="footer">
                    <p-divider></p-divider>
                    <p class="mt-2">Sugestões</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>Conter ao menos uma letra minuscula</li>
                        <li>Conter ao menos uma letra maiuscula</li>
                        <li>Conter ao menos um numero</li>
                        <li>Minimo 8 caracteres</li>
                    </ul>
                </ng-template>
            </p-password>
        </div>
        <div class="field col-6">
            <label>CPF</label>
            <p-inputMask styleClass="w-full" [unmask]="true" mask="999.999.999-99" placeholder="000.000.000-00" formControlName="cpf" [ngClass]="{ 'ng-invalid': userForm.get('cpf')?.errors?.['required'] && userForm.get('cpf')?.touched, 'ng-dirty': userForm.get('cpf')?.errors?.['required'] && userForm.get('cpf')?.touched }"></p-inputMask>
        </div>
        <div class="field col-6">
            <label>Telefone</label>
            <p-inputMask styleClass="w-full" [unmask]="true" mask="(99) 99999-9999" placeholder="(00) 0000-0000" formControlName="tel" [ngClass]="{ 'ng-invalid': userForm.get('tel')?.errors?.['required'] && userForm.get('tel')?.touched, 'ng-dirty': userForm.get('tel')?.errors?.['required'] && userForm.get('tel')?.touched }"></p-inputMask>
        </div>
        <div class="field col-6">
            <label>Data de nascimento</label>
            <p-calendar dateFormat="dd/mm/yy" dataType="string" styleClass="w-full" formControlName="data" [ngClass]="{ 'ng-invalid': userForm.get('data')?.errors?.['required'] && userForm.get('data')?.touched, 'ng-dirty': userForm.get('data')?.errors?.['required'] && userForm.get('data')?.touched }"></p-calendar>
        </div>
        <div class="field col-6">
            <label>Gênero</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col">
                    <p-radioButton name="genero" value="1" inputId="genero1" formControlName="genero" [ngClass]="{ 'ng-invalid': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched, 'ng-dirty': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched }"></p-radioButton>
                    <label for="genero1">Masculino</label>
                </div>
                <div class="field-radiobutton col">
                    <p-radioButton name="genero" value="2" inputId="genero2" formControlName="genero" [ngClass]="{ 'ng-invalid': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched, 'ng-dirty': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched }"></p-radioButton>
                    <label for="genero2">Feminino</label>
                </div>
                <div class="field-radiobutton col">
                    <p-radioButton name="genero" value="0" inputId="genero3" formControlName="genero" [ngClass]="{ 'ng-invalid': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched, 'ng-dirty': userForm.get('genero')?.errors?.['required'] && userForm.get('genero')?.touched }"></p-radioButton>
                    <label for="genero3">Não declarar</label>
                </div>
            </div>
        </div>
        <div class="field col-6">
            <label>Habilidades</label>
            <p-multiSelect styleClass="w-full" [options]="skills" formControlName="habilidades" [ngClass]="{ 'ng-invalid': userForm.get('habilidades')?.errors?.['required'] && userForm.get('habilidades')?.touched, 'ng-dirty': userForm.get('habilidades')?.errors?.['required'] && userForm.get('habilidades')?.touched }"></p-multiSelect>
        </div>
        <div class="field col-6">
            <label>Curriculum</label>
            <p-fileUpload #fileUpload mode="advanced" styleClass="w-full" name="demo[]" accept=".pdf" chooseLabel="Escolha" [showUploadButton]="false" [showCancelButton]="false" (onSelect)="onUpload($event)"></p-fileUpload>
        </div>
    </form>
    <p-toolbar>
        <ng-template pTemplate="left">
            <button pButton pRipple label="Voltar" icon="pi pi-back" class="p-button-info mr-2" (click)="voltar()"></button>
        </ng-template>
        <ng-template pTemplate="right">
            <button pButton pRipple label="Salvar" icon="pi pi-plus" class="p-button-success mr-2" (click)="salvar()"></button>
        </ng-template>
    </p-toolbar>
</div>