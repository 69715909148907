import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {RegimeJuridico} from "src/app/shared/models/servidor/regime.juridico";
import {Servidor} from "src/app/shared/models/servidor/servidor.model";
import {PageableServidor} from "src/app/shared/models/servidor/servidor.page.model";
import {SituacaoFuncional} from "src/app/shared/models/servidor/situacao.funcional";
import {RegimeJuridicoService} from "src/app/shared/services/servidor/regime-juridico.service";
import {ServidorService} from "src/app/shared/services/servidor/servidor.service";
import {SituacaoFuncionalService} from "src/app/shared/services/servidor/situacao-funcional.service";
import {AppBreadcrumbService} from "../../../modules/main/breadcrumb/app.breadcrumb.service";
import {Orgao} from "../../../modules/scf/models/orgao.model";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import {CPFUtils} from "../../../utilities/cpf-utils";
import { Situacao } from "../../escala/interfaces/situacao";
import { SituacaoService } from "../../escala/services/situacao.service";
import { SituacoesService } from "../../escala/services/situacoes.service";
import { PageableOrgao } from "src/app/modules/scf/models/orgao.page.model";
import { OrgaosService } from "src/app/modules/scf/services/orgaos.service";
import { OrganizacoesAdministrativasService } from "src/app/modules/corporativo/services/organizacoes-administrativas.service";
import { isObject } from "underscore";

@Component({
    selector: "app-orgaos-list",
    templateUrl: "./orgaos-list.component.html",
    styleUrls: ["./orgaos-list.component.scss"],
})
export class OrgaosListComponent implements OnInit {
    isShowFormFilter: boolean = false;
    orgaos: Orgao[];
    situacoes: Situacao[];
    regimes: RegimeJuridico[];
    lotacoes: Orgao[];
    orgaosOrigem: Orgao[];
    totalElements: number;
    isShowSave: boolean = false;
    formFilter = {
        nome: "",
        status: "",
    };
    formSave = {
        list: [],
    };
    formSaveAutocomplete: Object[];
    queryParam: string;

    constructor(
        private situacaoService: SituacoesService,
        private orgaosService: OrgaosService,
        private organizacoesAdministrativasService: OrganizacoesAdministrativasService,
        private router: Router,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            {label: "Orgãos", routerLink: ["/orgaos"]},
        ]);
    }

    ngOnInit(): void {
        this.getSituacoes();
        this.clear();
        this.formSaveAutocomplete = [];
        // this.initiateDataLoading(
        //     this.orgaoOrigemService,
        //     (response) => (this.orgaosOrigem = response)
        // );
    }

    handleFormSaveDialog() {
        this.isShowSave = !this.isShowSave;
    }

    onChangeOrgaoAutocomplete(event) {
        // this.formSaveAutocomplete = [{
        //     name: "todos",
        //     code: ""
        // }];
        if (event.query.length >= 3) {
            this.organizacoesAdministrativasService.listAll({
                nome: event.query,
                status: 'A'
            }).subscribe(data => {
                const results = data.map(item =>
                    ({
                        name: item.nome,
                        code: item.id
                    })
                )
                this.formSaveAutocomplete = results;
            });
        }

        //tagOuNome
        // this.mylookupservice.getResults(event.query).then(data => {
        //     this.results = data;
        // });
        // this.servidorService.sharedData = servidor;
        // this.router.navigate(["/servidor"]);
    }

    clearInput() {
        this.formSaveAutocomplete = null;
        this.formSave.list = [];
    }

    handleSubmit() {
        const list = this.formSave.list.map(
            item => ({
                organizacaoAdministrativa: {
                    id: item.code
                },
                status: "A"
            })
        )
        this.orgaosService.saveAll(list).subscribe((result) => {
            this.handleFormSaveDialog();
            this.fetchAll();
            this.clearInput();
        });
    }


    handleShowFilter() {
        this.isShowFormFilter = !this.isShowFormFilter;
    }

    getSituacoes() {
        this.situacaoService.listar().subscribe((situacoes) => {
        // situacoes.unshift({id: "", nome: "Todos"})
        this.situacoes = situacoes
        });
    }

    // initiateDataLoading(service: any, callback: (response: any[]) => void) {
    //     service.listAll().subscribe((response: any[]) => {
    //         callback(response);
    //     });
    // }

    clear() {
        for (const chave in this.formFilter) {
            delete this.formFilter[chave];
        }
        this.queryParam = "";
        this.fetchAll();
    }

    fetchAll() {
        this.orgaosService
            .listByFilter(0, this.createQueryString(this.formFilter))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            })
    }

    onPageChange(event: any) {
        const page = event.first / event.rows;
        // const page = event.page;
        this.createQueryString(this.formFilter)
        this.orgaosService
            .listByFilter(page, this.createQueryString(this.formFilter))
            .subscribe((response: PageableOrgao) => {
                this.orgaos = response.content;
                this.totalElements = response.totalElements;
            });
    }

    handleChangeStatus(orgao: Orgao) {
        orgao.status = orgao.status === 'A' ? 'I' : 'A';
        this.orgaosService.update(orgao).subscribe((result) => {
            this.fetchAll();
        });
    }

    show(orgao: Orgao) {
        // this.servidorService.sharedData = servidor;
        this.router.navigate(["/servidor"]);
    }

    private createQueryString(formFilter: any): string {
        const partesQueryParam = [];
        for (const chave in formFilter) {
            if (formFilter[chave]) {
                if(isObject(formFilter[chave])) {
                    partesQueryParam.push(
                        `${chave}=${encodeURIComponent(formFilter[chave]?.id)}`
                    );
                } else {
                    partesQueryParam.push(
                        `${chave}=${encodeURIComponent(formFilter[chave])}`
                    );
                }
            }
        }
        return partesQueryParam.join("&");
    }
}
