<p-toast></p-toast>

<div class="card">
    <p-toolbar>
        <ng-template pTemplate="right">
            <button pButton pRipple label="Adicionar" icon="pi pi-plus" class="p-button-success mr-2" (click)="goToRegister()"></button>
        </ng-template>
    </p-toolbar>
    <br>
    <p-table #dt [value]="users" [rows]="10" [paginator]="true" [globalFilterFields]="['user','email','pass','cpf','tel','date','genre','skills','cv']" [tableStyle]="{'min-width': '75rem'}"
        currentPageReportTemplate="Montrando {first} a {last} de {totalRecords} reguistros" [showCurrentPageReport]="true" [rowHover]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="user" style="min-width:15rem">Usuario <p-sortIcon field="user"></p-sortIcon></th>
                <th pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
                <th pSortableColumn="pass" style="min-width:10rem">Senha <p-sortIcon field="pass"></p-sortIcon></th>
                <th pSortableColumn="cpf" style="min-width:10rem">CPF <p-sortIcon field="cpf"></p-sortIcon></th>
                <th pSortableColumn="tel" style="min-width:10rem">Telefone <p-sortIcon field="tel"></p-sortIcon></th>
                <th pSortableColumn="date" style="min-width:10rem">Data de nascimento <p-sortIcon field="date"></p-sortIcon></th>
                <th pSortableColumn="genre" style="min-width:10rem">Genero <p-sortIcon field="genre"></p-sortIcon></th>
                <th pSortableColumn="skills" style="min-width:10rem">Habilidades <p-sortIcon field="skills"></p-sortIcon></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{user.usuario}}</td>
                <td>{{user.email}}</td>
                <td>{{user.senha}}</td>
                <td>{{user.cpf}}</td>
                <td>{{user.tel}}</td>
                <td>{{user.data}}</td>
                <td>{{user.genero == 1 ? 'Masculino' : user.genero == 2 ? 'Feminino' : 'Não declarar'}}</td>
                <td>{{user.habilidades}}</td>
                <td>
                    <button pButton pRipple *ngIf="user.arquivo" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary mr-2" (click)="abrirPdf(dataURItoBlob(user.arquivo))"></button>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" (click)="editar(user.id)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteUser(user)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>