<p-toast></p-toast>

<p-card>
    <ng-template pTemplate="header">
        <div class="grid p-2">
            <div class="col-10">
                <h3>Manter fechamentos</h3>
            </div>
            <div class="col-2 flex justify-content-end gap-1">
                <button
                    pButton
                    type="button"
                    class="p-button-raised p-button-rounded"
                    pTooltip="Incluir órgão"
                    icon="pi pi-plus"
                    tooltipPosition="top"
                    (click)="addFechamento()"
                ></button>
                <button
                    pButton
                    type="button"
                    class="p-element p-ripple p-button-rounded p-button-text p-button p-component p-button-icon-only"
                    pTooltip="Fechar filtro"
                    [icon]="isShowFormFilter ? 'pi pi-times' : 'pi pi-filter'"
                    tooltipPosition="top"
                    (click)="handleShowFilter()"
                ></button>
            </div>
        </div>
    </ng-template>
    <div class="div-fields">
        <div [hidden]="!isShowFormFilter">
            <hr />
            <div class="formgrid grid">
                <div class="field col div-item">
                    <label><b>Órgão</b></label>
                    <!-- <input
                        [(ngModel)]="formFilter.orgao.id"
                        pInputText
                        class="w-full"
                    /> -->
                    <p-autoComplete
                        autoClear="true"
                        [(ngModel)]="formFilterOrgaoAutocomplete"
                        [suggestions]="autoCompleteOrgaos"
                        (completeMethod)="onChangeOrgaoAutocomplete($event)"
                        [multiple]="false"
                        field="name"
                        [style]="{ minWidth: '100%' }"
                        [inputStyle]="{ minWidth: '100%' }"
                        class="p-autocomplete w-full"
                    ></p-autoComplete>
                </div>
                <div class="field col-3 div-item">
                    <label><b>Referência (Mês/Ano)</b></label>
                    <input
                        [(ngModel)]="formFilter.anoMes"
                        pInputText
                        class="w-full"
                        mask="00/0000"
                    />
                </div>
                <div class="field col-2 div-item">
                    <label><b>Data do fechamento</b></label>
                    <br />
                    <p-calendar
                        [(ngModel)]="formFilter.dataFechamento"
                        class="w-full"
                    ></p-calendar>
                    <!-- <input
                        unmask="true"
                        [(ngModel)]="formFilter.dataFechamento"
                        pInputText
                        class="w-full"
                        mask="00/00/0000"
                    /> -->
                </div>
                <!-- <div class="field col div-item">
                    <label><b>Data do agendamento (opcional)</b></label>
                    <input
                        [(ngModel)]="formFilter.dtAgendamento"
                        pInputText
                        class="w-full"
                        mask="00/00/0000"
                    />
                </div> -->
            </div>
            <div class="flex justify-content-end gap-2">
                <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="button"
                    label="Limpar"
                    class="p-button-text"
                    (click)="handleClear()"
                ></button>
                <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="button"
                    label="Consultar"
                    (click)="handleSearch()"
                ></button>
            </div>
        </div>
        <hr />
    </div>
    <div class="div-fields">
        <p-table
            styleClass="p-datatable-striped p-datatable-sm"
            [lazy]="true"
            [paginator]="true"
            [rowHover]="true"
            [rows]="10"
            (onPage)="onChangePage($event)"
            [totalRecords]="resultPaginated?.totalElements || 0"
            [value]="resultPaginated?.content"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Órgão</th>
                    <th>Referência (Mês/Ano)</th>
                    <th>Data do fechamento</th>
                    <!-- <th>Data do agendamento</th> -->
                    <th>Ação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{ item.orgao.organizacaoAdministrativa.nome }}</td>
                    <td>{{ item.anoMes }}</td>
                    <td>
                        {{ item.dataFechamento | date : "dd/MM/yyyy hh:mm:ss" }}
                    </td>
                    <!-- <td>{{ item.dtAgendamento }}</td> -->
                    <td>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-pencil"
                            pTooltip="Editar"
                            tooltipPosition="top"
                            (click)="handleEdit(item)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
