import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CrudOperations } from '../interfaces/crud-operations.interface';
import { PageRequest } from '../models/page-sort/page-request.model';
import { Page } from '../models/page-sort/page.model';

export abstract class AbstractCrudService<T> implements CrudOperations<T, number> {
  protected constructor(
    protected http: HttpClient,
    protected base: string
  ) { }

  save(t: T): Observable<T> {
    return this.http.post<T>(this.base, t, {});
  }

  update(id: number, t: T): Observable<T> {
    return this.http.put<T>(this.base, t, {});
  }

  findOne(id: number): Observable<T> {
    return this.http.get<T>(`${this.base}/${id}`);
  }

  page(pageRequest?: PageRequest): Observable<Page<T>> {
    const params: { [key: string]: any } = !pageRequest ? {} : {
      page: pageRequest.page,
      size: pageRequest.size,
      sort: pageRequest.sort.column + ',' + pageRequest.sort.direction
    };

    return this.http.get<Page<T>>(`${this.base}/paginado`, { params: params })
  }

  findAll(): Observable<Page<T>> {
    return this.http.get<Page<T>>(`${this.base}/all`);
  }

  delete(id: number): Observable<T> {
    return this.http.delete<T>(`${this.base}/${id}`);
  }
}
