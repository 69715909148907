import {PageableEscala} from '../../../pages/escala/models/PageableEscala';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Escala} from '../../../pages/escala/interfaces/escala';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import { EscalaFormFilterType } from '../models/types/escala-form-filter.type';
import { PaginateType } from '../../scf-core/types/paginate.type';
import { EscalaType } from '../models/types/escala.type';
import { ResponseRequest } from '../../core/models/ResponseRequest';


@Injectable({
    providedIn: 'root'
})
export class EscalaService {
    sharedData: any;
    apiUrl = `${environment.apiUrl}/escalas`;

    constructor(private http: HttpClient) {
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }

    findAllPaginated(filter: EscalaFormFilterType, page: number) {
        const url = `${this.apiUrl}/paginado`;
        let filterParam = {
            page: page,
            orgaoId: filter?.orgao?.code,
            descricao: filter?.descricao,
            tipoEscalaId: filter?.tipoEscala?.id
        }
        console.log(filterParam, filter);
        const options = {params: this.addFilterToParams(filterParam)}
        return this.http.get<PaginateType<EscalaType>>(url, options);
    }

    save(escala: EscalaType) {
        return this.http.post<ResponseRequest<Escala>>(this.apiUrl, escala);
    }

    findById(id: Number) {
        return this.http.get<ResponseRequest<EscalaType>>(`${this.apiUrl}/${id}`);
    }

    deleteById(id: Number) {
        return this.http.delete<ResponseRequest<EscalaType>>(`${this.apiUrl}/${id}`);
    }

    // update(formData: EscalaType ): Observable<EscalaType> {
    //     const apiUrl = `${environment.apiUrl}/admin/orgaos`;
    //     return this.http.put<EscalaType>(this.apiUrl, formData, {headers: this.headers}).pipe(
    //         catchError(this.handleError)
    //     );
    // }

    listar(page: number): Observable<PageableEscala> {
        console.log(`${this.apiUrl}/page=${page}`);
        return this.http.get<PageableEscala>(`${this.apiUrl}?page=${page}`).pipe(
            catchError(this.handleError));
    }

    activateStale(escala: Escala) {
        console.log(`${this.apiUrl}/ativar`);
        const apiUrl = `${environment.apiUrl}/ativar`;
        this.http.patch(apiUrl, escala);

    }

    inactivateStale(escala: Escala) {
        console.log(`${this.apiUrl}/inativar`);
        const apiUrl = `${environment.apiUrl}/inativar`;
        this.http.patch(apiUrl, escala);

    }

    excluir(id: number) {
        console.log(`${this.apiUrl}/${id}`);
        const apiUrl = `${environment.apiUrl}/${id}`;
        this.http.delete(apiUrl);

    }

    listarEscalaPorId(idEscala: any) {
        return this.http.get<Escala>(`${this.apiUrl}/${idEscala}`).pipe(
            catchError(this.handleError));
    }

    listByParams(page: number, params: string): Observable<PageableEscala> {
        const apiUrl = `${this.apiUrl}?page=${page}${params ? `&${params}` : ''}`;
        return this.http.get<PageableEscala>(apiUrl);
    }


    private addFilterToParams(filter: object) {
        let params = new HttpParams();
        for (const key in filter) {
            if (filter[key] !== null && typeof filter[key] === 'object') {
                params = params.set(key, JSON.stringify(filter[key]));
                continue;
            }
            if (filter[key]) params = params.set(key, filter[key]);
        }
        return params;
    }
}
