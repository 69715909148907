<p-toast></p-toast>

<form [formGroup]="formSave" (ngSubmit)="handleSubmit()">
    <p-card>
        <ng-template pTemplate="header">
            <div class="grid p-2">
                <div class="col">
                    <h3>{{ id ? "Editar" : "Cadastrar" }} escala</h3>
                </div>
            </div>
        </ng-template>
        <div class="grid p-fluid">
            <div class="field col-12">
                <label for="nome"><b>Órgão</b></label>
                <!-- <input
                pInputText
                formControlName="nome"
                placeholder="Nome Completo"
                name="nome"
                class="w-full"
                id="nome"
            /> -->
                <p-autoComplete
                    formControlName="orgao"
                    aria-required="true"
                    autoClear="true"
                    [multiple]="false"
                    field="name"
                    [suggestions]="autoCompleteOrgaos"
                    (completeMethod)="onChangeOrgaoAutocomplete($event)"
                ></p-autoComplete>
            </div>
            <div class="field col-4">
                <label for="tipoEscala"><b>Tipo da Escala</b></label>
                <p-dropdown
                    formControlName="tipoEscala"
                    inputId="tipoEscala"
                    optionLabel="descricao"
                    placeholder="Selecione o tipo"
                    [options]="listTiposEscalas"
                    (onChange)="onChangeTipoEscala($event.value)"
                >
                    <!-- (onChange)="getExibeBotoesInserirJornada($event.value)" -->
                </p-dropdown>
            </div>
            <div class="field col-2">
                <label for="dataInicioVigencia"
                    ><b>Inicio de vigência</b></label
                >
                <br />
                <p-calendar
                    inputId="dataInicioVigencia"
                    formControlName="dataInicioVigencia"
                    aria-required="true"
                    placeholder="Digite a data"
                    showTime="true"
                    [hourFormat]="24"
                ></p-calendar>
            </div>
            <div class="field col-6">
                <label for="descricao"><b>Descrição</b></label>
                <input
                    formControlName="descricao"
                    inputId="descricao"
                    pInputText
                    placeholder="Digite a descrição"
                />
            </div>

            <div class="field col-4">
                <label for="horasJornadaDiaria"><b>Jornada Diária</b></label>
                <p-inputNumber
                    formControlName="horasJornadaDiaria"
                    inputId="horasJornadaDiaria"
                    placeholder="Jornada diária"
                    mode="decimal"
                    [useGrouping]="false"
                    [max]="24"
                ></p-inputNumber>
            </div>

            <div class="field col-4">
                <label for="horasJornadaSemanal"><b>Jornada Semanal</b></label>
                <p-inputNumber
                    formControlName="horasJornadaSemanal"
                    inputId="horasJornadaSemanal"
                    placeholder="Jornada semanal"
                    mode="decimal"
                    [useGrouping]="false"
                    [max]="168"
                ></p-inputNumber>
            </div>

            <div class="field col-4">
                <label for="horasJornadaMensal"><b>Jornada Mensal</b></label>
                <p-inputNumber
                    formControlName="horasJornadaMensal"
                    inputId="horasJornadaMensal"
                    placeholder="Jornada mensal"
                    mode="decimal"
                    [useGrouping]="false"
                    [max]="774"
                ></p-inputNumber>
            </div>

            <div class="field col-4 fontForm8">
                <label><b>Banco de Horas</b></label>
                <div class="formgrid grid">
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            inputId="bancoHoras1"
                            value="S"
                            formControlName="statusBancoDeHoras"
                        ></p-radioButton>
                        <label for="bancoHoras1">Sim</label>
                    </div>
                    <div class="field-radiobutton col-2 danger">
                        <p-radioButton
                            inputId="bancoHoras2"
                            value="N"
                            formControlName="statusBancoDeHoras"
                        ></p-radioButton>
                        <label for="bancoHoras2">Não</label>
                    </div>
                </div>
            </div>

            <div class="field col-4 fontForm8">
                <label><b>Flexibilidade</b></label>
                <div class="formgrid grid">
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            inputId="flexibilidade1"
                            value="S"
                            formControlName="statusHorarioFlexivel"
                        ></p-radioButton>
                        <label for="flexibilidade1">Sim</label>
                    </div>
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            inputId="flexibilidade2"
                            value="N"
                            formControlName="statusHorarioFlexivel"
                        ></p-radioButton>
                        <label for="flexibilidade2">Não</label>
                    </div>
                </div>
            </div>
            <div class="field col-4">
                <label><b>Escala Continua</b></label>
                <div class="formgrid grid">
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            inputId="escalacontinua1"
                            value="S"
                            formControlName="statusEscalaContinua"
                        ></p-radioButton>
                        <label for="escalacontinua1">Sim</label>
                    </div>
                    <div class="field-radiobutton col-2">
                        <p-radioButton
                            inputId="escalacontinua2"
                            value="N"
                            formControlName="statusEscalaContinua"
                        ></p-radioButton>
                        <label for="escalacontinua2">Não</label>
                    </div>
                </div>
            </div>
            <!-- <div class="field col-12 div-item">
                <label><b>Justificativa (opcional)</b></label>
                <textarea
                    formControlName="justificativa"
                    pInputTextarea
                    class="w-full"
                ></textarea>
            </div> -->
        </div>

        <div class="grid flex gap-2">
            <div *ngIf="isToShowJornadas" class="col-12">
                <button
                    pButton
                    type="button"
                    (click)="handleDialogJornadas()"
                    class="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="left"
                ></button>
                <button
                    pButton
                    type="button"
                    (click)="handleDialogJornadas()"
                    label="Adicionar jornada de trabalho"
                    class="p-button-link mr-3"
                ></button>
            </div>
            <div *ngIf="isToShowIntervalos" class="col-12">
                <button
                    pButton
                    type="button"
                    (click)="handleDialogIntervalos()"
                    class="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="left"
                ></button>
                <button
                    pButton
                    type="button"
                    label="Adicionar intervalo"
                    class="p-button-link"
                ></button>
            </div>
        </div>

        <!-- ng-invalid -->
        <ng-template pTemplate="footer">
            <div class="grid">
                <div class="col-12">
                    <div class="flex justify-content-end gap-2">
                        <button
                            iconPos="right"
                            pButton
                            pRipple
                            type="button"
                            label="Cancelar"
                            class="p-button-text"
                            (click)="handleCancel()"
                        ></button>
                        <button
                            iconPos="right"
                            pButton
                            pRipple
                            type="button"
                            [label]="id ? 'Editar' : 'Cadastrar'"
                            (click)="handleSubmit()"
                        ></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</form>

<p-dialog
    [(visible)]="isToShowDialogJornadas"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="horaEntrada">Entrada</label>
            <input
                pInputText
                [(ngModel)]="formJornada.horaEntrada"
                placeholder="Entrada"
                name="formJornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
                mask="00:00"
            />
        </div>

        <div class="field col-4">
            <label for="horaSaida">Saida</label>
            <input
                pInputText
                [(ngModel)]="formJornada.horaSaida"
                placeholder="Saida"
                name="formJornada.horaSaida"
                class="w-full"
                id="horaSaida"
                mask="00:00"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">Jornada Diaria </label>
            <p-inputNumber
                [(ngModel)]="formJornada.quantidadeHoras"
                inputId="horasJornadaDiaria"
                placeholder="Jornada diária"
                mode="decimal"
                [useGrouping]="false"
                [max]="24"
            ></p-inputNumber>
        </div>

        <div *ngIf="isToShowJornadas"></div>
        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="1"
                label="Seg"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="2"
                label="Ter"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-2">
            <p-checkbox
                name="diasSemana"
                value="3"
                label="Qua"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="4"
                label="Qui"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="5"
                label="Sex"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="6"
                label="Sab"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="7"
                label="Dom"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-12">
            <p><b> Intervalos</b></p>
        </div>

        <div class="field col-4">
            <label for="horaIntervaloEntrada1">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="formJornada.horaIntervaloEntrada1"
                placeholder="Intervalo Entrada"
                name="horaIntervaloEntrada1"
                class="w-full"
                id="horaIntervaloEntrada1"
                mask="00:00"
            />
        </div>

        <div class="field col-4">
            <label for="horaIntervaloSaida1">Saida:*</label>
            <input
                pInputText
                [(ngModel)]="formJornada.horaIntervaloSaida1"
                placeholder="Intervalo Saida"
                name="horaIntervaloSaida1"
                class="w-full"
                id="horaIntervaloSaida1"
                mask="00:00"
            />
        </div>
    </form>

    <div class="grid">
        <div class="col-12 flex justify-content-end">
            <div class="flex gap-2">
                <button
                    pButton
                    pRipple
                    label="  Cancelar "
                    class="p-button-text"
                    (click)="handleDialogJornadas()"
                ></button>
                <button
                    pButton
                    pRipple
                    label="  Incluir  "
                    class="p-button-raised"
                    (click)="handleSubmitJornada()"
                ></button>
            </div>
        </div>
    </div>
</p-dialog>

<!-- <p-dialog
    [(visible)]="adicionaJornadaRevezamento"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="entrada">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaEntrada"
                placeholder="Entrada"
                name="jornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
            />
        </div>

        <div class="field col-4">
            <label for="saida">Jornada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaSaida"
                placeholder="Saida"
                name="jornada.horaSaida"
                class="w-full"
                id="horaSaida"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">InterJornada </label>
            <input
                pInputText
                [(ngModel)]="jornada.quantidadeHoras"
                placeholder="Jornada Diária"
                name="jornada.quantidadeHoras"
                class="w-full"
                id="quantidadeHoras"
            />
        </div>

        <div class="field col-4">
            <label for="horaIntervaloEntrada1">Quantidade Intervalos:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaIntervaloEntrada1"
                placeholder="Intervalo Entrada"
                name="horaIntervaloEntrada1"
                class="w-full"
                id="horaIntervaloEntrada1"
            />
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog> -->

<!-- <p-dialog
    [(visible)]="adicionaJornadaTeleatendimento"
    [style]="{ width: '750px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-12">
            <p><b> Selecione a jornada de trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="entrada">Entrada:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaEntrada"
                placeholder="Entrada"
                name="jornada.horaEntrada"
                class="w-full"
                id="horaEntrada"
            />
        </div>

        <div class="field col-4">
            <label for="saida">Saida:*</label>
            <input
                pInputText
                [(ngModel)]="jornada.horaSaida"
                placeholder="Saida"
                name="jornada.horaSaida"
                class="w-full"
                id="horaSaida"
            />
        </div>

        <div class="field col-4">
            <label for="quantidadeHoras">Jornada Diaria </label>
            <input
                pInputText
                [(ngModel)]="jornada.quantidadeHoras"
                placeholder="Jornada Diária"
                name="jornada.quantidadeHoras"
                class="w-full"
                id="quantidadeHoras"
            />
        </div>

        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="1"
                label="Seg"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="2"
                label="Ter"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
        <div class="field col-2">
            <p-checkbox
                name="diasSemana"
                value="3"
                label="Qua"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="4"
                label="Qui"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="5"
                label="Sex"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="6"
                label="Sab"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>

        <div class="field col-3">
            <p-checkbox
                name="diasSemana"
                value="7"
                label="Dom"
                [(ngModel)]="diasSelecionadosJornada"
            ></p-checkbox>
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog> -->

<p-dialog
    [(visible)]="isToShowDialogIntervalos"
    [style]="{ width: '850px' }"
    [modal]="true"
    styleClass="p-fluid"
>
    <form class="formgrid grid">
        <div class="field col-4">
            <label for="idNrMinutos"><b> Duração do Intervalo:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaMinutos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="formIntervalo.numeroMinutosIntervalo"
                inputId="idNrMinutos"
            >
            </p-dropdown>
        </div>

        <div></div>

        <div class="field col-12">
            <p><b> Dias de Trabalho</b></p>
        </div>

        <div class="field col-4">
            <label for="inicioIntervalo"><b> Inicio:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaPrimeirosIntervalos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="formIntervalo.numeroInicioIntervalo"
                inputId="inicioIntervalo"
            >
            </p-dropdown>
        </div>

        <div class="field col-4">
            <label for="fimIntervalo"><b> Fim:*</b></label>

            <p-dropdown
                styleClass="w-full"
                [options]="listaSegundosIntervalos"
                placeholder="Selecione o intervalo"
                [(ngModel)]="formIntervalo.numeroFimIntervalo"
                inputId="fimIntervalo"
            >
            </p-dropdown>
        </div>
    </form>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left"> </ng-template>

        <ng-template pTemplate="right">
            <button
                pButton
                pRipple
                label="  Cancelar "
                class="p-button-outlined mr-3"
                routerLink="/escalas"
            ></button>
            <button
                pButton
                pRipple
                label="  Incluir  "
                class="p-button-raised p-button-rounded mr-3"
                (click)="openNew()"
            ></button>
        </ng-template>
    </p-toolbar>
</p-dialog>
