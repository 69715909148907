import { Injectable } from "@angular/core";
import dayjs from "dayjs";


@Injectable({
    providedIn: 'root'
})
export class DateUtil {

    constructor() {
    }

    public yearMonthToAPI(text: string) {
        text = text.replace('/', '');
        if (text.substring(0, 1) == '0') text = text.substring(1);
        return text;
    }

    public yearMonthToView(text: string) {
        if (text.length < 6) text = '0' + text;
        return `${text.substring(0, 2)}/${text.substring(2)}`;
    }

    public dateTimeToAPI(date: Date) {
        return dayjs(date).format('YYYY-MM-DDThh:mm');
    }

    public dateToAPI(date: Date) {
        return dayjs(date).format('YYYY-MM-DD');
    }

    public dateToAPI2(date: Date) {
        return dayjs(date).format('YYYY-MM-DD');
    }

    public dateToView(date: any) {
        return dayjs(date).format('MM/DD/YYYY hh:mm');
    }
}
