import {Component, OnInit} from '@angular/core';
import {Orgao} from "../../../modules/corporativo/models/orgao";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FechamentoFormSave } from 'src/app/modules/scf/models/types/fechamento-form-save.type';
import { Fechamento } from 'src/app/modules/scf/models/fechamento.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrgaosService } from 'src/app/modules/scf/services/orgaos.service';
import { MessageService } from 'primeng/api';
import { FechamentoService } from 'src/app/modules/scf/services/fechamento.service';
import { first } from 'rxjs';
import { DateUtil } from 'src/app/modules/scf/utils/date.util';
import { TipoEscala } from '../interfaces/tipoEscala';
import { TiposEscalasService } from 'src/app/modules/scf/services/tipos-escalas.service';
import { Messages } from 'src/app/modules/scf-core/constants/messages';
import { EscalaService } from 'src/app/modules/scf/services/escala.service';
import { Jornada } from '../interfaces/jornada';
import { IntervaloJornada } from '../interfaces/intervaloJornada';

@Component({
    selector: 'app-escala-save',
    templateUrl: './escala-save.component.html'
})
export class EscalaSaveComponent implements OnInit {
    listTiposEscalas: TipoEscala[];
    autoCompleteOrgaos: object[];
    formSave: FormGroup;
    id: number;
    isToShowJornadas = false;
    isToShowIntervalos = false;
    isToShowDialogJornadas = false;
    isToShowDialogIntervalos = false;

    formJornada: Jornada = {
        id: null,
        horaEntrada: null,
        horaSaida: null,
        horaIntervaloEntrada1: null,
        horaIntervaloSaida1: null,
        horaIntervaloEntrada2: null,
        horaIntervaloSaida2: null,
        horaIntervaloEntrada3: null,
        horaIntervaloSaida3: null,
        numeroDiaSemana: 0,
        quantidadeHoras: 0,
        quantidadeJornada: 0,
        descricaoDiaSemana: '',
        quantidadeIntervalos: 0,
        status: '',
        descricaoJustificativa: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null
    };
    diasSelecionadosJornada: number[] = [];
    formIntervalo: IntervaloJornada = {
        id: null,
        numeroMinutosIntervalo: '',
        numeroInicioIntervalo: '',
        numeroFimIntervalo: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null

    };
    listaMinutos = [
        '10 minutos',
        '20 minutos'
    ];
    listaPrimeirosIntervalos = [
        '1ª Hora',
        '2ª Hora',
        '3ª Hora',
        '4ª Hora'
    ];
    listaSegundosIntervalos = [
        '1ª Hora',
        '2ª Hora',
        '3ª Hora',
        '4ª Hora'
    ];

    constructor(
        private service: EscalaService,
        private serviceTiposEscalas: TiposEscalasService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private orgaosService: OrgaosService,
        private messageService: MessageService,
        private dateUtil: DateUtil
    ) {
    }

    ngOnInit(): void {
        this.formSave = this.formBuilder.group({
            orgao: ['', Validators.required],
            tipoEscala: ['', Validators.required],
            descricao: ['', Validators.required],
            statusBancoDeHoras: ['', Validators.required],
            dataInicioVigencia: ['', Validators.required],
            statusHorarioFlexivel: ['', Validators.required],
            statusEscalaContinua: ['', Validators.required],
            horasJornadaDiaria: ['', Validators.required],
            horasJornadaSemanal: ['', Validators.required],
            horasJornadaMensal: ['', Validators.required],
        });

        if (this.service.sharedData) {
            this.id = this.service.sharedData.id;
            const dataForm: any = this.service.sharedData;
            dataForm.orgao = {
                name: dataForm.orgao.organizacaoAdministrativa.nome,
                code: dataForm.orgao.id,
            }
            this.populateForm(this.service.sharedData);
            this.onChangeTipoEscala(dataForm.tipoEscala);
        } else {
            this.route.paramMap.subscribe(params => {
                this.id = parseInt(params.get('id'));
                if (this.id) {
                    this.fetchData(this.id);
                    return;
                }
            });
        }

        this.fetchTiposEscalas();
    }

    fetchData(id: any) {
        this.service.findById(id).subscribe((response) => {
            const dataForm: any = response.data;
            dataForm.dataInicioVigencia = this.dateUtil.dateToView(dataForm.dataInicioVigencia)
            dataForm.orgao = {
                name: dataForm.orgao.organizacaoAdministrativa.nome,
                code: dataForm.orgao.id,
            }
            this.populateForm(dataForm);
            if (dataForm.tipoEscala) {
                this.onChangeTipoEscala(dataForm.tipoEscala);
            }
        },
        _ => {
            this.messageService.add({severity:'error', summary: 'Ops!', detail: Messages.error.service, life: 3000});
        });
    }

    populateForm(data: any): void {
        let customData = data;
        // customData.dataFechamento = this.dateUtil.dateToView(customData.dataFechamento)
        // customData.orgao = {
        //     name: data.orgao.organizacaoAdministrativa.nome,
        //     code: data.orgao.id,
        // }
        // console.log(this.service.sharedData, 'aqui ----', customData, this.formFilterOrgaoAutocomplete)
        this.formSave.patchValue(customData);
    }

    onChangeOrgaoAutocomplete(event) {
        if (event.query.length >= 3) {
            this.fetchOrgaosByTagOrName(event.query);
        }
    }

    fetchOrgaosByTagOrName(nome: string) {
        this.orgaosService.findAllByTagOrName({
            nome: nome,
            status: 'A'
        }).subscribe(response => {
            const results = response.content.map(item =>
                ({
                    name: item.organizacaoAdministrativa.nome,
                    code: item.id
                })
            )
            this.autoCompleteOrgaos = results;
        });
    }

    handleCancel(): void{
        this.router.navigate(['escalas']);
    }

    handleSubmit(): void{
        let dataForm = this.formSave.getRawValue();
        console.log(dataForm, 'FORM')
        if (!this.formSave.dirty || !this.formSave.valid) {
            if (!this.id || !dataForm.orgao) {
                this.messageService.add({severity:'warn', summary: 'Ops!', detail: Messages.alert.required, life: 3000});
                return;
            }
        }
        dataForm.orgao = {
            id: dataForm.orgao.code
        }
        dataForm.tipoEscala = {
            id: dataForm.tipoEscala.id
        }
        dataForm.dataInicioVigencia = this.dateUtil.dateTimeToAPI(dataForm.dataFechamento);
        dataForm.status = 'S';
        if (this.id) {
            dataForm.id = this.id;
        }
        this.service.save(dataForm)
            .pipe(first())
            .subscribe(data => {
                console.log('voltou', data)
                // if (data != null) {
                    // this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Fechamento cadastrado com sucesso!!!', life: 3000});
                    // setTimeout(() => {
                        this.router.navigate(['escalas']);
                    // }, 500)

                // } else {
                // alert(data);
                // }
            },
            error => {
                this.messageService.add({severity:'error', summary: 'Ops!', detail: Messages.error.service, life: 3000});
            });
            // this.messageService.add({severity:'error', summary: 'Ops!', detail: 'CPF ou Senha inválida!', life: 3000});

    }

    fetchTiposEscalas() {
        this.serviceTiposEscalas.findAll().subscribe((response) => {
            this.listTiposEscalas = response.data;
        });
    }

    onChangeTipoEscala(event) {
        if (event.nome === 'DIARIA' || event.nome === 'TELEATENDIMENTO') {
            this.isToShowJornadas = true;
            this.isToShowIntervalos = true;
            return;
        }

        if (event.nome === 'REVEZAMENTO') {
            this.isToShowJornadas = true;
            this.isToShowIntervalos = false;
            return;
        }
    }

    handleDialogIntervalos() {
        this.isToShowDialogIntervalos = !this.isToShowDialogIntervalos;
    }

    handleDialogJornadas() {
        this.isToShowDialogJornadas = !this.isToShowDialogJornadas;

    }

    handleSubmitJornada() {
        // this.isToShowDialogIntervalos = !this.isToShowDialogIntervalos;
        console.log(this.formJornada);
    }
}
