export const environment = {
    production: false,
    isToUseAuthentication: true,
    authorizationUrl: 'https://ssodesenv.go.gov.br/api/identity/entitlement/decision',
    apiUrl: 'https://apidesenv.go.gov.br/rh/controle-frequencia/v1.0',
    apiServidorUrl: 'http://localhost:3000/test',
    apiPortalAcessos: 'https://apidesenv.go.gov.br/portal/acessos-usuarios/v1.0',
    apiPortalMenusSistemas: 'https://apidesenv.go.gov.br/portal/menus-sistemas/v1.0',
    apiRhVinculos: 'https://apidesenv.go.gov.br/rh/vinculos/v1.0',
    idSistemaPortal: 'SCF',
    sso: {
        clientId: 'O2W0c8mA1hrgLmUl1dobZfp9_XYa',
        serverUrl: 'https://ssodesenv.go.gov.br',
        issuer: '/oauth2/oidcdiscovery',
        redirectUri: window.location.origin,
        scope: 'openid email profile',
        showDebugInformation: true,
        urlCheckSession: '/oidc/checksession',
        responseType: 'code',
    }
};
