

export const Messages = {
    alert: {
        required: 'Falta preencher os campos!'
    },
    error: {
        service: 'Não foi possível realizar a operação!'
    }
}
