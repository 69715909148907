import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Servidor} from "src/app/shared/models/servidor/servidor.model";
import {ServidorService} from "src/app/shared/services/servidor/servidor.service";
import {AppBreadcrumbService} from "../../../modules/main/breadcrumb/app.breadcrumb.service";
import {Orgao} from "../../../modules/corporativo/models/orgao";
import {UnidadeAdministrativa} from "../../../modules/core/models/rhnet/unidade-admnistrativa.model";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import {UnidadeService} from "../../escala/services/unidade.service";
import {Unidade} from "../../escala/interfaces/unidade";

@Component({
    selector: "app-servidor-detail",
    templateUrl: "./servidor-detail.component.html",
    styleUrls: ["./servidor-detail.component.scss"],
})
export class ServidorDetailComponent implements OnInit {
    servidor: Servidor;
    orgaos: Orgao[];
    unidades: Unidade[];

    constructor(
        private servidorService: ServidorService,
        private router: Router,
        private breadcrumbService: AppBreadcrumbService,
        private orgaoService: OrgaoService,
        private unidadeService: UnidadeService
    ) {
        this.servidor = this.servidorService.sharedData;
        this.breadcrumbService.setItems([
            {label: "Servidores", routerLink: ["/servidores"]},
            {label: this.servidor.nome},
        ]);
        orgaoService.listAll().subscribe(orgaos => {
            this.orgaos = orgaos?.data
        });
        unidadeService.listar().subscribe(unidades => {
            this.unidades = unidades;
        })
    }

    ngOnInit(): void {
    }

    onRedirect(url: String) {
        this.router.navigate([url]);
    }
}
