<p-toast></p-toast>

<div class="card">
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <button pButton pRipple label="Adicionar" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Deletar" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
        </ng-template>
    </p-toolbar>
    <p-table #dt [value]="products" [rows]="10" [paginator]="true" [globalFilterFields]="['name','price','category','inventoryStatus']" [tableStyle]="{'min-width': '75rem'}"
        [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Montrando {first} a {last} de {totalRecords} reguistros" [showCurrentPageReport]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem" *ngIf="products.length > 0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="name" style="min-width:15rem">Nome <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="price">Preço <p-sortIcon field="price"></p-sortIcon></th>
                <th pSortableColumn="category" style="min-width:10rem">Categoria <p-sortIcon field="category"></p-sortIcon></th>
                <th pSortableColumn="inventoryStatus" style="min-width:10rem">Status <p-sortIcon field="inventoryStatus"></p-sortIcon></th>
                <th></th>
            </tr>
            <tr>
                <th *ngIf="products.length > 0"></th>
                <th>
                    <p-columnFilter type="text" field="name"></p-columnFilter>
                </th>
                <th>
                    <p-columnFilter type="numeric" field="price" currency="BRL"></p-columnFilter>
                </th>                                                                                                                                               
                <th>
                    <p-columnFilter field="category" matchMode="in" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect [ngModel]="value" appendTo="body" [options]="categorias" placeholder="Selecione" (onChange)="filter($event.value)"></p-multiSelect>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>
                    <p-columnFilter field="inventoryStatus" matchMode="equals" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-dropdown [ngModel]="value" appendTo="body" [options]="statuses" (onChange)="filter($event.value)" placeholder="Selecione"></p-dropdown>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>
                    <p-tableCheckbox [value]="product"></p-tableCheckbox>
                </td>
                <td>{{product.name}}</td>
                <td>{{product.price | currency:'BRL'}}</td>
                <td>{{product.category}}</td>
                <td><span>{{product.inventoryStatus?.label}}</span></td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" (click)="editProduct(product)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="productDialog" [style]="{width: '450px'}" header="Detalhe do Produto" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <img [src]="'assets/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image">
        <div class="field">
            <label for="name">Nome</label>
            <input type="text" pInputText id="name" [(ngModel)]="product.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !product.name">Nome é obrigatório.</small>
        </div>
        <div class="field">
            <label for="description">Descrição</label>
            <textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
        </div>

        <div class="field">
            <label for="inventoryStatus">Status no inventário</label>
            <p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Selecione" optionLabel="label"></p-dropdown>
        </div>

        <div class="field">
            <label class="mb-3">Categoria</label>
            <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category1" name="category" value="Acessórios" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category1">Acessórios</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category2" name="category" value="Roupas" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category2">Roupas</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category3" name="category" value="Eletrônicos" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category3">Eletrônicos</label>
                </div>
                <div class="field-radiobutton col-6">
                    <p-radioButton id="category4" name="category" value="Fitness" [(ngModel)]="product.category"></p-radioButton>
                    <label for="category4">Fitness</label>
                </div>
            </div>
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label for="price">Preço</label>
                <p-inputNumber id="price" [(ngModel)]="product.price" mode="currency" currency="BRL" locale="pt-BR"></p-inputNumber>
            </div>
            <div class="field col">
                <label for="quantity">Quantidade</label>
                <p-inputNumber id="quantity" [(ngModel)]="product.quantity"></p-inputNumber>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>