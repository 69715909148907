import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {IntervaloJornada} from '../interfaces/intervaloJornada';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IntervaloJornadaService {

    sharedData: any;
    apiUrl = `${environment.apiUrl}/escala/intervalos`;

    constructor(private http: HttpClient) {
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }


    listarIntervalosPorEscala(): Observable<IntervaloJornada[]> {
        console.log(`${this.apiUrl}`);
        return this.http.get<IntervaloJornada[]>(`${this.apiUrl}`).pipe(
            catchError(this.handleError));
    }

}
