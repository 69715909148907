import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {PageableOrgao} from '../models/orgao.page.model';
import {Orgao} from "../models/orgao.model";
import { AuthService } from '../../core/auth/auth.service';
import { ResponseRequest } from '../../core/models/ResponseRequest';
import { PaginateType } from '../../scf-core/types/paginate.type';

@Injectable({
    providedIn: 'root'
})
export class OrgaosService {
    sharedData: any;
    headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private authService: AuthService) {
      this.headers = new HttpHeaders({
        'Authorization': `Bearer ${this.authService.getUserToken()}`
      });
    }

    findAll(filter?: any): Observable<ResponseRequest<Orgao[]>> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos`;
        let params = new HttpParams();
        params = params.set('status', 'A')

        if (filter.nome) {
            params = params.set('nome', filter.nome)
        }
        // if (filter.status) {
        //     params.set('status', filter.status)
        // }

        const options = {
            headers: this.headers,
            params
        }

        return this.http.get<ResponseRequest<Orgao[]>>(apiUrl, options).pipe(
            catchError(this.handleError)
        );
    }

    findAllByTagOrName(filter?: any): Observable<PaginateType<Orgao>> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos/paginado`;
        let params = new HttpParams();
        params = params.set('status', 'A')

        if (filter.nome) {
            params = params.set('nome', filter.nome)
        }
        // if (filter.status) {
        //     params.set('status', filter.status)
        // }

        const options = {
            headers: this.headers,
            params
        }

        return this.http.get<PaginateType<Orgao>>(apiUrl, options).pipe(
            catchError(this.handleError)
        );
    }

    listAllPaginated(): Observable<PageableOrgao> {
        let params = new HttpParams();

        const apiUrl = `${environment.apiUrl}/admin/orgaos/paginado`;
        return this.http.get<PageableOrgao>(apiUrl, {headers: this.headers}).pipe(
            catchError(this.handleError)
        );
    }

    saveAll(formData: object[] ): Observable<Orgao> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos/lista`;
        return this.http.post<Orgao>(apiUrl, formData, {headers: this.headers}).pipe(
            catchError(this.handleError)
        );
    }

    update(formData: Orgao ): Observable<Orgao> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos`;
        return this.http.put<Orgao>(apiUrl, formData, {headers: this.headers}).pipe(
            catchError(this.handleError)
        );
    }

    listByFilter(page: number, filter: string): Observable<PageableOrgao> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos/paginado?page=${page}${filter ? `&${filter}` : ''}`;
        return this.http.get<PageableOrgao>(apiUrl, {
            headers: this.headers
        }).pipe(
            catchError(this.handleError)
        );
    }

    findOne(id: string): Observable<Orgao> {
        const apiUrl = `${environment.apiUrl}/admin/orgaos/${id}`;
        return this.http.get<Orgao>(apiUrl).pipe();
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }
}
