import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {RegimeJuridico} from "src/app/shared/models/servidor/regime.juridico";
import {Servidor} from "src/app/shared/models/servidor/servidor.model";
import {PageableServidor} from "src/app/shared/models/servidor/servidor.page.model";
import {SituacaoFuncional} from "src/app/shared/models/servidor/situacao.funcional";
import {RegimeJuridicoService} from "src/app/shared/services/servidor/regime-juridico.service";
import {ServidorService} from "src/app/shared/services/servidor/servidor.service";
import {SituacaoFuncionalService} from "src/app/shared/services/servidor/situacao-funcional.service";
import {AppBreadcrumbService} from "../../../modules/main/breadcrumb/app.breadcrumb.service";
import {Orgao} from "../../../modules/corporativo/models/orgao";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import {CPFUtils} from "../../../utilities/cpf-utils";
import { Response } from "src/app/modules/corporativo/models/response";

@Component({
    selector: "app-servidores-list",
    templateUrl: "./servidores-list.component.html",
    styleUrls: ["./servidores-list.component.scss"],
})
export class ServidoresListComponent implements OnInit {
    isShowFormFilter: boolean = false;
    servidores: Servidor[];
    situacoes: SituacaoFuncional[];
    regimes: RegimeJuridico[];
    lotacoes: Orgao[];
    orgaosOrigem: Orgao[];
    totalElements: number;
    parametros = {
        orgaoOrigem: null,
        nome: "",
        cpf: "",
        regimeJuridico: null,
        situacaoFuncional: null,
        codigoVinculo: null,
        orgaoId: null,
    };
    queryParam: string;

    constructor(
        private servidorService: ServidorService,
        private situacaoFuncionalService: SituacaoFuncionalService,
        private regimeJuridicoService: RegimeJuridicoService,
        private lotacaoService: OrgaoService,
        private orgaoOrigemService: OrgaoService,
        private router: Router,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            {label: "Servidores", routerLink: ["/servidores"]},
        ]);
    }

    ngOnInit(): void {
        this.clear();
        this.initiateDataLoading(
            this.situacaoFuncionalService,
            (response) => (this.situacoes = response)
        );
        this.initiateDataLoading(
            this.regimeJuridicoService,
            (response) => (this.regimes = response)
        );
        this.initiateDataLoading(
            this.lotacaoService,
            (response) => (this.lotacoes = response)
        );
        this.initiateDataLoading(
            this.orgaoOrigemService,
            (response) => (this.orgaosOrigem = response)
        );
    }

    handlerShowFilter() {
        this.isShowFormFilter = !this.isShowFormFilter;
    }

    initiateDataLoading(service: any, callback: (response: any[]) => void) {
        service.listAll().subscribe((response: Response<any>) => {
            callback(response.data);
        });
    }

    clear() {
        for (const chave in this.parametros) {
            delete this.parametros[chave];
        }
        this.parametros.orgaoId = 308;
        this.queryParam = "";
        this.fetchServidores();
    }

    onPageChange(event: any) {
        const page = event.first / event.rows;
        this.servidorService
            .listAllInCorporative(page, this.createQueryString(this.parametros))
            .subscribe((response: PageableServidor) => {
                this.servidores = response.content;
                this.totalElements = response.totalElements;
            });
    }

    showServidor(servidor: Servidor) {
        this.servidorService.sharedData = servidor;
        this.router.navigate([`/servidor/${servidor.id}`]);
    }

    formatCPFNumber(cpf: string): string {
        return CPFUtils.formatCPFNumber(cpf);
    }

    fetchServidores() {
        this.servidorService
            .listAllInCorporative(0, this.createQueryString(this.parametros))
            .subscribe((response: PageableServidor) => {
                this.servidores = response.content;
                this.totalElements = response.totalElements;
            })
    }

    private createQueryString(parametros: { [key: string]: string }): string {
        const partesQueryParam = [];
        for (const chave in parametros) {
            if (parametros[chave]) {
                partesQueryParam.push(
                    `${chave}=${encodeURIComponent(parametros[chave])}`
                );
            }
        }
        return partesQueryParam.join("&");
    }
}
