<p-toast></p-toast>
<p-card>
    <ng-template pTemplate="header">
        <div class="grid p-2">
            <div class="col">
                <h3>Visualizar escala</h3>
            </div>
        </div>
    </ng-template>
    <div class="grid p-fluid">
        <div class="col-12">
            <label><b>Órgão</b></label>
            <div class="m-2">
                {{ data?.orgao.organizacaoAdministrativa.nome }}
            </div>
        </div>
        <div class="col-4">
            <label><b>Tipo da Escala</b></label>
            <div class="m-2">
                {{ data?.tipoEscala.nome }}
            </div>
        </div>
        <div class="col-2">
            <label><b>Inicio de vigência</b></label>
            <div class="m-2">
                {{ data?.dataInicioVigencia }}
            </div>
        </div>
        <div class="col-6">
            <label><b>Descrição</b></label>
            <div class="m-2">
                {{ data?.descricao }}
            </div>
        </div>

        <div class="col-4">
            <label for="horasJornadaDiaria"><b>Jornada Diária</b></label>
            <div class="m-2">
                {{ data?.horasJornadaDiaria }}
            </div>
        </div>

        <div class="col-4">
            <label for="horasJornadaSemanal"><b>Jornada Semanal</b></label>
            <div class="m-2">
                {{ data?.horasJornadaSemanal }}
            </div>
        </div>

        <div class="col-4">
            <label><b>Jornada Mensal</b></label>
            <div class="m-2">
                {{ data?.horasJornadaMensal }}
            </div>
        </div>

        <div class="col-4 fontForm8">
            <label><b>Banco de Horas</b></label>
            <div class="m-2">
                {{ data?.statusBancoDeHoras ? "Sim" : "Não" }}
            </div>
        </div>

        <div class="col-4">
            <label><b>Flexibilidade</b></label>
            <div class="m-2">
                {{ data?.statusHorarioFlexivel ? "Sim" : "Não" }}
            </div>
        </div>

        <div class="col-4">
            <label><b>Escala Continua</b></label>
            <div class="m-2">
                {{ data?.statusEscalaContinua ? "Sim" : "Não" }}
            </div>
        </div>
    </div>
    <!-- ng-invalid -->
    <ng-template pTemplate="footer">
        <div class="grid">
            <div class="col-12">
                <div class="flex justify-content-end gap-2">
                    <button
                        iconPos="right"
                        pButton
                        pRipple
                        type="button"
                        label="Voltar"
                        (click)="handleCancel()"
                    ></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-card>
