import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';

@Component({
  selector: 'app-crud-produtos',
  templateUrl: './crud-produtos.component.html',
  styleUrls: ['./crud-produtos.component.scss']
})
export class CrudProdutosComponent implements OnInit {
  productDialog: boolean = false;

  products: any[] = [
    {
      name: 'Geladeira',
      description: 'teste',
      price: 960,
      category: 'Eletrônicos',
      inventoryStatus: {label: 'EM ESTOQUE', value: 'instock'}
    },
    {
      name: 'Ventilador',
      description: 'teste',
      price: 150,
      category: 'Eletrônicos',
      inventoryStatus: {label: 'FORA DE ESTOQUE', value: 'outofstock'}
    },
    {
      name: 'Jaqueta',
      description: 'teste',
      price: 120,
      category: 'Roupas',
      inventoryStatus: {label: 'ESTOQUE BAIXO', value: 'lowstock'}
    },
    {
      name: 'Pulseira',
      description: 'teste',
      price: 23.50,
      category: 'Acessórios',
      inventoryStatus: {label: 'EM ESTOQUE', value: 'instock'}
    },
    {
      name: 'Halter 2kg',
      description: 'teste',
      price: 34,
      category: 'Fitness',
      inventoryStatus: {label: 'ESTOQUE BAIXO', value: 'lowstock'}
    },
  ];
  product: any;
  selectedProducts: any[] = [];
  submitted: boolean = false;
  statuses: any[] = [];
  categorias: any[] = [];
  constructor(private messageService: MessageService, private confirmationService: ConfirmationService, private breadcrumbService: AppBreadcrumbService) {
    this.breadcrumbService.setItems([
        { label: 'Produtos', routerLink: ['/produtos'] }
    ]);
  }

  ngOnInit() {
    this.statuses = [
      {label: 'EM ESTOQUE', value: 'instock'},
      {label: 'ESTOQUE BAIXO', value: 'lowstock'},
      {label: 'FORA DE ESTOQUE', value: 'outofstock'}
    ];

    this.categorias = [
      'Acessórios',
      'Roupas',
      'Eletrônicos',
      'Fitness'
    ];
  }

  openNew() {
    this.product = {};
    this.submitted = false;
    this.productDialog = true;
  }

  deleteSelectedProducts() {
    this.confirmationService.confirm({
        message: 'Você tem certeza que deseja excluir estes produtos?',
        header: 'Comfirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => !this.selectedProducts.includes(val));
            this.selectedProducts = [];
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Products Deleted', life: 3000});
        }
    });
  }

  deleteProduct(product: any) {
    this.confirmationService.confirm({
        message: 'você tem certeza que deseja deletar ' + product.name + '?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.products = this.products.filter(val => val.id !== product.id);
            this.product = {};
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Produto deletado!', life: 3000});
        }
    });
  }

  editProduct(product: any) {
    this.product = {...product};
    this.productDialog = true;
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;

    if (this.product.name.trim()) {
        if (this.product.id) {
            this.products[this.findIndexById(this.product.id)] = this.product;
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Produto atualizado', life: 3000});
        }
        else {
            this.product.id = this.createId();
            this.products.push(this.product);
            this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Produto criado', life: 3000});
        }

        this.products = [...this.products];
        this.productDialog = false;
        this.product = {};
    }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
            index = i;
            break;
        }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
}
