import {Component, OnInit} from '@angular/core';

import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OrgaoService} from 'src/app/pages/escala/services/orgao.service';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RHVinculosServidoresService } from 'src/app/modules/scf/services/rhvinculos-servidores.service';
import { RHVinculoServidorFormFilter } from 'src/app/modules/scf/models/types/rhvinculos-servidor-form-filter.type';
import { PaginateType } from 'src/app/modules/scf-core/types/paginate.type';
import { RHVinculosServidor } from 'src/app/modules/scf/models/types/RHVinculosServidor';


@Component({
    selector: 'app-escala-revezamento',
    templateUrl: './escala-revezamento.component.html',
    styleUrls: ['./escala-revezamento.component.scss']
})
export class EscalaRevezamentoComponent implements OnInit {
    listaOrgaos: Orgao[];
    orgaoSelecionado: Orgao;
    servidoresSelecionados: any[];
    value: boolean;
    formFilter: RHVinculoServidorFormFilter = {
        cpf: null,
        orgaoId: null,
        orgaoOrigemId: null,
        situacaoFuncional: null,
        regimeJuridico: null,
        vincCodg: null
    };
    servidor = {
        id: '',
        unidade: '',
        nome: '',
        cpf: '',
        codVinculo: '',
        cargo: '',
        inicioRevezamento: ''
    };
    resultPaginated: PaginateType<RHVinculosServidor>;

    servidores: any[] = [
        {
            id: 1,
            unidade: 'Penitenciária Coronel Odenir Guimarães',
            nome: 'João Luis da Silva',
            cpf: '225.343.124-15',
            codVinculo: '0001526',
            cargo: 'Agente Penitenciário',
            inicioRevezamento: '10/10/2021'
        },
        {
            id: 2,
            unidade: 'Penitenciária Coronel Odenir Guimarães',
            nome: 'João Luis Oliveira',
            cpf: '145.346.789-13',
            codVinculo: '0008040',
            cargo: 'Agente de Segurança Prisional',
            inicioRevezamento: '08/03/2022'
        },
        {
            id: 2,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Santos',
            cpf: '351.424.715-26',
            codVinculo: '0009030',
            cargo: 'Médico Cardiologista',
            inicioRevezamento: '01/09/2023'
        },
        {
            id: 3,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Pereira',
            cpf: '141.346.720-18',
            codVinculo: '0004359',
            cargo: 'Enfermeiro',
            inicioRevezamento: '30/08/2023'
        },
        {
            id: 4,
            unidade: 'O Hospital de Urgências de Goiás',
            nome: 'João Luis Sousa',
            cpf: '804.202.395-81',
            codVinculo: '0002517',
            cargo: 'Fisioterapeuta',
            inicioRevezamento: '04/04/2022'
        },
    ];
    id: any;


    constructor(
        private serviceOrgao: OrgaoService,
        private messageService: MessageService, private confirmationService: ConfirmationService,
        private router: Router,
        private service: RHVinculosServidoresService,
        private routerParam: ActivatedRoute,
        private breadcrumbService: AppBreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Gestor', routerLink: ['#']}, {label: 'Escalas', routerLink: ['/escalas']}, {
                label: 'Escala-revezamento',
                routerLink: ['/escala-revezamento']
            }
        ]);

    }


    ngOnInit(): void {
        this.routerParam.paramMap.subscribe(params => {
            this.id = params.get('id');
        });
        this.getOrgaosAll();
        this.fetchSearch();
    }

    handleSearch() {
        this.fetchSearch();
    }

    fetchSearch(page = 0) {
        console.log('PESQUISOU', this.id, this.formFilter)
        // if (!this.id) {
        //     return;
        // }
        this.formFilter.orgaoId = 308;
        this.service
            .findAllPaginated(this.formFilter, page)
            .subscribe((response) => {
                this.resultPaginated = response;
                if (response.content.length == 0) {
        this.messageService.add({severity:'warn', summary:'Ops!', detail: "Não foram encontrados dados para os filtros pesquisados!"})
                }
            })
    }

    getOrgaosAll() {
        this.serviceOrgao.listar().subscribe((listaOrgaos) => {
            console.log(listaOrgaos);
            this.listaOrgaos = listaOrgaos.data;
            console.log(this.listaOrgaos, '===========');
        });
    }

    pesquisa() {

    }

    handleSubmit() {
        if (this.servidoresSelecionados && this.servidoresSelecionados.length > 0) {
            this.router.navigate([`/escalas`]);
            return;
        }

        this.messageService.add({severity:'warn', summary:'Ops!', detail: "Selecione ao menos 1 servidor!"})
        console.log(this.servidoresSelecionados, '---');
    }

}
