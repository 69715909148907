import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Fechamento} from "../models/fechamento.model";
import { FechamentoFormFilterDto } from 'src/app/modules/scf/models/dtos/fechamento-form-filter.dto';
import { PaginateType } from 'src/app/modules/scf-core/types/paginate.type';
import { DateUtil } from '../utils/date.util';

@Injectable({
    providedIn: 'root'
})
export class FechamentoService {
    public apiUrl = `${environment.apiUrl}/fechamento-frequencias`;
    headers: HttpHeaders;
    sharedData: Fechamento = null;

    constructor(
        private http: HttpClient,
        private dateUtil: DateUtil) {
    }

    // findAllPageable(orgao: number, referencia: string, dataFechamento: string, dataAgendamento: string, size: number, page: number): Observable<PageableFechamento> {
    //     const apiUrl = `${environment.apiUrl}/fechamentos`;
    //     let params = new URLSearchParams();

    //     this.appendParamIfTruthy(params, 'orgao', orgao);
    //     this.appendParamIfTruthy(params, 'referencia', referencia);
    //     this.appendParamIfTruthy(params, 'dataFechamento', dataFechamento);
    //     this.appendParamIfTruthy(params, 'dataAgendamento', dataAgendamento);
    //     this.appendParamIfTruthy(params, 'size', size);
    //     this.appendParamIfTruthy(params, 'page', page);

    //     return this.http.get<PageableFechamento>(`${apiUrl}?${params.toString()}`);
    // }

    findAllPaginated(filter: FechamentoFormFilterDto, page: number) {
        let filterTreated: any = {};
        filterTreated = {...filterTreated, ...filter, ...{page}};
        if (filter.dataFechamento) {
            filterTreated.dataFechamento = this.dateUtil.dateToAPI(filter.dataFechamento);
        }
        if (filter.anoMes) {
            filterTreated.anoMes = this.dateUtil.yearMonthToAPI(filter.anoMes);
        }

        console.log(filterTreated);

        const options = {params: this.addFilterToParams(filterTreated)}
        // const queryParams = new URLSearchParams({
        //     data: JSON.stringify(filter)
        // }).toString();
        // console.log('TESt', queryParams);
        return this.http.get<PaginateType<Fechamento>>(this.apiUrl, options);
    }

    save(fechamento: Fechamento) {
        return this.http.post(this.apiUrl, fechamento);
    }

    saveOne(fechamento: Fechamento) {
        const apiUrl = `${environment.apiUrl}/fechamentos`;
        this.http.post(apiUrl, fechamento);
    }

    edit(fechamento: Fechamento){
        const apiUrl = `${environment.apiUrl}/fechamentos`;
        this.http.put(apiUrl, fechamento);
    }

    private addFilterToParams(filter: object) {
        let params = new HttpParams();
        for (const key in filter) {
            if (filter[key] !== null && typeof filter[key] === 'object') {
                params = params.set(key, JSON.stringify(filter[key]));
                continue;
            }
            if (filter[key]) params = params.set(key, filter[key]);
        }
        return params;
    }

    // private appendParamIfTruthy(params: URLSearchParams, key: string, value: string | number) {
    //     if (value) {
    //         params.append(key, value.toString());
    //     }
    // }
}
