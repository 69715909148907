import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SituacaoFuncional } from '../../models/servidor/situacao.funcional';

@Injectable({
  providedIn: 'root'
})
export class SituacaoFuncionalService {

  constructor(private http: HttpClient) { }

  listAll(): Observable<SituacaoFuncional[]> {
    const apiUrl = `${environment.apiUrl}/rhnet/situacoes-funcionais`;
    return this.http.get<SituacaoFuncional[]>(apiUrl).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error('Erro na requisição:', error);
    return throwError(error);
  }
}
