<p-toast></p-toast>
<p-dialog
    [(visible)]="isShowModal"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
>
    <ng-template pTemplate="header"> Registros do dia </ng-template>
    <div class="div-fields">
        <p-table
            styleClass="p-datatable-striped p-datatable-sm"
            [lazy]="true"
            [paginator]="false"
            [rowHover]="true"
            [totalRecords]="totalElements"
            (onPage)="onPageChange($event)"
        >
            <!-- [value]="orgaos" -->
            <!-- [rows]="10" -->
            <ng-template pTemplate="header">
                <tr>
                    <th>Unidade</th>
                    <th>Dia da semana</th>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>Marcação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orgao>
                <tr>
                    <td>{{ orgao?.organizacaoAdministrativa?.nome }}</td>
                    <td>{{ orgao?.dtCriacao }}</td>
                    <td>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-check-circle"
                            pTooltip="Inativar"
                            tooltipPosition="top"
                            (click)="changeStatus(orgao)"
                        ></button>
                        <button
                            pButton
                            pRipple
                            type="button"
                            class="p-button-text p-button-rounded"
                            icon="pi pi-search"
                            pTooltip="Visualizar"
                            tooltipPosition="top"
                            (click)="show(orgao)"
                        ></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template pTemplate="footer">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Fechar"
            class="p-button-text"
            (click)="handlerShowList()"
        ></button>
    </ng-template>
</p-dialog>
<div class="card">
    <h3>Olá, Registre seu ponto!</h3>
    <!-- <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Adicionar"
            (click)="handlerSave()"
        ></button>
    </div> -->

    <div class="grid">
        <div class="col-12 flex justify-content-center">
            <div
                class="col-4 flex justify-content-center text-primary text-4xl"
            >
                {{ timeNow }}
            </div>
        </div>
        <div class="col-12 flex justify-content-center">
            <div class="col-4 flex justify-content-center">
                {{ dateNow }} ({{ dateWeak }})
            </div>
        </div>
    </div>

    <form [formGroup]="formSave" (ngSubmit)="onSubmit()">
        <div class="div-fields">
            <div class="formgrid grid">
                <div class="flex justify-content-center col-12">
                    <div class="field col-4 div-item">
                        <label><b>CPF</b></label>
                        <p-inputMask
                            aria-required="true"
                            styleClass="w-full"
                            formControlName="cpf"
                            [unmask]="true"
                            mask="999.999.999-99"
                            placeholder="Digite aqui o seu CPF"
                            name="cpf"
                            id="cpf"
                            class="w-full"
                        ></p-inputMask>
                    </div>
                </div>
                <div class="flex justify-content-center col-12">
                    <div class="field col-4 div-item">
                        <label><b>Senha</b></label>
                        <input
                            aria-required="true"
                            formControlName="senha"
                            placeholder="Digite aqui a sua senha"
                            type="password"
                            pInputText
                            class="w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="grid flex justify-content-center col-12">
            <div class="col-2 flex justify-content-end gap-2">
                <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="button"
                    label="Registros do dia"
                    class="p-button-text"
                    (click)="handlerShowList()"
                ></button>
            </div>
            <div class="col-2 flex justify-content-start gap-2">
                <!-- <button
                    iconPos="right"
                    pButton
                    pRipple
                    type="submit"
                    label="Registrar"
                ></button> -->
                <button type="submit" pButton pRipple>Registrar</button>
            </div>
        </div>
    </form>

    <!-- <div class="flex justify-content-end gap-2">
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Limpar"
            class="p-button-text"
            (click)="clear()"
        ></button>
        <button
            iconPos="right"
            pButton
            pRipple
            type="button"
            label="Consultar"
            (click)="fetchOrgaos()"
        ></button>
    </div> -->
    <!-- <p-divider></p-divider> -->
</div>
