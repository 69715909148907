import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Jornada} from '../interfaces/jornada';
import {environment} from '../../../../environments/environment';
import {PageableJornada} from '../models/PageableJornada';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JornadaTrabalhoService {

    sharedData: any;
    apiUrl = `${environment.apiUrl}/rhnet/jornadas`;

    constructor(private http: HttpClient) {
    }

    private handleError(error: any) {
        console.error('Erro na requisição:', error);
        return throwError(error);
    }


    listarJornadasPorEscala(): Observable<Jornada[]> {
        return this.http.get<Jornada[]>(this.apiUrl);
    }


    listarIntervalosPorEscala(page: number): Observable<PageableJornada> {
        console.log(`${this.apiUrl}?page=${page}`);
        return this.http.get<PageableJornada>(`${this.apiUrl}?page=${page}`).pipe(
            catchError(this.handleError));
    }
}
