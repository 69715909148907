import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { TipoEscala } from 'src/app/pages/escala/interfaces/tipoEscala';
import { ResponseRequest } from '../../core/models/ResponseRequest';

@Injectable({
    providedIn: 'root'
})
export class TiposEscalasService {
    public apiUrl = `${environment.apiUrl}/escalas/tipos`;

    constructor(
        private http: HttpClient) {
    }

    findAll() {
        return this.http.get<ResponseRequest<TipoEscala[]>>(this.apiUrl);
    }
}
