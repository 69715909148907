import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Situacao} from '../interfaces/situacao';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SituacaoService {

    constructor(private http: HttpClient) {
    }

    listar(): Observable<Situacao[]> {
        return this.http.get<Situacao[]>('/assets/data/escala/situacao.json');
    }


}
