import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from "@angular/core";

import {
    HashLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";

import { FullCalendarComponent, FullCalendarModule } from "@fullcalendar/angular";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

import { AppComponent } from "./app.component";
import { AppConfigComponent } from "./modules/main/app.config.component";
import { AppMainComponent } from "./modules/main/app.main.component";
import { AppBreadcrumbComponent } from "./modules/main/breadcrumb/app.breadcrumb.component";
import { AppFooterComponent } from "./modules/main/footer/app.footer.component";
import { AppMenuComponent } from "./modules/main/menu-sidebar/app.menu.component";
import { AppMenuitemComponent } from "./modules/main/menu-sidebar/app.menuitem.component";
import { AppTopBarComponent } from "./modules/main/topbar/app.topbar.component";
import { AppAccessdeniedComponent } from "./utilities/app.accessdenied.component";
import { AppErrorComponent } from "./utilities/app.error.component";
import { AppLoginComponent } from "./utilities/app.login.component";
import { AppNotfoundComponent } from "./utilities/app.notfound.component";
import { IconsComponent } from "./utilities/icons.component";

import { ConfirmationService, MessageService } from "primeng/api";
import { AppBreadcrumbService } from "./modules/main/breadcrumb/app.breadcrumb.service";
import { MenuService } from "./modules/main/menu-sidebar/app.menu.service";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { InputComponent } from "./components/input/input.component";
import { ClienteAddComponent } from "./pages/cliente/cliente-add/cliente-add.component";
import { ClienteEditComponent } from "./pages/cliente/cliente-edit/cliente-edit.component";
import { ClienteListComponent } from "./pages/cliente/cliente-list/cliente-list.component";
import { CrudProdutosComponent } from "./pages/crud-produtos/crud-produtos.component";
import { CrudUsuariosListComponent } from "./pages/crud-usuarios/crud-usuarios-list/crud-usuarios-list.component";
import { CrudUsuariosRegisterComponent } from "./pages/crud-usuarios/crud-usuarios-register/crud-usuarios-register.component";
import { AppCodeModule } from "./utilities/app.code.component";

import localePt from "@angular/common/locales/pt";
import { OAuthModule } from "angular-oauth2-oidc";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxPermissionsModule } from "ngx-permissions";
import { environment } from "../environments/environment";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { EscalaConsultarComponent } from "./pages/escala/escala-consultar/escala-consultar.component";
import { EscalaCreateComponent } from "./pages/escala/escala-create/escala-create.component";
import { EscalaListComponent } from "./pages/escala/escala-list/escala-list.component";
import { PerfilComponent } from "./pages/perfil/perfil.component";
import { EstagiarioDetailComponent } from "./pages/servidores/estagiario-detail/estagiario-detail.component";
import { ServidorDetailComponent } from "./pages/servidores/servidor-detail/servidor-detail.component";
import { ServidoresListComponent } from "./pages/servidores/servidores-list/servidores-list.component";
import { OrgaosListComponent } from "./pages/orgaos/orgaos-list/orgaos-list.component";
import { EscalaDiariaComponent } from "./pages/vinculo-escala/escala-diaria/escala-diaria.component";
import { EscalaRevezamentoComponent } from "./pages/vinculo-escala/escala-revezamento/escala-revezamento.component";
import { EscalaTeleatendimentoComponent } from "./pages/vinculo-escala/escala-teleatendimento/escala-teleatendimento.component";
import { EscalaEditarComponent } from './pages/escala/escala-editar/escala-editar.component';
import { FechamentosListComponent } from './pages/fechamentos/fechamentos-list/fechamentos-list.component';
import { FechamentoSaveComponent } from './pages/fechamentos/fechamento-save/fechamento-save.component';
import { EspelhoDePontoComponent } from './pages/espelho-de-ponto/espelho-de-ponto.component';
import { PontoSaveComponent } from "./pages/registrar-ponto/ponto-save/ponto-save.component";
import { PontoListComponent } from "./pages/registrar-ponto/ponto-list/ponto-list.component";
import { EscalaSaveComponent } from "./pages/escala/escala-save/escala-save.component";
import { EscalaShowComponent } from "./pages/escala/escala-show/escala-show.component";

const maskConfig: Partial<IConfig> = {
    validation: false,
};

registerLocaleData(localePt);

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
]);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        ImageModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [
                    environment.apiUrl,
                    environment.apiPortalAcessos,
                    environment.apiPortalMenusSistemas,
                    environment.apiRhVinculos,
                ],
                sendAccessToken: true,
            },
        }),
        NgxMaskModule.forRoot(maskConfig),
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        IconsComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        CrudProdutosComponent,
        ClienteListComponent,
        ClienteEditComponent,
        ClienteAddComponent,
        InputComponent,
        CrudUsuariosListComponent,
        CrudUsuariosRegisterComponent,
        DashboardComponent,
        PerfilComponent,
        EscalaListComponent,
        EscalaCreateComponent,
        EscalaConsultarComponent,
        EscalaDiariaComponent,
        EscalaRevezamentoComponent,
        EscalaTeleatendimentoComponent,
        PerfilComponent,
        ServidoresListComponent,
        OrgaosListComponent,
        ServidorDetailComponent,
        EstagiarioDetailComponent,
        EscalaEditarComponent,
        FechamentosListComponent,
        FechamentoSaveComponent,
        PontoSaveComponent,
        PontoListComponent,
        EscalaSaveComponent,
        EscalaShowComponent,
        // EspelhoDePontoComponent,
        // FullCalendarComponent,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        MenuService,
        AppBreadcrumbService,
        ConfirmationService,
        MessageService,
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: "BRL",
        },
        {
            provide: LOCALE_ID,
            useValue: "pt-BR",
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
