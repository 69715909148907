<p-toast></p-toast>

<p-card>
  <!-- <div class="card-header">
    <h3 class="card-title">Lista de Clientes</h3>
  </div> -->
  <p-toolbar>
    <ng-template pTemplate="right">
      <button pButton pRipple label="Novo" icon="pi pi-plus" class="p-button-success mr-2" (click)="addCliente()"></button>
    </ng-template>
  </p-toolbar>
  <p-table #dt [value]="getContent()" (sortFunction)="changeOrder($event)" [paginator]="true" [lazy]="true" (onLazyLoad)="loadClientes($event)" [tableStyle]="{'min-width': '75rem'}" [loading]="loadingCliente"
    currentPageReportTemplate="Montrando {first} a {last} de {totalRecords} reguistros" [totalRecords]="totalElements" [rows]="10" [showCurrentPageReport]="true" [rowHover]="true">
    <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th pSortableColumn="id">ID<p-sortIcon field="ID"></p-sortIcon></th>
          <th pSortableColumn="nome">Nome<p-sortIcon field="nome"></p-sortIcon></th>
          <th pSortableColumn="cpf">CPF<p-sortIcon field="cpf"></p-sortIcon></th>
          <th pSortableColumn="telefone">Telefone<p-sortIcon field="telefone"></p-sortIcon></th>
          <th pSortableColumn="endereco">Endereço<p-sortIcon field="endereco"></p-sortIcon></th>
          <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-cliente>
        <tr>
          <td>{{cliente.id}}</td>
          <td>{{cliente.nome}}</td>
          <td>{{cliente.cpf}}</td>
          <td>{{cliente.telefone}}</td>
          <td>{{cliente.endereco}}</td>
          <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" (click)="editCliente(cliente)"></button>
              <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteCliente(cliente)"></button>
          </td>
        </tr>
    </ng-template>
  </p-table>
  <br>

</p-card>
