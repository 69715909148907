import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TipoEscala} from '../interfaces/tipoEscala';

@Injectable({
    providedIn: 'root'
})
export class TipoService {

    constructor(private http: HttpClient) {
    }

    listar(): Observable<TipoEscala[]> {
        return this.http.get<TipoEscala[]>('/assets/data/escala/tipoescala.json');
    }

}
