import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Orgao } from '../models/orgao';
import { ResponseRequest } from '../../core/models/ResponseRequest';

@Injectable({
  providedIn: 'root'
})
export class OrgaoService {

  constructor(private http: HttpClient) { }

  listAll(): Observable<ResponseRequest<Orgao[]>> {
    const apiUrl = `${environment.apiUrl}/wso2/corporativo/orgaos`;
    return this.http.get<ResponseRequest<Orgao[]>>(apiUrl).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error('Erro na requisição:', error);
    return throwError(error);
  }
}
