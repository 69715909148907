import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegimeJuridico } from '../../models/servidor/regime.juridico';

@Injectable({
  providedIn: 'root'
})
export class RegimeJuridicoService {

  constructor(private http: HttpClient) { }

  listAll(): Observable<RegimeJuridico[]> {
    const apiUrl = `${environment.apiUrl}/rhnet/regimes-juridicos`;
    return this.http.get<RegimeJuridico[]>(apiUrl).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    console.error('Erro na requisição:', error);
    return throwError(error);
  }
}
