import {Jornada} from 'src/app/pages/escala/interfaces/jornada';
import {Component, OnInit} from '@angular/core';
import {Orgao} from 'src/app/pages/escala/interfaces/orgao';
import {EscalaService} from '../../../modules/scf/services/escala.service';
import {SituacaoService} from './../services/situacao.service';
import {TipoService} from './../services/tipo.service';
import {UnidadeService} from '../services/unidade.service';
import {OrgaoService} from '../services/orgao.service';
import {Unidade} from 'src/app/pages/escala/interfaces/unidade';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import {Escala} from '../interfaces/escala';
import {TipoEscala} from '../interfaces/tipoEscala';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {IntervaloJornada} from '../interfaces/intervaloJornada';


@Component({
    selector: 'app-escala-create',
    templateUrl: './escala-create.component.html',
    styleUrls: ['./escala-create.component.scss']
})
export class EscalaCreateComponent implements OnInit {

    listaOrgaos: Orgao[];
    listaUnidades: Unidade[];
    unidade: Unidade;
    TipoAux: TipoEscala;
    tiposEscala: TipoEscala[];
    idOrgao: '';
    escala: Escala = {
        id: null,
        descEscalaTrabalho: '',
        dataInicioVigencia: new Date(10 / 10 / 2023),
        indiHorarioFlexivel: '',
        indiBancoDeHoras: '',
        indiEscalaContinua: '',
        qtdeHorajornadaDiaria: 0,
        qtdeHorajornadaSemanal: 0,
        qtdeHorajornadaMensal: 0,
        status: '',
        descStatJustificativa: '',
        tipoEscala: null,
        idRhJornadaTrabalhoMes: 0,
        idRhJornadaTrabalhoSemana: 0,
        dataCriacao: new Date(10 / 10 / 2023),
        dataAtualizacao: new Date(10 / 10 / 2023),
        unidade: {
            id: 1,
            tipoAdministracao: ''
        },
        organizacaoAdministrativa: {
            id: 1,
            nome: '',
            sigla: '',
            unidades: null,
            unidade: {
                id: 1,
                tipoAdministracao: ''
            },
            dataCriacao: new Date(10 / 10 / 2023),
            dataAtualizacao: new Date(10 / 10 / 2023),
        }
    };

    jornada: Jornada = {
        id: null,
        horaEntrada: null,
        horaSaida: null,
        horaIntervaloEntrada1: null,
        horaIntervaloSaida1: null,
        horaIntervaloEntrada2: null,
        horaIntervaloSaida2: null,
        horaIntervaloEntrada3: null,
        horaIntervaloSaida3: null,
        numeroDiaSemana: 0,
        quantidadeHoras: 0,
        quantidadeJornada: 0,
        descricaoDiaSemana: '',
        quantidadeIntervalos: 0,
        status: '',
        descricaoJustificativa: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null
    };

    listaMinutos: any[] = [];
    listaPrimeirosIntervalos: any[] = [];
    listaSegundosIntervalos: any[] = [];


    intervalo: IntervaloJornada = {
        id: null,
        numeroMinutosIntervalo: '',
        numeroInicioIntervalo: '',
        numeroFimIntervalo: '',
        escala: null,
        dataCriacao: null,
        dataAtualizacao: null

    };

    dadosEscalaDiaria: boolean = false;

    diasSelecionadosJornada: number[] = [];
    mostraBotoesDeAdicaoDeJornada: boolean = false;
    mostraBotoesDeIntervalos: boolean = false;

    adicionaJornada: boolean = false;
    adicionaJornadaRevezamento: boolean = false;
    adicionaJornadaTeleatendimento: boolean = false;
    adicionaIntervalo: boolean = false;


    mostraCamposDiaria: boolean = false;
    mostraCamposReveza: boolean = false;

    flexibilidades: any[] = [];

    constructor(private serviceOrgao: OrgaoService, private serviceUnidade: UnidadeService,
                private serviceSituacao: SituacaoService, private serviceTipo: TipoService,
                private serviceEscala: EscalaService, private breadcrumbService: AppBreadcrumbService,
                private messageService: MessageService,
                private router: Router,) {
        this.breadcrumbService.setItems([
            {label: 'Listar Escalas', routerLink: ['/escalas']},
            {label: 'Criar Escalas'},

        ]);
    }

    ngOnInit(): void {
        // this.getFlexibilidades();
        this.getOrgaosAll();
        // this.getiposEscala();
    }

    criarEscala() {
        // this.serviceEscala.save(this.escala).subscribe();
        // this.router.navigate([`/escalas`]);
    }

    getFlexibilidades() {
        return this.flexibilidades = ['Sim', 'Não'];
    }

    getOrgaosAll() {
        this.serviceOrgao.listar().subscribe((listaOrgaos) => {
            this.listaOrgaos = listaOrgaos.data;
            console.log(`-------`, this.listaOrgaos);
        });
    }

    getiposEscala() {
        this.serviceTipo.listar().subscribe((tiposEscala) => {
            this.tiposEscala = tiposEscala;
        });
    }


    getUnidadesPorOrgao(idOrgao: any) {
        this.serviceUnidade.listar().subscribe((listaUnidades) => {
            this.listaUnidades = listaUnidades.filter(unidade => unidade.orgao.id == idOrgao);
        });
    }


    editarEscala(escala: Escala) {
        this.escala = escala;
        this.router.navigate([`/escalas`]);
    }


    abrirDialogJornada() {
        if (this.TipoAux.id == 1) {
            this.adicionaJornada = true;
        } else if (this.TipoAux.id == 2) {
            this.adicionaJornadaRevezamento = true;
        } else {
            this.adicionaJornadaTeleatendimento = true;
        }
    }

    abrirDialogIntervalos() {
        this.listaMinutos = [
            '10 minutos',
            '20 minutos'
        ];

        this.listaPrimeirosIntervalos = [
            '1ª Hora',
            '2ª Hora',
            '3ª Hora',
            '4ª Hora'
        ];

        this.listaSegundosIntervalos = [
            '1ª Hora',
            '2ª Hora',
            '3ª Hora',
            '4ª Hora'
        ];
        this.adicionaIntervalo = true;
    }


    getExibeBotoesInserirJornada(idTipo: TipoEscala) {

        this.TipoAux = idTipo;
        if ((idTipo.id == 1) || (idTipo.id == 3)) {
            this.mostraBotoesDeAdicaoDeJornada = true;
            this.mostraBotoesDeIntervalos = true;

        } else {
            this.mostraBotoesDeAdicaoDeJornada = true;
            this.mostraBotoesDeIntervalos = false;
        }
    }

}
