import { Component, OnInit } from "@angular/core";
import { AppBreadcrumbService } from "src/app/modules/main/breadcrumb/app.breadcrumb.service";
import { Estagiario } from "src/app/shared/models/servidor/estagiario.model";
import { ServidorService } from "src/app/shared/services/servidor/servidor.service";

@Component({
    selector: "app-estagiario-detail",
    templateUrl: "./estagiario-detail.component.html",
    styleUrls: ["./estagiario-detail.component.scss"],
})
export class EstagiarioDetailComponent implements OnInit {
    estagiario: Estagiario;

    constructor(
        private servidorService: ServidorService,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.estagiario = this.servidorService.sharedData;
        this.breadcrumbService.setItems([
            { label: "Servidores", routerLink: ["/servidores"] },
            { label: this.estagiario.nome },
        ]);
    }

    ngOnInit(): void {}
}
