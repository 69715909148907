import {Component, OnInit} from "@angular/core";
import {AppComponent} from "../../../app.component";
import {PortalService} from "../../core/services/portal/portal.service";

@Component({
    selector: "app-menu",
    template: `
        <ul class="layout-menu">
            <li
                app-menuitem
                *ngFor="let item of model; let i = index"
                [item]="item"
                [index]="i"
                [root]="true"
            ></li>
        </ul>
    `,
})
export class AppMenuComponent implements OnInit {
    model: any[];

    constructor(
        public app: AppComponent,
        private portalService: PortalService
    ) {
    }

    ngOnInit() {
        this.model = [
            {
                label: "",
                items: [
                    {
                        label: "Dashboard",
                        icon: "pi pi-fw pi-home",
                        routerLink: ["/dashboard"],
                    },
                ],
            },
            {
                label: "Espelho de ponto",
                items: [
                    {
                        label: "Registrar ponto",
                        icon: "pi pi-fw pi-clock",
                        routerLink: ["/registrar-ponto"],
                    },
                ],
            },
            {
                label: "Gestor",
                icon: "pi pi-fw pi-home",
                routerLink: ["/"],
                items: [
                    {
                        label: "Fechamentos",
                        icon: "pi pi-fw pi-calendar",
                        routerLink: ["/fechamentos"],
                    },
                ],
            }
            ,
            /*{
                label: "Registrar Ponto",
                icon: "pi pi-fw pi-download",
                items: [
                    {
                        label: "Incluir Ponto",
                        icon: "pi pi-fw pi-home",
                        routerLink: ["/produtos"],
                    },
                ],
            },*/
            /*{
                label: "Controlar Ponto",
                icon: "pi pi-fw pi-download",
                items: [
                    {
                        label: "Servidor",
                        icon: "pi pi-fw pi-home",
                        routerLink: ["/"],
                        items: [
                            {
                                label: "Espelho do Ponto",
                                icon: "pi pi-fw pi-home",
                                routerLink: ["/espelho-de-ponto"],
                            },
                            {
                                label: "Registrar Ocorrência",
                                icon: "pi pi-shopping-bag",
                                routerLink: ["/usuarios-list"],
                            },
                        ],
                    },
                    {
                        label: "Gestor",
                        icon: "pi pi-fw pi-home",
                        routerLink: ["/"],
                        items: [
                            {
                                label: "Servidores",
                                icon: "pi pi-shopping-bag",
                                routerLink: ["/servidores"],
                            },
                            {
                                label: "Valida Ocorrência",
                                icon: "pi pi-fw pi-home",
                                routerLink: ["/produtos"],
                            },
                            {
                                label: "Fecha Ponto",
                                icon: "pi pi-shopping-bag",
                                routerLink: ["/usuarios-list"],
                            },
                            {
                                label: "Atribuir",
                                icon: "pi pi-shopping-bag",
                                items: [
                                    {
                                        label: "Jornanda",
                                        icon: "pi pi-fw pi-home",
                                        routerLink: ["/produtos"],
                                    },
                                    {
                                        label: "Escala",
                                        icon: "pi pi-fw pi-home",
                                        routerLink: ["/clientes"],
                                    },
                                    {
                                        label: "Revezamento",
                                        icon: "pi pi-fw pi-home",
                                        routerLink: ["/input"],
                                    },
                                    {
                                        label: "Plantões",
                                        icon: "pi pi-fw pi-home",
                                        routerLink: ["/produtos"],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },*/
            {
                label: "Servidor",
                icon: "pi pi-fw pi-download",
                items: [
                    {
                        label: "Servidores",
                        icon: "pi pi-fw pi-user",
                        routerLink: ["/servidores"],
                    },
                    {
                        label: "Escalas",
                        icon: "pi pi-fw pi-calendar",
                        routerLink: ["/escalas"],
                    }
                ]
            },
            {
                label: "Administrar Sistema",
                icon: "pi pi-fw pi-download",
                items: [
                    {
                        label: "Orgãos",
                        icon: "pi pi-fw pi-building",
                        routerLink: ["/orgaos"],
                    }
                    // {
                    //     label: "Gestor",
                    //     icon: "pi pi-fw pi-home",
                    //     routerLink: ["/"],
                    //     items: [
                    //         {
                    //             label: "Usuários",
                    //             icon: "pi pi-user",
                    //             routerLink: ["/usuarios-list"],
                    //         },
                    //     ],
                    // }
                    /*,
                    {
                        label: "Documentação Primeng",
                        icon: "pi pi-fw pi-info-circle",
                        url: ["https://www.primefaces.org/primeng/setup"],
                        target: "_blank",
                    },*/,
                ],
            },
        ];
    }
}
