import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, ReplaySubject } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';
import { CrudUsuariosService } from '../crud-usuarios.service';
import { AppBreadcrumbService } from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';

@Component({
  selector: 'app-crud-usuarios-register',
  templateUrl: './crud-usuarios-register.component.html',
  styleUrls: ['./crud-usuarios-register.component.scss']
})
export class CrudUsuariosRegisterComponent implements OnInit {
  skills: any = [
    'Angular',
    'Vue',
    'React',
    'Flutter',
    'Java',
    'Ionic',
    'Python',
    'Kotlin',
    'SQL',
    'Scrum',
    'React Native',
    'Spring',
    'HTML',
    'Javascript',
    'Node',
    'Oracle',
    'PHP',
  ];
  dados: any = {}
  userForm: FormGroup = new FormGroup({});
  arquivo: string = '';
  arquivoFile: File;
  user: any;

  @ViewChild('fileUpload') fileUpload: FileUpload;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private location: Location,
    private routerParam: ActivatedRoute,
    private crudUsuariosService: CrudUsuariosService,
    private breadcrumbService: AppBreadcrumbService
  ) {
    this.breadcrumbService.setItems([
        { label: 'Usuários', routerLink: ['/usuarios-list']},
        { label: 'Cadastrar Usuário', routerLink: ['/usuarios-register'] }
    ]);

    this.userForm = this.formBuilder.group({
      usuario: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
      senha: new FormControl("", [Validators.required]),
      cpf: new FormControl("", [Validators.required]),
      tel: new FormControl("", [Validators.required]),
      data: new FormControl("", [Validators.required]),
      genero: new FormControl("", [Validators.required]),
      habilidades: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    let id: string = this.routerParam.snapshot.params['id'];

    if(id){
      this.crudUsuariosService.getUserById(id).then((user: any) =>{
        this.user = user;
        this.userForm.patchValue(user)
      })
    }
  }

  ngAfterViewInit() {
    let id: string = this.routerParam.snapshot.params['id'];

    if(id){
      this.crudUsuariosService.getUserById(id).then((user: any) =>{
        this.arquivo = this.user.arquivo;
        if(this.user.arquivo){
          this.fileUpload.files = [new File( [this.dataURItoBlob(this.user.arquivo)], this.user.arquivoNome || 'empty.pdf', {type: 'application/pdf'} )]
          this.arquivoFile = this.fileUpload.files[0]
          this.fileUpload.cd.detectChanges();
        }
      })
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));

    return result;
  }

  onUpload(event: any) {
    this.convertFile(event.currentFiles[0]).subscribe(res => {
      this.arquivo = res
      this.arquivoFile = event.currentFiles[0]
    })
  }

  salvar(){
    if(this.userForm.valid){
      let obj: any = Object.assign(this.userForm.value)
      let id: string = this.routerParam.snapshot.params['id'];

      if(id){
        obj.id = id;
        obj.arquivo = this.arquivo ? this.arquivo : '';
        obj.arquivoNome = this.arquivo ? this.arquivoFile.name : '';

        this.crudUsuariosService.editUser(obj).then( res => {
          this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Usuario salvo com sucesso', life: 3000});
          this.router.navigate([`usuarios-list`]);
        })
      }else{
        obj.id = this.createId();
        obj.arquivo = this.arquivo ? this.arquivo : '';
        obj.arquivoNome = this.arquivo ? this.arquivoFile.name : '';

        this.crudUsuariosService.saveUser(obj).then( res => {
          this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Usuario salvo com sucesso', life: 3000});
          this.router.navigate([`usuarios-list`]);
        })
      }
    }else{
      this.messageService.add({severity:'error', summary: 'Info', detail: 'Dados obrigatórios', life: 3000});
    }
  }

  voltar(){
    this.location.back();
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
}
