import {Component, OnInit} from '@angular/core';
import {Orgao} from "../../../modules/corporativo/models/orgao";
import {OrgaoService} from "../../../modules/corporativo/services/orgao.service";
import { Router } from '@angular/router';
import { FechamentoFormSave } from 'src/app/modules/scf/models/types/fechamento-form-save.type';
import { Fechamento } from 'src/app/modules/scf/models/fechamento.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrgaosService } from 'src/app/modules/scf/services/orgaos.service';
import { MessageService } from 'primeng/api';
import { FechamentoService } from 'src/app/modules/scf/services/fechamento.service';
import { first } from 'rxjs';
import { DateUtil } from 'src/app/modules/scf/utils/date.util';
import { Messages } from 'src/app/modules/scf-core/constants/messages';

@Component({
    selector: 'app-fechamento-save',
    templateUrl: './fechamento-save.component.html',
    styleUrls: ['./fechamento-save.component.scss']
})
export class FechamentoSaveComponent implements OnInit {
    formFilterOrgaoAutocomplete = null;
    autoCompleteOrgaos: object[];
    formSave: FormGroup;
    id: number;

    constructor(
        private service: FechamentoService,
        private router: Router,
        private formBuilder: FormBuilder,
        private orgaosService: OrgaosService,
        private messageService: MessageService,
        private dateUtil: DateUtil
    ) {
    }

    ngOnInit(): void {
        this.formSave = this.formBuilder.group({
            orgao: ['', Validators.required],
            // status: ['', Validators.required],
            anoMes: ['', Validators.required],
            dataFechamento: ['', Validators.required],
            justificativa: ['', Validators.minLength(50)],
        });

        if (this.service.sharedData) {
            this.id = this.service.sharedData.id;
            this.populateForm(this.service.sharedData);
        }
    }

    populateForm(data: any): void {
        let customData = data;
        customData.dataFechamento = this.dateUtil.dateToView(customData.dataFechamento)
        customData.orgao = {
            name: data.orgao.organizacaoAdministrativa.nome,
            code: data.orgao.id,
        }
        console.log(this.service.sharedData, 'aqui ----', customData, this.formFilterOrgaoAutocomplete)
        this.formSave.patchValue(customData);
    }

    onChangeOrgaoAutocomplete(event) {
        if (event.query.length >= 3) {
            this.fetchOrgaosByTagOrName(event.query);
        }
    }

    fetchOrgaosByTagOrName(nome: string) {
        this.orgaosService.findAllByTagOrName({
            nome: nome,
            status: 'A'
        }).subscribe(response => {
            const results = response.content.map(item =>
                ({
                    name: item.organizacaoAdministrativa.nome,
                    code: item.id
                })
            )
            this.autoCompleteOrgaos = results;
        });
    }

    handleCancel(): void{
        this.router.navigate(['/fechamentos']);
    }

    handleSubmit(): void{
        let dataForm = this.formSave.getRawValue();
        if (!this.formSave.dirty || !this.formSave.valid) {
            if (!this.id || !dataForm.orgao) {
                this.messageService.add({severity:'warn', summary: 'Ops!', detail: Messages.alert.required, life: 3000});
                return;
            }
        }
        dataForm.orgao = {
            id: dataForm.orgao.code
        }
        dataForm.anoMes = dataForm.anoMes.replace('/', '');
        dataForm.dataFechamento = this.dateUtil.dateTimeToAPI(dataForm.dataFechamento);
        dataForm.status = 'A';
        if (this.id) {
            dataForm.id = this.id;
        }
        this.service.save(dataForm)
            .pipe(first())
            .subscribe(data => {
                console.log('voltou', data)
                // if (data != null) {
                    this.messageService.add({severity:'success', summary: 'Sucesso', detail: 'Fechamento cadastrado com sucesso!!!', life: 3000});
                    // setTimeout(() => {
                        this.router.navigate(['fechamentos']);
                    // }, 500)

                // } else {
                // alert(data);
                // }
            },
            error => {
                this.messageService.add({severity:'error', summary: 'Erro', detail: Messages.error.service, life: 3000});
            });
            // this.messageService.add({severity:'error', summary: 'Ops!', detail: 'CPF ou Senha inválida!', life: 3000});

    }
}
