<div class="card">
    <div class="grid">
        <div class="col-12 lg:col-2">
            <div class="text-900 font-medium text-xl mb-3">Perfil</div>
            <p class="m-0 p-0 text-600 line-height-3 mr-3">Visualize todas as informações do seu perfil.</p>
        </div>
        <div class="col-12 lg:col-10">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12 flex justify-content-center">
                    <p-avatar icon="pi pi-user" alt="avatar" size="xlarge" shape="circle"></p-avatar>
                </div>
                <div class="field mb-4 col-12">
                    <label for="nickname" class="font-medium text-900">Usuário</label>
                    <input id="nickname" type="text" pInputText value="{{user?.user}}" [disabled]="true">
                </div>
                <div class="field mb-4 col-12">
                    <label for="name" class="font-medium text-900">Nome</label>
                    <input id="name" type="text" pInputText value="{{user?.name}}" [disabled]="true">
                </div>
                <div class="field mb-4 col-12">
                    <label for="cpf" class="font-medium text-900">CPF</label>
                    <p-inputMask id="cpf" [ngModel]="user?.cpf" mask="999.999.999-99" [disabled]="true"></p-inputMask>
                </div>
                <!-- <div class="field mb-4 col-12">
                    <label for="avatar" class="font-medium text-900">Avatar</label>
                    <p-fileUpload mode="basic" name="avatar" accept="image/*"
                        styleclass="p-button-outlined p-button-plain" chooselabel="Upload Imagem">
                    </p-fileUpload>
                </div> -->
                <div class="field mb-4 col-12">
                    <label for="bio" class="font-medium text-900">Bio</label>
                    <textarea id="bio" type="text" pInputTextarea rows="5" class="p-inputtextarea-resizable"
                        style="height: 104px; overflow: hidden;" [disabled]="true"></textarea>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="email" class="font-medium text-900">Email</label>
                    <input id="email" type="text" pInputText value="{{user?.email}}" [disabled]="true">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="country" class="font-medium text-900">País</label>
                    <p-dropdown inputid="country" optionlabel="name" filterby="name" placeholder="Selecione um País" class="p-inputwrapper" [disabled]="true"></p-dropdown>
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="city" class="font-medium text-900">Cidade</label>
                    <input id="city" type="text" pInputText [disabled]="true">
                </div>
                <div class="field mb-4 col-12 md:col-6">
                    <label for="state" class="font-medium text-900">Estado</label>
                    <input id="state" type="text" pInputText [disabled]="true">
                </div>
                <div class="field mb-4 col-12">
                    <label for="website" class="font-medium text-900">Site</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">www</span>
                        <input id="website" type="text" pInputText [disabled]="true">
                    </div>
                </div>
                <!-- <div class="col-12">
                    <button pButton pRipple label="Criar Usuário" class="w-auto mt-3"></button>
                </div> -->
            </div>
        </div>
    </div>
</div>