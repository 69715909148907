<p-toast></p-toast>

<div class="card">
    <div>
        <p class="title"><b>Servidor</b></p>
    </div>
    <p-tabView>
        <p-tabPanel header="Dados do servidor">
            <div class="formgrid grid">
                <div class="field col-3 ">
                    <label><b>Nome:</b></label>
                    <label class="w-full">{{ servidor.nome }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>CPF:</b></label>
                    <label class="w-full">{{ servidor.cpf }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Regime Jurídico:</b></label>
                    <label class="w-full">{{ servidor.regimeJuridico }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Situação Funcional:</b></label>
                    <label class="w-full">{{ servidor.situacaoFuncional }}</label>
                </div>

                <div class="field col-3 ">
                    <label><b>Código do Vínculo:</b></label>
                    <label class="w-full">{{ servidor.codigoVinculo }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Situação Especial do Vínculo:</b></label>
                    <label class="w-full">{{ servidor.situacaoEspecialVinculo }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Orgão de Origem:</b></label>
                    <label class="w-full">{{ servidor.orgaoOrigem.nome }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Orgão de Lotação:</b></label>
                    <label class="w-full">{{ servidor.orgaoLotacao.nome }}</label>
                </div>

                <div class="field col-3 ">
                    <label><b>Grupo de Cargo:</b></label>
                    <label class="w-full">{{ servidor.grupoCargo }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Cargo:</b></label>
                    <label class="w-full">{{ servidor.cargo }}</label>
                </div>
                <div class="field col-6 ">
                    <label><b>Atividade de Função:</b></label>
                    <label class="w-full">{{ servidor.atividadeFuncao }}</label>
                </div>

                <div class="field col-3 ">
                    <label><b>Data de Nomeação:</b></label>
                    <label class="w-full">{{ servidor.dataNomeacao }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Data de Posse:</b></label>
                    <label class="w-full">{{ servidor.dataPosse }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Data de Exercício:</b></label>
                    <label class="w-full">{{ servidor.dataExercicio }}</label>
                </div>
                <div class="field col-3 ">
                    <label><b>Data de Exclusão:</b></label>
                    <label class="w-full">{{ servidor.dataExclusao }}</label>
                </div>

                <div class="field col-4 ">
                    <label><b>Jornada de Trabalho do Cargo:</b></label>
                    <label class="w-full">{{ servidor.jornadaTrabalhoCargo }}</label>
                </div>
                <div class="field col-4 ">
                    <label><b>Jornada de Trabalho para Cálculo:</b></label>
                    <label class="w-full">{{ servidor.jornadaTrabalhoCalculo }}</label>
                </div>
                <div class="field col-4 ">
                    <label><b>Jornada de Trabalho do Servidor:</b></label>
                    <label class="w-full">{{ servidor.jornadaTrabalhoServidor }}</label>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Vínculo / Unidade de trabalho">
            <div *ngFor="let vinculo of servidor.vinculos">
                <div class="formgrid grid">
                    <label class="field col-8"><b>Atividade de Função: {{ vinculo.atividade }}</b></label>
                    <label class="field col-4"><b>Cod Vínculo: {{ vinculo.codVinculo }}</b></label>

                    <label class="field col-3 "><b>Orgão:</b></label>
                    <label class="field col-3 "><b>Unidade de Trabalho:</b></label>
                    <label class="field col-3 "><b>Data de início:</b></label>
                    <label class="field col-3 "><b>Data de término:</b></label>
                    <label class="field col-3 ">{{ vinculo.orgao.nome }}</label>
                    <label class="field col-3 ">{{ vinculo.unidade.nome }}</label>
                    <label class="field col-3 ">{{ vinculo.dataInicio }}</label>
                    <label class="field col-3 ">{{ vinculo.dataTermino }}</label>

                    <label class="field col-3 "><b>Tipo de Escala:</b></label>
                    <label class="field col-9 "><b>Descrição:</b></label>
                    <label class="field col-3 ">{{ vinculo.escala?.tipo }}</label>
                    <label class="field col-9 ">{{ vinculo.escala?.descricao }}</label>

                    <div class="col-4 flex align-items-center gap-1">
                        <p-checkbox value="lotaçãoDivergente"></p-checkbox>
                        <label><b>Lotação divergente</b></label>
                    </div>
                    <div class="field col-4">
                        <label><b>Orgão</b></label>
                        <p-dropdown styleClass="w-full"
                                    [options]="orgaos"
                                    optionLabel="nome"></p-dropdown>
                    </div>
                    <div class="field col-4">
                        <label><b>Unidade Administrativa</b></label>
                        <p-dropdown styleClass="w-full"
                                    [options]="unidades"
                                    optionLabel="nome"></p-dropdown>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
    <p-divider></p-divider>
    <div class="flex justify-content-end">
        <button pButton type=" button" label="Voltar" (click)="onRedirect('/servidores')"></button>
    </div>
</div>
