<p-toast></p-toast>

<form [formGroup]="formSave" (ngSubmit)="handleSubmit()">
    <p-card>
        <ng-template pTemplate="header">
            <div class="grid p-2">
                <div class="col">
                    <h3>{{ id ? "Editar" : "Cadastrar" }} fechamento</h3>
                </div>
            </div>
        </ng-template>
        <div class="grid">
            <div class="field col-12">
                <label for="nome"><b>Órgão</b></label>
                <!-- <input
                pInputText
                formControlName="nome"
                placeholder="Nome Completo"
                name="nome"
                class="w-full"
                id="nome"
            /> -->
                <p-autoComplete
                    aria-required="true"
                    formControlName="orgao"
                    autoClear="true"
                    [suggestions]="autoCompleteOrgaos"
                    (completeMethod)="onChangeOrgaoAutocomplete($event)"
                    [multiple]="false"
                    field="name"
                    [style]="{ minWidth: '100%' }"
                    [inputStyle]="{ minWidth: '100%' }"
                    class="p-autocomplete w-full"
                ></p-autoComplete>
            </div>
            <div class="field col-2">
                <label for="nome"><b>Data e hora do fechamento</b></label>
                <br />
                <p-calendar
                    formControlName="dataFechamento"
                    aria-required="true"
                    placeholder="Digite aqui a data de fechamento"
                    showTime="true"
                    [hourFormat]="24"
                    class="w-full"
                ></p-calendar>
            </div>
            <div class="field col">
                <label for="nome"><b>Referência</b></label>
                <input
                    aria-required="true"
                    formControlName="anoMes"
                    placeholder="Digite aqui o mês/ano"
                    type="text"
                    pInputText
                    class="w-full"
                    mask="00/0000"
                />
            </div>
            <div class="field col-12 div-item">
                <label><b>Situação excepcional (opcional)</b></label>
                <textarea
                    formControlName="justificativa"
                    pInputTextarea
                    class="w-full"
                ></textarea>
            </div>
        </div>
        <!-- ng-invalid -->
        <ng-template pTemplate="footer">
            <div class="grid">
                <div class="col-12">
                    <div class="flex justify-content-end gap-2">
                        <button
                            iconPos="right"
                            pButton
                            pRipple
                            type="button"
                            label="Cancelar"
                            class="p-button-text"
                            (click)="handleCancel()"
                        ></button>
                        <button
                            iconPos="right"
                            pButton
                            pRipple
                            type="button"
                            [label]="id ? 'Editar' : 'Cadastrar'"
                            (click)="handleSubmit()"
                        ></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</form>
