import { Component, ElementRef, ViewChild } from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { MegaMenuItem } from 'primeng/api';
import { AppComponent } from '../../../app.component';
import { AppMainComponent } from '../app.main.component';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionStorageService } from 'src/app/shared/services/session-storage/session-storage.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({opacity: 0, transform: 'scaleY(0.8)'}),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
              ]),
              transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
              ])
        ])
    ]
})
export class AppTopBarComponent {

    constructor(public appMain: AppMainComponent, public app: AppComponent, private oauthService: OAuthService, private sessionStorageService: SessionStorageService, private router: Router) {
    }

    activeItem: number;

    @ViewChild('searchInput') searchInputViewChild: ElementRef;

    onSearchAnimationEnd(event: AnimationEvent) {
        switch(event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
            break;
        }
    }

    onSubmit(request: any) {
        this.sessionStorageService.clear();
        this.oauthService.logOut();
    }
    goToProfile(){
        this.router.navigate(['perfil'])
    }
}